import { AlertDialog as Alert, Button } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { useRef } from 'react';
import { P, match } from 'ts-pattern';
interface Props {
  onPress: () => Promise<void>;
  title: string;
  message: string;
  setIsOpen: (arg0: boolean) => void;
  isOpen: boolean;
  setDeleteError: (arg0: string[]) => void;
}

export const DeleteAlertDialog: React.FC<Props> = ({ onPress, title, message, setIsOpen, isOpen, setDeleteError }) => {
  const onClose = () => setIsOpen(false);
  const cancelRef = useRef(null);
  const processing = useRef(false);
  const navigation = useNavigation();

  const genDeleteErrorMassage = (baseMassages: string[]) => {
    const convertMassage = (s: string) => {
      return match(s)
        .with(P.string.includes('tasks'), (s: any) => s.replace('tasks', 'タスク'))
        .with(P.string.includes('group users'), (s: any) => s.replace('group users', 'グループメンバー'))
        .with(P.string.includes('applied feed designs'), (s: any) => s.replace('applied feed designs', '飼料設計適用'))
        .with(P.string.includes('additives'), (s: any) => s.replace('additives', '添加剤'))
        .with(P.string.includes('group feed designs'), (s: any) => s.replace('group feed designs', 'グループ飼料設計'))
        .with(P.string.includes('visits'), (s: any) => s.replace('visits', '訪問履歴'))
        .otherwise((s: any) => s);
    };
    return baseMassages.map((m) => convertMassage(m));
  };

  return (
    <Alert leastDestructiveRef={cancelRef} isOpen={isOpen} onClose={onClose}>
      <Alert.Content borderWidth={1}>
        <Alert.CloseButton />
        <Alert.Header>{title}</Alert.Header>
        <Alert.Body>{message}</Alert.Body>
        <Alert.Footer>
          <Button.Group space="xs">
            <Button variant="unstyled" colorScheme="coolGray" onPress={onClose} ref={cancelRef}>
              キャンセル
            </Button>
            <Button
              colorScheme="danger"
              onPress={() => {
                if (processing.current) return;
                processing.current = true;
                onPress()
                  // @ts-expect-error TS(2339): Property 'unwrap' does not exist on type 'Promise<... Remove this comment to see the full error message
                  .unwrap()
                  .then(() => navigation.goBack())
                  .catch((error: any) => {
                    setDeleteError(genDeleteErrorMassage(error.data.base));
                  })
                  .finally(() => (processing.current = false));
              }}
              isLoading={processing.current}
              isDisabled={processing.current}
            >
              実行
            </Button>
          </Button.Group>
        </Alert.Footer>
      </Alert.Content>
    </Alert>
  );
};
