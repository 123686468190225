import normalizeUrl from 'normalize-url';
import { useState } from 'react';
import { Attachment, Modal, ModalGallery, isScrapedContent, isMediaAttachment } from '~/lib/StreamChatReact';
import type { AttachmentProps, DefaultStreamChatGenerics } from '~/lib/StreamChatReact';

export const CustomAttachments = (props: AttachmentProps<DefaultStreamChatGenerics>) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [index, setIndex] = useState(0);

  // https://github.com/u-motion/u-motion-apps/issues/1146
  const uniqueAttachments = props.attachments.filter(
    (item, index, self) =>
      !item.og_scrape_url ||
      index ===
        self.findIndex(
          (t) =>
            !!t.og_scrape_url &&
            t.title === item.title &&
            (t.title_link ? normalizeUrl(t.title_link) : '') === (item.title_link ? normalizeUrl(item.title_link) : '')
        )
  );

  const hasLink = uniqueAttachments.some((item) => isScrapedContent(item));
  const hasVideo = uniqueAttachments.some((item) => isMediaAttachment(item));

  if (!hasLink && !hasVideo) {
    if (props.attachments.length <= 4 && props.attachments.length > 2) {
      // https://github.com/u-motion/u-motion-apps/issues/1692
      return (
        <div className="str-chat__attachment-list">
          <div className="str-chat__message-attachment str-chat__message-attachment-dynamic-size str-chat__message-attachment--gallery">
            <div className="str-chat__gallery str-chat__gallery--square str-chat__gallery-two-rows">
              {uniqueAttachments.map((attachment, index) => (
                <button
                  className="str-chat__gallery-image"
                  data-testid="gallery-image"
                  key={`gallery-image-${index}`}
                  onClick={() => {
                    setIsModalOpen(true);
                    setIndex(index);
                  }}
                >
                  <img
                    data-testid="str-chat__base-image"
                    alt={attachment.fallback}
                    src={attachment.image_url}
                    title={attachment.fallback}
                    className="str-chat__base-image"
                    style={{ '--original-height': '1000000', '--original-width': '1000000' } as React.CSSProperties}
                  />
                </button>
              ))}
              <Modal
                className="str-chat__gallery-modal"
                onClose={() => setIsModalOpen((isModalOpen) => !isModalOpen)}
                open={isModalOpen}
              >
                <ModalGallery images={props.attachments} index={index} />
              </Modal>
            </div>
          </div>
        </div>
      );
    } else {
      return <Attachment {...props} attachments={uniqueAttachments} />;
    }
  } else {
    return <Attachment {...props} attachments={uniqueAttachments} />;
  }
};
