import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import {
  Avatar,
  HStack,
  VStack,
  Center,
  Text,
  Box,
  Pressable,
  ScrollView,
  Divider,
} from '@gluestack-ui/themed-native-base';
import { FlashList } from '@shopify/flash-list';
import { isEmpty } from 'lodash';
import { Toast } from 'native-base';
import React, { useCallback, useEffect, useState } from 'react';
import { DefaultStreamChatGenerics } from 'stream-chat-react';
import {
  useDeletePushMuteConfigsByIdMutation,
  useGetPushMuteConfigsExistQuery,
  usePostPushMuteConfigsMutation,
} from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';
import { MODE_ENUM, type MODES } from '~/components/ChannelInfo';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useAccounts, useAvatars, useChannelMembers } from '~/hooks';
import ArrowRightIcons from '~/icons/ArrowRightIcons';
import ArrowUpIcons from '~/icons/ArrowUpIcons';
import CloseIcons from '~/icons/CloseIcons';
import GroupMemberIcons from '~/icons/GroupMemberIcons';
import ImageAlbumIcons from '~/icons/ImageAlbumIcons';
import InfoIcons from '~/icons/InfoIcons';
import LoginIcons from '~/icons/LoginIcons';
import LogoutIcons from '~/icons/LogoutIcons';
import MessageUnReadIcons from '~/icons/MessageUnReadIcons';
//import PaletteIcons from '~/icons/PaletteIcons';
import PenEditIcons from '~/icons/PenEditIcons';
import PlusIcons from '~/icons/PlusIcons';
import VolumeOffIcons from '~/icons/VolumeOffIcons';
import VolumeOnIcons from '~/icons/VolumeOnIcons';
import { ThumbnailScrollView } from './Media/ThumbnailScrollView';
import type { Channel } from 'stream-chat';

const ChannelInfoHome: React.FC<{
  open?: boolean;
  onClose: (() => void) | undefined;
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
  setMode: (mode: MODES) => void;
}> = ({ open, appChannel, onClose, setMode }) => {
  const [currentMembers, setCurrentMembers] = useState<{ id?: string; name?: string }[]>([]);
  const [mute, setMute] = useState(false);
  const { avatarsByChatUserId } = useAvatars();
  const { accounts } = useAccounts();
  const channel = appChannel as Channel<DefaultStreamChatGenerics>;
  const pushMuteConfigQuery = useGetPushMuteConfigsExistQuery({ targetId: channel?.cid, targetType: 'channel' });
  const [deletePushMuteConfig] = useDeletePushMuteConfigsByIdMutation();
  const [postPushMuteConfig] = usePostPushMuteConfigsMutation();
  const { chatUserId, findUsersByTeamIdAsync } = useStreamChatContext();
  const [isSystemChannel, setIsSystemChannel] = useState();

  const { allMembers } = useChannelMembers(appChannel?.id ?? '');

  useEffect(() => {
    setCurrentMembers(
      allMembers.map((item) => ({
        id: item?.user?.id,
        name: item?.user?.name,
      }))
    );
  }, [allMembers, setCurrentMembers]);

  const handleMutePress = useCallback(() => {
    if (mute) {
      const muteConfig = pushMuteConfigQuery.currentData;
      if (!muteConfig) {
        return;
      }
      deletePushMuteConfig({ id: muteConfig.id });
      Toast.show({
        description: 'プッシュ通知のミュートを解除しました',
        placement: 'top',
      });
    } else {
      postPushMuteConfig({ body: { push_mute_config: { target_type: 'channel', target_id: channel?.cid } } });
      Toast.show({
        description: 'プッシュ通知をミュートしました',
        placement: 'top',
      });
    }
  }, [pushMuteConfigQuery, deletePushMuteConfig, postPushMuteConfig, mute]);

  useDidUpdate(
    () => {
      if (pushMuteConfigQuery.isLoading) {
        return;
      }
      const data = pushMuteConfigQuery.currentData;
      if (pushMuteConfigQuery.isSuccess && data?.id) {
        setMute(true);
      } else {
        setMute(false);
      }
    },
    [pushMuteConfigQuery],
    true
  );

  useDidUpdate(
    () => {
      if (!appChannel?.data || isEmpty(appChannel?.data) || appChannel?.disconnected) {
        return;
      }

      if (appChannel.data?.isDM || appChannel.data?.is_my_channel) {
        return;
      }

      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (appChannel?.data?.cid.match(/^team\:u-feed\-group.+/)?.length > 0) {
        // @ts-expect-error TS(2345): Argument of type 'true' is not assignable to param... Remove this comment to see the full error message
        setIsSystemChannel(true);
      } else {
        // @ts-expect-error TS(2345): Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
        setIsSystemChannel(false);
      }
    },
    [appChannel, appChannel?.data, chatUserId],
    true
  );

  const onLeaveChannel = () => {
    Alert.alert('確認', `「${appChannel?.data?.name}」から退出します。`, [
      {
        text: 'キャンセル',
      },
      {
        text: '退出する',
        onPress: async () => {
          // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
          await appChannel?.removeMembers([chatUserId]);
        },
      },
    ]);
  };

  const onJoinChannel = async () => {
    // @ts-expect-error TS(2322): Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
    await appChannel?.addMembers([chatUserId]);
  };

  const renderItem = ({ item }: any) => {
    return (
      <HStack py="xs" gap="xs" alignItems="center">
        {avatarsByChatUserId[item.id]?.avatar ? (
          <Avatar source={{ uri: avatarsByChatUserId[item.id]?.avatar }} size="md" />
        ) : (
          <Avatar backgroundColor="onSurfaceBrightest" size="md" />
        )}
        <VStack>
          <VStack>
            <Text fontSize="md" fontWeight="bold" color="onSurface">
              {item.name}
            </Text>
          </VStack>
          <VStack>
            <Text fontSize="sm" fontWeight="regular" color="onSurfaceBright">
              {/* @ts-expect-error TS(2538): Type 'any[]' cannot be used as an index type. */}
              {accounts[[item.id]]}
            </Text>
          </VStack>
        </VStack>
      </HStack>
    );
  };

  const updateTeamMembers = async (query: string) => {
    const channelState = await appChannel?.watch();
    const channelTeam = channelState?.channel.team;
    // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const result = await findUsersByTeamIdAsync(channelTeam, query);
    return result || [];
  };

  const onQuery = (query: any) => {
    return updateTeamMembers(query);
  };

  const onConfirm = async (selectedChatIds: any) => {
    // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
    const filteredChatIds = selectedChatIds.filter((id) => !currentMembers.some((member) => member.id === id));
    appChannel
      ?.addMembers(filteredChatIds)
      .then(async (result) => {
        const newMembers = appChannel?.state.members;

        setCurrentMembers(
          Object.keys(newMembers).map((id) => ({
            id,
            name: newMembers[id]?.user?.name,
          }))
        );
      })
      .catch((error) => console.error('addMembersToChannel Error: ', error));
  };

  // チャンネルにメンバーを追加する権限があるかどうか
  const hideMemberAddButton =
    appChannel?.data?.isDM ||
    isSystemChannel ||
    appChannel?.data?.is_my_channel ||
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    !appChannel?.data?.own_capabilities?.includes('update-channel-members');

  return (
    <VStack
      display={!open && 'none'}
      width="300px"
      borderLeftWidth={gluestackUIConfig.tokens.borderWidths.medium}
      borderColor="outlineVariant"
    >
      <HStack px="sm" py="lg" justifyContent="flex-end">
        <Pressable onPress={onClose}>
          <CloseIcons />
        </Pressable>
      </HStack>
      <ScrollView>
        <VStack px="sm" py="2xl" gap="xl">
          <Center gap="xs">
            <Avatar
              source={{ uri: appChannel?.data?.image }}
              backgroundColor="onSurfaceBrightest"
              height="70"
              width="70"
            />
            <HStack gap="xs">
              <Text fontSize="xl" fontWeight="bold" color="onSurface">
                {appChannel?.data?.name}
              </Text>
              <Pressable onPress={() => setMode(MODE_ENUM.EDIT_CHANNEL)}>
                <PenEditIcons />
              </Pressable>
            </HStack>
            <Text maxWidth="300" fontSize="md" fontWeight="regular" color="onSurfaceBright">
              {appChannel?.data?.channel_overview}
            </Text>
          </Center>
          <HStack gap="xs">
            <Pressable flex="1" onPress={handleMutePress}>
              <Box
                flex="1"
                p="xs"
                borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
                borderRadius={gluestackUIConfig.tokens.radii.lg}
                borderColor="outlineVariant"
              >
                <Center>
                  <VStack alignItems="center">
                    {mute ? <VolumeOffIcons /> : <VolumeOnIcons />}
                    <Text fontSize="sm" fontWeight="bold" color="onSurface">
                      {mute ? '通知オン' : '通知オフ'}
                    </Text>
                  </VStack>
                </Center>
              </Box>
            </Pressable>

            {currentMembers.find((m) => m.id === chatUserId) &&
            // @ts-expect-error TS(2538): Type 'undefined' cannot be used as an index type.
            appChannel.state.members[chatUserId]?.role !== 'owner' ? (
              <Pressable flex="1" onPress={onLeaveChannel}>
                <VStack
                  p="xs"
                  gap="2xs"
                  borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
                  borderRadius={gluestackUIConfig.tokens.radii.lg}
                  borderColor="negative"
                >
                  <Center>
                    <LogoutIcons />
                    <Text fontSize="sm" fontWeight="bold" color="negative">
                      ルームから退出
                    </Text>
                  </Center>
                </VStack>
              </Pressable>
            ) : (
              !currentMembers.find((m) => m.id === chatUserId) && (
                <Pressable flex="1" onPress={onJoinChannel}>
                  <VStack
                    p="xs"
                    gap="2xs"
                    borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
                    borderRadius="8"
                    borderColor="outlineVariant"
                  >
                    <Center>
                      <LoginIcons />
                      <Text fontSize="sm" fontWeight="bold" color="onSurface">
                        ルームに参加
                      </Text>
                    </Center>
                  </VStack>
                </Pressable>
              )
            )}
          </HStack>
          <VStack
            py="md"
            gap="sm"
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
          >
            <Pressable onPress={() => setMode(MODE_ENUM.MEDIA)}>
              <HStack alignItems="center" justifyContent="space-between">
                <HStack gap="xs" alignItems="center">
                  <ImageAlbumIcons />
                  <Text fontSize="md" fontWeight="regular" color="onSurface">
                    写真・動画
                  </Text>
                </HStack>
                <ArrowRightIcons />
              </HStack>
            </Pressable>
            <ThumbnailScrollView appChannel={appChannel} />
          </VStack>
          <VStack
            py="md"
            gap="sm"
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
          >
            <HStack justifyContent="space-between">
              <HStack gap="xs" alignItems="center">
                <MessageUnReadIcons />
                <Text fontSize="md" fontWeight="regular" color="onSurface">
                  未読メッセージ
                </Text>
              </HStack>
              <Pressable onPress={() => appChannel?.markRead()}>
                <Text fontSize="md" fontWeight="bold" color="primary">
                  すべて既読にする
                </Text>
              </Pressable>
            </HStack>
          </VStack>
          {/* <VStack
            py="md"
            gap="sm"
            borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outlineVariant"
          >
            <Pressable onPress={() => setMode(MODE_ENUM.BACKGROUND)}>
              <HStack justifyContent="space-between">
                <HStack gap="xs" alignItems="center">
                  <PaletteIcons />
                  <Text fontSize="md" fontWeight="regular" color="onSurface">
                    背景デザイン
                  </Text>
                </HStack>
                <ArrowRightIcons />
              </HStack>
            </Pressable>
          </VStack> */}
          <VStack py="md" gap="sm" borderColor="outlineVariant">
            <HStack justifyContent="space-between">
              <HStack gap="xs" alignItems="center">
                <GroupMemberIcons />
                <Text fontSize="md" fontWeight="regular" color="onSurface">
                  メンバー
                </Text>
              </HStack>
              <ArrowUpIcons />
            </HStack>
            <VStack flex={1} gap="sm">
              {hideMemberAddButton ? null : (
                <Pressable
                  p="sm"
                  borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
                  borderColor="outline"
                  borderRadius={gluestackUIConfig.tokens.radii.lg}
                  onPress={() => setMode(MODE_ENUM.MEMBERS)}
                >
                  <Center>
                    <HStack gap="2xs" alignItems="center">
                      <PlusIcons />
                      <Text fontSize="sm" fontWeight="bold" color="primary">
                        メンバーを追加
                      </Text>
                    </HStack>
                  </Center>
                </Pressable>
              )}
              <VStack p="sm" backgroundColor="surface" borderRadius={gluestackUIConfig.tokens.radii.lg}>
                <HStack gap="2xs" alignItems="center">
                  <InfoIcons />
                  <Text fontSize="md" fontWeight="bold" color="onSurface">
                    {appChannel?.data?.isPublic ? '公開ルーム' : 'シークレットルーム'}
                  </Text>
                </HStack>
                <Text fontSize="md" fontWeight="regular" color="onSurface">
                  {appChannel?.data?.isPublic
                    ? '同じグループのメンバー全員が閲覧可能です。'
                    : '参加しているメンバーのみ閲覧可能です'}
                </Text>
              </VStack>
              <FlashList
                data={currentMembers}
                renderItem={renderItem}
                ItemSeparatorComponent={Divider}
                estimatedItemSize={150}
              />
            </VStack>
          </VStack>
        </VStack>
      </ScrollView>
    </VStack>
  );
};

export default ChannelInfoHome;
