// @ts-nocheck
import { useNavigation } from '@react-navigation/native';
import { Spinner, Input, VStack, HStack, Text, Pressable, Button } from 'native-base';
import React, { useState } from 'react';
import { FlatList, Alert } from 'react-native';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useFetchFarmQuery, useFetchFarmCurrentQuery } from '~/packages/u-motion-api/api/farmApi';
import { setUmotionToken } from '~/packages/u-motion-api/slices/uMotionSessionSlice';
import { useSwitchFarmMutation } from '~/packages/u-motion-api/uMotionRefreshTokenApi';
import { useAppDispatch } from '~/store';

type Props = {
  theme: any;
};

export const UmotionSwitchFarmScreen: React.FC<Props> = ({ theme }) => {
  const [searchText, setSearchText] = useState('');
  const [error, setError] = useState('');
  const farmQuery = useFetchFarmQuery();
  const farmCurrentQuery = useFetchFarmCurrentQuery();
  const navigation = useNavigation();
  const [switchFarm, switchFarmResult] = useSwitchFarmMutation();
  const dispatch = useAppDispatch();

  const itemIsEqual = (prev, next) => {
    return prev?.item?.id === next?.item?.id;
  };

  const contractedPlanString = (contractedPlan: string): string => {
    switch (contractedPlan) {
      case 'full':
        return 'フル';
      case 'light':
        return 'ライト';
      case 'light_feed':
        return 'ライト+';
      default:
        return '-';
    }
  };

  const showAlert = () => {
    Alert.alert('エラー', '牧場を切り替えに失敗しました。操作をやり直してください', [{ text: 'OK' }]);
  };

  const onPressSwitchFarm = (item) => {
    switchFarm({ id: Number(item?.id) })
      .unwrap()
      .then(async (result) => {
        dispatch(
          setUmotionToken({
            sessionToken: result?.sessionToken,
            refreshToken: result?.refreshToken,
            expiredAt: result?.expiredAt,
          })
        );
        await farmCurrentQuery.refetch();
        navigation.goBack();
      })
      .catch((error) => {
        console.error('switchFarm failed: ', error);
        showAlert();
      });
  };

  const Row = React.memo(
    ({ item }: any) => (
      <Pressable key={String(item?.id)} onPress={() => onPressSwitchFarm(item)}>
        <HStack
          justifyContent="space-between"
          py={2}
          px={4}
          borderBottomWidth={1}
          borderColor="coolGray.200"
          space="md"
          width="100%"
        >
          <Text flex={2.5}>{item?.farmName}</Text>
          <Text>{contractedPlanString(item?.contractedPlan)}</Text>
          <Text>
            {item?.farmTypeDairy && '乳用 '}
            {item?.farmTypeBreeding && '繁殖 '}
            {item?.farmTypeFattening && '肥育'}
          </Text>
        </HStack>
      </Pressable>
    ),
    itemIsEqual
  );

  const renderItem = ({ item }) => <Row item={item} />;

  const renderItemCall = React.useCallback(({ item }) => renderItem({ item }), []);

  const filteredFarms = farmQuery.data
    ?.filter((f: any) => {
      const name = f.farmName.toLowerCase();
      return name.match(new RegExp(searchText.toLowerCase()));
    })
    .sort((a, b) => b.id - a.id);

  return (
    <ScreenWidthModal>
      <VStack space={4} p={4} flex={1}>
        <HStack alignItems="center" justifyContent="space-between" width="100%">
          <Text bold fontSize="lg">
            牧場切り替え
          </Text>
          <Button onPress={() => navigation.goBack()} variant="ghost">
            キャンセル
          </Button>
        </HStack>
        <Input
          placeholder="牧場名で絞り込み"
          value={searchText}
          onChangeText={setSearchText}
          fontSize="md"
          borderColor="coolGray.300"
          borderWidth={1}
        />
        <HStack
          justifyContent="space-between"
          py={2}
          px={4}
          borderBottomWidth={1}
          borderColor="coolGray.200"
          space="md"
        >
          <Text flex={2.5} bold>
            牧場名
          </Text>
          <Text bold>契約プラン</Text>
          <Text bold>運用形態</Text>
        </HStack>
        {farmQuery.isLoading || switchFarmResult.isLoading ? (
          <Spinner size="lg" />
        ) : (
          <FlatList
            data={filteredFarms}
            contentContainerStyle={{ flexGrow: 1 }}
            renderItem={renderItemCall}
            keyExtractor={(item) => String(item.id)}
          />
        )}
      </VStack>
    </ScreenWidthModal>
  );
};
