import { ScrollView } from '@gluestack-ui/themed';
import { Skeleton, Alert, Heading, Box, Center, Spacer } from '@gluestack-ui/themed-native-base';
import { useRoute } from '@react-navigation/native';
import * as React from 'react';
import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { SectionTitle } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { GroupMemberCard } from '~/components/GroupMemberCard';

interface Props {
  groupId?: number;
  farmId?: number;
  showTitle?: boolean;
}

const { useState } = React;

export const GroupDetailScreen: React.FC<Props> = ({ groupId, farmId, showTitle = true }) => {
  const { params } = useRoute();

  const queryParams = React.useMemo(() => {
    return {
      farmId,
      groupId,
      ...params,
    };
    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type 'object'.
  }, [params?.farmId, params?.groupId, farmId, groupId]);

  // @ts-expect-error TS(2345): Argument of type '{ farmId: number | undefined; gr... Remove this comment to see the full error message
  const group = useGetGroupsByGroupIdQuery(queryParams);

  if (group.isLoading) {
    return (
      <BaseScreenBuilder>
        <Skeleton />
      </BaseScreenBuilder>
    );
  } else if (group.isError) {
    return (
      <BaseScreenBuilder>
        <Alert colorScheme="error">
          <Alert.Icon />
          {/* @ts-expect-error TS(2571): Object is of type 'unknown'. */}
          {'data' in group.error ? (group.error.data?.error ?? 'エラーが発生しました') : 'エラーが発生しました'}
        </Alert>
      </BaseScreenBuilder>
    );
  }
  return (
    <ScrollView contentContainerStyle={{ paddingTop: 16, paddingHorizontal: 16, backgroundColor: '#f8f9fb' }}>
      <Center>
        <Box width="full" maxWidth={1440}>
          <>
            {showTitle ? (
              <Heading fontSize="xl" p="md" color="onSurface">
                {/* @ts-expect-error TS(2532): Object is possibly 'undefined'. */}
                {group.data.name}
              </Heading>
            ) : null}
          </>
          <Box backgroundColor="white" rounded="xl" marginBottom={8}>
            <SectionTitle
              title="グループのメンバー"
              px={{ base: 4, md: 10 }}
              py={{ base: 4, md: 6 }}
              backgroundColor="transparent"
            />
            {/* @ts-expect-error */}
            <GroupMemberCard groupId={queryParams?.groupId} px={{ base: 4, md: 10 }} />
          </Box>
        </Box>
      </Center>
      <Spacer height={200} />
    </ScrollView>
  );
};
