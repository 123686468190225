import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Foundation } from '@expo/vector-icons';
import { Text, Button, HStack, Box, VStack, useToken } from '@gluestack-ui/themed-native-base';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import { Platform, Pressable, ScrollView } from 'react-native';
import { Channel } from 'stream-chat';

import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import { BaseFormBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useStreamChatAuthContext, useStreamChatContext } from '~/contexts/StreamChatContext';
import { useUserChannel } from '~/hooks/useUserChannel';

type FormValues = {
  chatTeamId: string;
  members: string[];
};

export const ChatDMCreateEditScreen: React.FC = () => {
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const [primary] = useToken('colors', ['primary']);

  const [groupsOption, setGroupsOption] = useState([]);
  const [membersOption, setMembersOption] = useState<{ id: string; name: string }[]>([]);
  const [memberNamesById, setMemberNamesById] = useState<Record<string, string>>({});
  const [selectedChatId, setSelectedChatId] = useState();
  const [defaultValues, setDefaultValues] = useState();
  const [fieldValues, setFieldValues] = useState<FormValues>();
  const { chatClient, chatUserId } = useStreamChatAuthContext();
  const { setAppChannel } = useStreamChatContext();
  const [channels, setChannels] = useState<Channel[] | []>([]);
  const [selectedMemberIdList, setSelectedMemberIdList] = useState<string[]>([chatUserId || '']);

  const currentUserGroupsQuery = useGetCurrentUserGroupsQuery();
  const { createUserChannel, errorMessages } = useUserChannel();

  const [isLoading, setIsLoading] = useState(false);

  const selectedMemberNameList = React.useMemo(() => {
    return membersOption.filter((m) => selectedMemberIdList.includes(m.id)).map((m) => m.name);
  }, [membersOption, selectedMemberIdList]);

  useDidUpdate(() => {
    const queryChannels = async () => {
      setIsLoading(true);
      const filter = {
        members: {
          $eq: selectedMemberIdList,
        },
        isDM: {
          $eq: true,
        },
        type: {
          $eq: 'messaging',
        },
      };

      const option = { limit: 30 };

      const channels = await chatClient.queryChannels(filter, undefined, option);

      setChannels(channels);
      setIsLoading(false);
    };
    queryChannels();
  }, [selectedMemberIdList]);

  const goToDM = (channel: Channel) => {
    if (Platform.OS === 'web') {
      linkTo(`/chat`);
      const newChannel = chatClient.channel('messaging', channel.id);
      setAppChannel(newChannel);
    } else {
      navigation.goBack();
      // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      navigation.navigate('ChatChannelMessages', { channelId: channel.id });
    }
  };

  const onSubmit = async (sendingData: any) => {
    const createChannelResult = await createUserChannel({
      name: selectedMemberIdList.map((id: any) => memberNamesById[id]).join(','),
      members: selectedMemberIdList,
      chatTeamId: sendingData.chatTeamId,
      isDM: true,
    });
    if (createChannelResult) {
      if (!('isError' in createChannelResult) || !createChannelResult.isError) {
        // @ts-expect-error
        goToDM(createChannelResult.channel);
      }
    }
  };

  const onCancel = () => {
    navigation.goBack();
  };

  useDidUpdate(
    () => {
      const users = currentUserGroupsQuery.data
        ?.map((g) =>
          g.approved_users?.map((u) => ({
            ...u,
            groupName: g.name,
          }))
        )
        .flat();

      const chatIds = users?.map((u) => ({
        // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type '{ ... Remove this comment to see the full error message
        id: u?.chat_user_id,
        name: `${u?.name} (${u?.groupName})`,
      }));

      setMembersOption(chatIds ?? []);
      setMemberNamesById(
        users?.reduce(
          (acc, cur) => ({
            ...acc,
            // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type '{ ... Remove this comment to see the full error message
            [cur?.chat_user_id]: cur.name,
          }),
          {}
        ) ?? {}
      );

      const groups = currentUserGroupsQuery.data?.map((g) => {
        const detail =
          // @ts-expect-error TS(2339): Property 'farm' does not exist on type 'Group'.
          g?.farm?.name === g?.farm?.account?.name ? g?.farm?.name : `${g?.farm?.name} / ${g?.farm?.account?.name}`;
        return {
          value: g?.chat_team_id,
          label: `${g?.name} (${detail})`,
        };
      });

      // @ts-expect-error TS(2345): Argument of type '{ value: string | undefined; lab... Remove this comment to see the full error message
      setGroupsOption(groups);

      setDefaultValues({
        // @ts-expect-error TS(2345): Argument of type '{ chatTeamId: string | undefined... Remove this comment to see the full error message
        chatTeamId: groups?.[0]?.value,
        members: [chatUserId],
      });
    },
    [currentUserGroupsQuery.data],
    true
  );

  useDidUpdate(() => {
    const chatIds = currentUserGroupsQuery.data
      ?.filter((d) => d.chat_team_id === selectedChatId)
      .map((g) =>
        g.approved_users?.map((u) => ({
          // @ts-expect-error TS(2339): Property 'chat_user_id' does not exist on type 'Us... Remove this comment to see the full error message
          id: u.chat_user_id,
          name: `${u.name}`,
        }))
      )
      .flat();
    // @ts-expect-error TS(2345): Argument of type '({ id: any; name: string; } | un... Remove this comment to see the full error message
    setMembersOption(chatIds);
  }, [selectedChatId]);

  if ((groupsOption?.length <= 0 ?? true) || !defaultValues) {
    return null;
  }

  return (
    <ScreenWidthModal title="ダイレクト・メッセージを開始">
      <BaseScreenBuilder>
        <BaseFormBuilder
          isLoading={isLoading}
          hideBottom={channels.length > 0}
          // @ts-expect-error TS(2740): Type '{}' is missing the following properties from... Remove this comment to see the full error message
          queryResult={{}}
          onSubmit={onSubmit}
          onCancel={onCancel}
          onFieldUpdate={(updated) => {
            if (updated.chatTeamId !== selectedChatId) {
              updated.setValue('members', [chatUserId]);
              setSelectedMemberIdList([chatUserId ?? '']);
            }
            if (updated.chatTeamId) {
              setSelectedChatId(updated.chatTeamId);
            }
            setFieldValues(updated);
          }}
          emptyMessage="グループがありません"
          defaultValues={fieldValues ?? defaultValues}
          fields={[
            {
              key: 'chatTeamId',
              type: 'comboBox',
              label: '所属グループ',
              watch: true,
              options: groupsOption,
              wrapperStyle: { zIndex: 10 },
              rules: {
                required: {
                  value: true,
                  message: 'グループを選択してください',
                },
              },
            },
          ]}
        >
          <HStack alignItems="center" justifyContent="space-between" mb="xs">
            <Text fontSize="sm" color="onSurface">
              メンバー
            </Text>
            <Pressable
              onPress={() =>
                // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                navigation.navigate('ChatChannelSelectMembers', {
                  options: membersOption,
                  selectedOptions: selectedMemberIdList,
                  onSubmit: (idList: string[]) => setSelectedMemberIdList(idList),
                  isDM: true,
                  chatUserId: chatUserId,
                })
              }
            >
              <Text fontSize="sm" fontWeight="bold" color="primary" px="sm">
                メンバーを追加
              </Text>
            </Pressable>
          </HStack>
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <HStack alignItems="center" gap="xs" mb="md">
              {selectedMemberNameList.map((name, i) => (
                <Box key={`name-${i}`} px="sm" py="2xs" borderColor="outline" borderWidth={1} rounded="full">
                  <Text fontSize="sm" color="onSurface">
                    {name}
                  </Text>
                </Box>
              ))}
            </HStack>
          </ScrollView>
          {channels.length > 0 ? (
            <VStack backgroundColor="surface" p="sm" rounded="md">
              <Text fontSize="md" fontWeight="bold" color="onSurface">
                同じメンバーとのダイレクト・メッセージが作成済みです
              </Text>
              {channels.map((c) => (
                <Button
                  variant="link"
                  color="onSurface"
                  _text={{
                    color: 'primary',
                    fontWeight: 'bold',
                  }}
                  onPress={() => goToDM(c)}
                >{`${c?.data?.name}`}</Button>
              ))}
            </VStack>
          ) : (
            <VStack backgroundColor="surface" p="sm" rounded="md">
              <HStack gap="xs" alignItems="center" flex={1}>
                <Foundation name="info" size={20} color={primary} />
                <Text color="onSurface" width="90%">
                  ダイレクト・メッセージは、選択したメンバーのみ閲覧できます。開始後にメンバーを変更することはできません。
                </Text>
              </HStack>
            </VStack>
          )}
        </BaseFormBuilder>
        {errorMessages
          ? errorMessages.map((errorMessage, i) => (
              <Text fontSize="md" fontWeight="medium" color="red.400" key={i}>
                {errorMessage}
              </Text>
            ))
          : null}
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
