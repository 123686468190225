// @ts-nocheck
import { useNavigation } from '@react-navigation/native';
import { Box, VStack, HStack, Text, Button, Input } from 'native-base';
import { useState } from 'react';
import { UmotionFarmSwitcher } from '~/packages/u-motion-api/components';
import { ApplySensorData } from '~/screens/UmotionApplySensorScreen/';
import { DateUtil } from '~/utils/DateUtils';

export const PreviewCattleSensorMapping = ({ data }: { data: ApplySensorData[] }) => {
  const [isPosted, setIsPosted] = useState(false);
  const [date, setDate] = useState(DateUtil.toYYYYMMDD(new Date()));
  const navigation = useNavigation();

  if (!data) return null;

  const onRegisterPress = async () => {
    navigation.navigate('UmotionApplySensorModal', { data });
  };

  return (
    <VStack space="md">
      <UmotionFarmSwitcher />
      <HStack space="md" justifyContent="space-between" alignItems="center">
        <Text>適用日付: </Text>
        <Input value={date} onChangeText={setDate} backgroundColor="white" borderRadius="8px" />
      </HStack>
      {!isPosted && (
        <Button onPress={onRegisterPress} backgroundColor="#FFA153" borderRadius="8px">
          センサー紐づけ
        </Button>
      )}
      <Box backgroundColor="white" borderRadius={20} padding={4} marginBottom={4}>
        <VStack space={4}>
          <HStack space="md" justifyContent="space-between">
            <Text flexGrow={3}>個体識別番号</Text>
            <Text>センサー番号</Text>
          </HStack>

          {data.map((data, i) => {
            return (
              <HStack key={`line-${i}`} space="md" justifyContent="space-between">
                <Text flexGrow={3}>{data?.uid || '-'}</Text>
                <Text>{data?.sensor_id || '-'}</Text>
              </HStack>
            );
          })}
        </VStack>
      </Box>
    </VStack>
  );
};
