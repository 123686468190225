import { Text, VStack, Input } from '@gluestack-ui/themed-native-base';
import * as React from 'react';

import type { User } from '~/api/uFeedApi';
import { FilesThumbnail } from '~/components/builder/FilesThumbnail';
import { UserDetails } from '~/components/builder/UserDetails';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  title: string;
  value?: string | React.ReactElement | User | string[];
  type?: 'text' | 'password' | 'user' | 'key-value' | 'number' | 'date' | 'key-value-array' | 'group-roles';
  options?: object;
  color?: string;
}

const genDisplayValue = (type: string, value: string | React.ReactElement | User | string[], options: object = {}) => {
  switch (type) {
    case 'date':
      // @ts-expect-error TS(2769): No overload matches this call.
      return value ? DateUtil.toJapaneseYYYYMMDDOrMMDD(new Date(value)) : '';
    case 'user':
      return value;
    case 'user-account':
      // @ts-expect-error TS(2339): Property 'account' does not exist on type 'string ... Remove this comment to see the full error message
      return value.account.name;
    case 'key-value':
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return options[value] || value;
    case 'number':
      return value.toLocaleString();
    case 'group-roles':
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      return value[0] === 'owner'
        ? 'オーナー'
        : // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
          value[0] === 'partner'
          ? 'パートナー'
          : // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            value[0] === 'guest'
            ? 'ゲスト'
            : '';
    case 'text':
    default:
      return value;
  }
};

const renderContent = (type: string, value: any, options: any, title: any, color: string) => {
  switch (type) {
    case 'user':
      // @ts-expect-error TS(2552): Cannot find name 'Users'. Did you mean 'User'?
      return <UserDetails user={value as Users} displayAccount={title != '名前'} />;
    case 'files':
      return <FilesThumbnail files={value} />;
    case 'key-value-array':
      const Values = value.map((val: any) => options.find((o: any) => o.key === val)?.value);
      return Values.map((val: any) => (
        <Input type="text" value={val} fontSize="md" fontWeight="bold" color="onSurface" padding={0} />
      ));

    default:
      const displayValue = genDisplayValue(type, value, options);

      return (
        <>
          <Text fontWeight="bold" padding={0} color="onSurface">
            {displayValue}
          </Text>
        </>
      );
  }
};

export const DetailListItem: React.FC<Props & React.PropsWithChildren> = ({
  title,
  value,
  type,
  options,
  children,
  color = 'gray.400',
}) => {
  return (
    <VStack space="xs" mb="md">
      <Text fontWeight="bold" color="onSurfaceBright" fontSize="sm">
        {title}
      </Text>
      {/* @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message */}
      {value ? renderContent(type, value, options, title, color) : null}
      {children}
    </VStack>
  );
};
