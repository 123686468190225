import { Text, HStack, VStack } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';

import { useGetFarmsByIdQuery, useGetFarmsByFarmIdGroupsQuery, useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { DetailsBuilder } from '~/components/builder/DetailsBuilder';
import { ListBuilder } from '~/components/builder/ListBuilder';
import { FarmDetailCard } from '~/components/FarmDetailCard';

interface Props {
  farmId?: number;
  showGroups?: boolean;
  cardMode?: boolean;
}

export const FarmDetailScreen: React.FC<Props> = ({ farmId, showGroups = true, cardMode = false }) => {
  const { params } = useRoute();
  const navigation = useNavigation();

  const userGroups = useGetCurrentUserGroupsQuery();

  const queryParams = params ? { ...params } : { id: farmId };
  // @ts-expect-error TS(2345): Argument of type '{ id?: undefined; } | { id: numb... Remove this comment to see the full error message
  const queryResult = useGetFarmsByIdQuery(cardMode ? { id: farmId } : queryParams);

  const queryResultGroups = useGetFarmsByFarmIdGroupsQuery({
    // @ts-expect-error TS(2322): Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
    farmId: queryParams?.id,
  });

  if (!userGroups.isSuccess) {
    return null;
  }

  return (
    <BaseScreenBuilder title="" padding0={cardMode}>
      <DetailsBuilder
        // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetFarmsB... Remove this comment to see the full error message
        queryResult={queryResult}
        emptyMessage="牧場データがありません"
        fields={[
          {
            key: 'name',
            label: '名前',
          },
          {
            key: 'memo',
            label: 'メモ',
          },
          {
            key: 'owner_name',
            label: '牧場オーナー',
          },
        ]}
      />

      <VStack space={3} paddingTop={5}>
        <Text fontSize="sm" color="onSurface" fontWeight="medium">
          詳細
        </Text>
        {queryResult.data?.details?.map((detail, i) => (
          <VStack key={i}>
            <FarmDetailCard item={detail} />
          </VStack>
        ))}
      </VStack>

      {showGroups && (
        <>
          <HStack space={2} pt="md" justifyContent="space-between">
            <Text fontSize="sm" color="onSurface" fontWeight="medium">
              グループ
            </Text>
          </HStack>
          <ListBuilder
            // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetFarmsB... Remove this comment to see the full error message
            queryResult={queryResultGroups}
            onSelect={(item) => {
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('GroupDetail', {
                farmId: queryParams?.id,
                groupId: item?.id,
              });
            }}
            emptyMessage="グループがありません。「追加」をタップしてグループを追加してください。"
            showIds={userGroups.data?.map((group) => group.id)}
          />
        </>
      )}
    </BaseScreenBuilder>
  );
};
