import { Ionicons } from '@expo/vector-icons';
import {
  VStack,
  Text,
  HStack,
  Alert,
  Center,
  FormControl,
  Skeleton,
  Button,
  Icon,
} from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { DetailListItem } from '~/components/builder/DetailListItem';
import { HeaderRight } from '~/components/HeaderRight';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { AlertBox } from './AlertBox';
import { DeleteAlertDialog } from './DeleteAlertDialog';

interface Props {
  queryResult: {
    data: [];
    error: boolean;
    isSuccess: boolean;
    isLoading: boolean;
    errorMessage: string;
    refetch: () => void;
  };
  detailLink?: string;
  // @ts-expect-error TS(2304): Cannot find name 'ReactNode'.
  emptyMessage?: string | ReactNode;
  defaultValues?: object;
  fields?: {
    key: string;
    type?: string;
    label: string;
  }[];
  onDeletePress?: () => Promise<Props>;
  onSharePress?: () => void;
  space?: number;
}

const { useEffect } = React;

export const DetailsBuilder = ({
  queryResult,
  detailLink,
  emptyMessage,
  defaultValues = {},
  fields,
  onDeletePress,
  onSharePress,
  // @ts-expect-error TS(2339): Property 'onEditPress' does not exist on type 'Pro... Remove this comment to see the full error message
  onEditPress,
  children,
  space = 8,
}: Props & React.PropsWithChildren) => {
  // @ts-expect-error TS(2339): Property 'isError' does not exist on type '{ data:... Remove this comment to see the full error message
  const { data, error, isSuccess, isLoading, errorMessage, refetch, isError } = queryResult;
  const navigation = useNavigation();
  const [deleteError, setDeleteError] = useState(null);
  const { control, setFocus, handleSubmit } = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const [isOpen, setIsOpen] = React.useState(false);

  onDeletePress &&
    navigation?.setOptions({
      headerRight: () => (
        <HeaderRight
          label="削除"
          labelColor="primary"
          onPress={() => {
            setIsOpen(true);
          }}
        />
      ),
    });

  return (
    <VStack space="xl">
      <DeleteAlertDialog
        // @ts-expect-error TS(2322): Type '(() => Promise<Props>) | undefined' is not a... Remove this comment to see the full error message
        onPress={onDeletePress}
        title="削除"
        message="削除を実行します。削除したデータは復旧できません。"
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        // @ts-expect-error TS(2322): Type 'Dispatch<SetStateAction<null>>' is not assig... Remove this comment to see the full error message
        setDeleteError={setDeleteError}
      />
      {deleteError ? <AlertBox message={deleteError} type="error" /> : null}
      {data && fields ? (
        <FormControl isReadOnly>
          <VStack space={space}>
            {fields
              // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
              .filter((field) => data?.[field.key])
              .map((field) => (
                <DetailListItem
                  title={field.label}
                  // @ts-expect-error TS(2322): Type 'string' is not assignable to type '"number" ... Remove this comment to see the full error message
                  type={field.type || 'text'}
                  // @ts-expect-error TS(7015): Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                  value={data?.[field.key]}
                  key={field.key}
                  // @ts-expect-error TS(2339): Property 'options' does not exist on type '{ key: ... Remove this comment to see the full error message
                  options={field.options}
                />
              ))}
            {children}
          </VStack>
        </FormControl>
      ) : null}
      {isSuccess && data && data.length === 0 ? (
        <Center paddingX="xl" paddingY="md">
          <Alert status="warning" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="space-between" space="xs" flexShrink={1}>
                <Alert.Icon />
                {emptyMessage ? (
                  typeof emptyMessage === 'string' ? (
                    <Text flexShrink={1} fontSize="md">
                      {emptyMessage}
                    </Text>
                  ) : (
                    { emptyMessage }
                  )
                ) : null}
              </HStack>
            </VStack>
          </Alert>
        </Center>
      ) : null}
      {isLoading && false
        ? [...Array(10).keys()].map((key, index) => (
            <>
              <Skeleton.Text
                key={index.toString()}
                height={24}
                width={30}
                rounded="md"
                startColor="surfaceHighest"
                isLoaded={false}
                padding="xl"
              />
            </>
          ))
        : null}
      {error ? (
        <Center paddingX="xl" paddingY="md">
          <Alert status="error" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="space-between" space="xs" flexShrink={1}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {errorMessage}
                </Text>
              </HStack>
            </VStack>
          </Alert>
        </Center>
      ) : null}
      {onEditPress ? (
        <VStack space="xl" paddingTop={4}>
          <Button
            rounded="md"
            backgroundColor="primary"
            _text={{
              color: 'onPrimary',
              fontWeight: 'bold',
            }}
            onPress={() => onEditPress && onEditPress()}
          >
            編集
          </Button>
        </VStack>
      ) : null}
      {onSharePress ? (
        <VStack space="xl">
          <Button
            variant="unstyled"
            _text={{ color: gluestackUIConfig.tokens.colors.primary }}
            borderColor="outline"
            onPress={() => onSharePress && onSharePress()}
          >
            <HStack space="xs" alignItems="center">
              <Icon as={Ionicons} name="share-outline" size="md" />
              <Text>チャットで共有</Text>
            </HStack>
          </Button>
        </VStack>
      ) : null}
    </VStack>
  );
};
