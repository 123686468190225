import { Ionicons } from '@expo/vector-icons';
import { HStack, VStack, Text, Image, Icon } from '@gluestack-ui/themed-native-base';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import { NativeStackNavigationProp } from '@react-navigation/native-stack';
import { Platform, TouchableOpacity } from 'react-native';
import { StreamMessage } from 'stream-chat-react';

import { CustomMessageText } from '~/components';
import { useAvatars } from '~/hooks';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  message?: StreamMessage;
  setShowOverlay?: (arg0: boolean) => void;
  handleMessagePress?: (message?: StreamMessage) => void;
  paddingHorizontal?: string;
  query?: string;
}

type RootStackParamList = {
  DMNavigator: {
    screen?: string;
    params: {
      channelId: string;
      messageId: string;
    };
  };
  ChatThreadMessages: {
    channelType: string;
    channelId: string;
    messageId: string;
  };
  ChatChannelMessages: {
    channelType: string;
    channelId: string;
    messageId: string;
  };
};

type NavigationProps = NativeStackNavigationProp<RootStackParamList>;

export const ChatSearchResultItem = ({ message, setShowOverlay, handleMessagePress, paddingHorizontal }: Props) => {
  const navigation = useNavigation<NavigationProps>();
  const { avatarsByChatUserId } = useAvatars();
  const linkTo = useLinkTo();

  const onMessagePress = () => {
    if (handleMessagePress) {
      handleMessagePress(message);
    } else {
      defaultHandleMessagePress();
    }
  };
  const defaultHandleMessagePress = async () => {
    if (Platform.OS === 'web') {
      if (message?.channel?.isDM) {
        navigation.navigate('DMNavigator', {
          screen: 'DirectMessageList',
          params: { channelId: message?.channel.id, messageId: message?.id },
        });
      } else {
        linkTo(`/chat/${message?.channel?.id}/${message?.id}`);
      }
    } else {
      if (message?.channel?.isDM) {
        navigation.navigate(message?.parent_id ? 'ChatThreadMessages' : 'ChatChannelMessages', {
          channelType: message?.channel?.type,
          channelId: message?.channel?.id,
          messageId: message?.id,
        });
      } else {
        if (message) {
          linkTo(`/chat/${message.channel?.id}/${message.id}`);
        }
      }
    }
    if (setShowOverlay) {
      setShowOverlay(false);
    }
  };

  if (!message) {
    return null;
  }

  return (
    <TouchableOpacity onPress={onMessagePress} style={{ flex: 1 }}>
      <VStack space="xs" paddingHorizontal={paddingHorizontal ?? 'md'} padding="xs" style={{ flex: 1 }}>
        <HStack space="xs">
          <Text fontWeight="normal" color="onSurfaceBright" lineHeight="2xs">
            {message?.channel?.name}
          </Text>
        </HStack>
        <HStack flex={1}>
          {avatarsByChatUserId[message?.user?.id as string] ? (
            <Image
              source={{ uri: avatarsByChatUserId[message?.user?.id as string]?.avatar }}
              size="xs"
              borderRadius="full"
              alt=""
            />
          ) : (
            <Icon as={Ionicons} name="person-circle-outline" size="4xl" />
          )}
          <VStack flex={1} marginLeft="xs">
            <HStack alignItems="center" justifyContent="space-between">
              <Text fontSize="md" color="onSurface" bold lineHeight="md">
                {message?.user?.name}
              </Text>
              <Text color="onSurfaceBright">
                {DateUtil.toYYYYMMDDOrMMDD(new Date(message?.created_at as string))}{' '}
                {DateUtil.tohmm(new Date(message?.created_at as string))}
              </Text>
            </HStack>
            <CustomMessageText message={message} isSearch />
          </VStack>
        </HStack>
      </VStack>
    </TouchableOpacity>
  );
};
