import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform } from 'react-native';

import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { streami18n } from '~/hooks/useStreamChat';
import { Chat } from '~/lib/StreamChatReact';
import { ChatDMListScreen, ChatDMCreateEditScreen, ChatMemberListScreen } from '~/screens';

import { AppHeader } from './AppHeader';
import { commonModalScreens } from './BottomTabNavigator';

const Stack = createNativeStackNavigator();

export const DMStackNavigator: React.FC = () => {
  const { chatClient, isUserConnected } = useStreamChatAuthContext();
  if (!chatClient) {
    return null;
  }

  return (
    <Chat client={chatClient} i18nInstance={streami18n}>
      <AppHeader />
      <Stack.Navigator
        // @ts-expect-error TS(2322): Type '({ navigation }: { route: RouteProp<ParamLis... Remove this comment to see the full error message
        screenOptions={({ navigation }) => ({
          headerBackTitle: '戻る',
          title: '通知',
          headerStyle:
            Platform.OS === 'web'
              ? {
                  borderBottomWidth: 0,
                }
              : {},
          headerTitleStyle: {
            fontWeight: 'bold',
          },
        })}
      >
        <Stack.Screen
          name="DirectMessageList"
          component={ChatDMListScreen}
          options={{
            title: 'ダイレクト・メッセージ',
            headerBackTitle: '戻る',
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="ChatDMCreateEdit"
          component={ChatDMCreateEditScreen}
          options={{
            title: 'ダイレクト・メッセージ',
          }}
        />
        {/* @ts-expect-error TS(2322): Type 'FC<Props>' is not assignable to type 'Screen... Remove this comment to see the full error message */}
        <Stack.Screen name="ChatMemberList" component={ChatMemberListScreen} />
        {commonModalScreens}
      </Stack.Navigator>
    </Chat>
  );
};
