import { VStack, Text, HStack, Alert, Center } from '@gluestack-ui/themed-native-base';
import * as React from 'react';

interface Props {
  type: 'error' | 'alert' | 'information';
  message: string | string[];
}

export const AlertBox: React.FC<Props> = ({ type = 'error', message }) => {
  return (
    <Center paddingX="xl" paddingY="md">
      <Alert status={type} maxWidth={400}>
        <VStack flexShrink={1} width="100%">
          {typeof message == 'string' ? (
            <HStack alignItems="center" justifyContent="space-between" space="xs" flexShrink={1}>
              <Alert.Icon />
              <Text flexShrink={1} fontSize="md">
                {message}
              </Text>
            </HStack>
          ) : (
            message?.map((m, i) => (
              <HStack alignItems="center" space="xs" flexShrink={1} key={i}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {m}
                </Text>
              </HStack>
            ))
          )}
        </VStack>
      </Alert>
    </Center>
  );
};
