import { FlatList } from '@gluestack-ui/themed';
import { Box, Divider } from '@gluestack-ui/themed-native-base';
import { usePinMessageWeb } from '~/hooks/usePinMessageWeb';
import { CustomHeader } from './CustomHeader';
import { EmptyMessage } from './EmptyMessage';
import { PinMassageListItem } from './PinMassageListItem';
import type { ReactElement } from 'react';
import type { Message } from 'stream-chat';

const ChatPinnedMessagesWeb = (): ReactElement | null => {
  const { pinMessages } = usePinMessageWeb();

  return (
    <Box height="100%">
      <CustomHeader />
      <FlatList
        data={pinMessages}
        keyExtractor={(item, index) => `${(item as Message).id}:${index}`}
        ItemSeparatorComponent={() => <Divider borderColor="gray.200" />}
        renderItem={({ item, index }) => <PinMassageListItem index={index} item={item as object} />}
        ListEmptyComponent={<EmptyMessage emptyMessage="ピンどめされたメッセージはありません" />}
        marginTop={50}
      />
    </Box>
  );
};

export default ChatPinnedMessagesWeb;
