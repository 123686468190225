import { FlatList } from '@gluestack-ui/themed';
import { VStack, Divider, Spinner } from '@gluestack-ui/themed-native-base';

import { memo, useCallback } from 'react';
import { MessageResponse } from 'stream-chat';
import { ChatSearchResultItem } from '~/components/ChatSearchResultItem';

import { EmptyMessage } from '~/components/EmptyMessage';
import { StreamChatGenerics } from '~/types';

interface Props {
  messages: { message: MessageResponse<StreamChatGenerics> }[];
  paginationSubmit: () => void;
  isReachingEnd: boolean;
  query: string;
}

function Item({ ...props }: MessageResponse<StreamChatGenerics>) {
  return (
    <VStack>
      <ChatSearchResultItem {...props} paddingHorizontal="sm" />
    </VStack>
  );
}

const MemoizedItem = memo(Item);

export const ChatSearchResults = ({ messages, paginationSubmit, isReachingEnd }: Props) => {
  const keyExtractor = useCallback(
    (item: { message: MessageResponse<StreamChatGenerics> }) => `${item.message.id}`,
    []
  );

  const renderItem = useCallback(({ item }: { item: MessageResponse<StreamChatGenerics> & [] }) => {
    return <MemoizedItem {...item} />;
  }, []);

  const ItemSeparatorComponent = useCallback(
    () => (
      <VStack paddingHorizontal="md">
        <Divider backgroundColor="outline" />
      </VStack>
    ),
    []
  );

  return (
    <FlatList
      data={messages}
      // @ts-expect-error
      keyExtractor={keyExtractor}
      ItemSeparatorComponent={ItemSeparatorComponent}
      // @ts-expect-error
      renderItem={renderItem}
      onEndReached={paginationSubmit}
      onEndReachedThreshold={0.3}
      ListEmptyComponent={<EmptyMessage emptyMessage="マッチするメッセージがありませんでした" />}
      ListFooterComponent={!isReachingEnd ? <Spinner my={4} /> : null}
    />
  );
};
