import Ionicons from '@expo/vector-icons/Ionicons';
import { VStack, Text, HStack, Alert, Center, Divider, Icon } from '@gluestack-ui/themed-native-base';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { ReactNode } from 'react';

import { EmptyMessage } from '../EmptyMessage';

import { ListItem } from '~/components/ListItem';
import { Skeleton } from '~/components/Skeleton';

interface Props {
  queryResult: {
    currentData: [];
    data: [];
    error: boolean;
    isSuccess: boolean;
    isError: boolean;
    isLoading: boolean;
    refetch: () => void;
  };
  detailLink?: string;
  emptyMessage?: string | ReactNode;
  max?: number;
  descriptionField?: string;
  onSelect?: (data: any) => void;
  getTitle?: (data: any) => string;
  onAddPress?: () => void;
  addLabel?: string;
  DescriptionComponent?: (item: any) => React.ReactNode;
  showIds?: (number | undefined)[];
}

export const ListBuilder = ({
  queryResult,
  onSelect,
  emptyMessage,
  max = 20,
  descriptionField,
  getTitle,
  onAddPress,
  addLabel,
  DescriptionComponent,
  showIds,
}: Props) => {
  const { data, error, isSuccess, isError, isLoading, refetch } = queryResult;

  const navigation = useNavigation();

  useFocusEffect(() => {
    if (!isSuccess && !isLoading && !isError) {
      refetch();
    }
  });

  // @ts-expect-error error TS2339: Property 'id' does not exist on type 'never'.
  const showDate = showIds !== undefined && data ? data.filter((item) => showIds?.includes(item?.id)) : data;

  return (
    <>
      <VStack space="2xs" divider={<Divider bgColor="outlineVariant" />}>
        {onAddPress ? (
          <ListItem
            key="add-button"
            title={addLabel || '追加'}
            left={<Icon as={Ionicons} name="add-circle" color="primary" />}
            onPress={onAddPress}
            link
          />
        ) : null}
        {showDate
          ? showDate.map((data, index) => {
              return (
                <ListItem
                  // @ts-expect-error TS(2339): Property 'key' does not exist on type 'never'.
                  key={data?.key || index.toString()}
                  // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
                  title={getTitle ? getTitle(data) : data.name}
                  description={descriptionField ? data[descriptionField] : null}
                  onPress={() => {
                    onSelect && onSelect(data);
                  }}
                  DescriptionComponent={DescriptionComponent ? DescriptionComponent(data) : null}
                  arrow
                />
              );
            })
          : null}
        {isSuccess && data.length === 0 ? <EmptyMessage emptyMessage={emptyMessage} /> : null}
      </VStack>
      {isLoading
        ? [...Array(max).keys()].map((key) => (
            <Skeleton.Text key={key} lines={2} rounded="md" startColor="outlineVariant" isLoaded={false} padding="sm" />
          ))
        : null}
      {error ? (
        <Center paddingX="xl" paddingY="md">
          <Alert status="error" maxWidth={400}>
            <VStack flexShrink={1} width="100%">
              <HStack alignItems="center" justifyContent="space-between" space="xs" flexShrink={1}>
                <Alert.Icon />
                <Text flexShrink={1} fontSize="md">
                  {/* @ts-expect-error TS(2339): Property 'message' does not exist on type 'true'. */}
                  {error?.message}
                </Text>
              </HStack>
            </VStack>
          </Alert>
        </Center>
      ) : null}
    </>
  );
};
