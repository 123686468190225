import Ionicons from '@expo/vector-icons/Ionicons';
import { HStack, Text, useTheme, VStack, Icon } from '@gluestack-ui/themed-native-base';
import { ReactNode } from 'react';
import { TouchableOpacity, StyleProp, ViewStyle } from 'react-native';

interface Props {
  title: string;
  value?: string;
  left?: ReactNode;
  right?: ReactNode;
  description?: string | null;
  onPress?: () => void;
  arrow?: boolean;
  DescriptionComponent?: React.ReactNode;
  bold?: boolean;
  link?: boolean;
  leftIconName?: string;
  style?: StyleProp<ViewStyle>;
}

export const ListItem: React.FC<Props> = ({
  title = '',
  left,
  right,
  description,
  value,
  onPress = () => {},
  arrow,
  DescriptionComponent,
  bold,
  link,
  leftIconName,
  style,
}) => {
  const theme = useTheme();

  // Note: TouchableOpacityで囲ってあると left right 内のコンポーネントのTestIDがマッチしなくなるのでListItem内のonPressはテストできない
  return (
    <TouchableOpacity onPress={onPress}>
      <HStack alignItems="center" gap="2xs" justifyContent="space-between" paddingVertical="sm">
        <HStack alignItems="center" gap="3xs">
          {leftIconName && <Icon as={Ionicons} name={leftIconName} color={link ? 'primary' : 'black'} />}
          {left && left}
          <VStack flexShrink={1}>
            <Text
              fontSize="lg"
              fontWeight={bold ? 'medium' : 'normal'}
              color={link ? 'primary' : 'black'}
              fontFamily="Hiragino Sans"
            >
              {title}
            </Text>
            {description && (
              <Text fontSize="md" color="onSurface">
                {description}
              </Text>
            )}
            {DescriptionComponent ? DescriptionComponent : null}
          </VStack>
        </HStack>
        <HStack>
          {value && (
            <Text fontSize="lg" px="0" color="onSurfaceBright">
              {value}
            </Text>
          )}

          {right && right}
          {arrow && (
            <VStack alignItems="center">
              <Ionicons name="chevron-forward" size={theme?.fontSizes['2xl']} color={theme?.colors['muted']?.['400']} />
            </VStack>
          )}
        </HStack>
      </HStack>
    </TouchableOpacity>
  );
};
