import { U_FEED_URL_BASE } from '@env';
import { Ionicons } from '@expo/vector-icons';
import { Box, HStack, Icon, VStack, Button, Heading, Hidden } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { StyledProps } from 'native-base';
import { useState, useCallback, memo } from 'react';
import { Platform, useWindowDimensions } from 'react-native';

import { useGetGroupsByGroupIdMilkingsStatisticQuery } from '~/api/uFeedApi';
import {
  SectionTitle,
  MilkAmountChart,
  MilkAmountCandleChart,
  MilkAmountByCowGroupChart,
  MilkAmountByLactationNumberChart,
} from '~/components/';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { DateUtil } from '~/utils/DateUtils';
import type { FC } from 'react';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  showTable?: boolean;
  type: 'all' | 'group' | 'count' | 'candle';
}

export const MilkAmountChartSwitcher: FC<Props & StyledProps> = memo(
  ({
    endDate,
    range,
    chatChannelId,
    groupId,
    farmId,
    isPreview = false,
    width = 400,
    height = 400,
    barWidth = 8,
    showTable = false,
    type,
    ...remainingProps
  }) => {
    const { chatClient } = useStreamChatAuthContext();
    const navigation = useNavigation();
    const [milkChartType, setMilkChartType] = useState<'all' | 'group' | 'count' | 'candle'>(type);
    const { width: windowWidth } = useWindowDimensions();
    const linkTo = useLinkTo();

    const milkingsStatisticQuery = useGetGroupsByGroupIdMilkingsStatisticQuery(
      groupId
        ? {
            groupId,
          }
        : skipToken
    );

    const shareButton = useCallback(() => {
      return (
        <Button
          mt={2}
          variant="unstyled"
          onPress={async () => {
            const endDateParams = DateUtil.toYYYYMMDD(endDate).split('/');
            const message = `${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/graphs/milk_amount/${endDateParams[0]}/${endDateParams[1]}/${endDateParams[2]}?range=${range}&type=${milkChartType}`;

            const channel = chatClient?.channel('team', chatChannelId);

            if (Platform.OS === 'web') {
              linkTo(`/chat/${channel.id}?message=${encodeURIComponent(message)}`);
            } else {
              // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('ChatChannelMessages', { channelId: channel.id });
            }
          }}
          leftIcon={<Icon as={Ionicons} name="share-outline" size="md" color="onSurface" />}
          testID="milkamount-chart-share-button"
          _text={{ fontWeight: 'bold', color: 'onSurface' }}
        >
          チャットで共有
        </Button>
      );
    }, [chatChannelId, chatClient, endDate, farmId, groupId, linkTo, milkChartType, navigation]);

    return (
      <VStack space={2} {...remainingProps}>
        <Hidden from="md">
          <>
            {milkingsStatisticQuery.data?.has_milking_data && !isPreview && (
              <>
                <SectionTitle title="搾乳量" />
                <Button.Group space="xs" size="md" flexGrow={1} mb={4} justifyContent="center">
                  <Button
                    variant={milkChartType !== 'all' ? 'link' : 'unstyled'}
                    disabled={milkChartType === 'all'}
                    onPress={() => setMilkChartType?.('all')}
                    px={0}
                    borderBottomWidth={2}
                    borderBottomColor={
                      milkChartType !== 'all' ? 'transparent' : gluestackUIConfig.tokens.colors.primary
                    }
                    padding={1}
                    _text={{
                      color:
                        milkChartType !== 'all'
                          ? gluestackUIConfig.tokens.colors.onSurfaceBrightest
                          : gluestackUIConfig.tokens.colors.onSurface,
                      fontSize: 'md',
                    }}
                  >
                    すべて
                  </Button>
                  <Button
                    variant={milkChartType !== 'candle' ? 'link' : 'unstyled'}
                    disabled={milkChartType === 'candle'}
                    onPress={() => setMilkChartType?.('candle')}
                    px={0}
                    borderBottomWidth={2}
                    borderBottomColor={
                      milkChartType !== 'candle' ? 'transparent' : gluestackUIConfig.tokens.colors.primary
                    }
                    padding={1}
                    _text={{
                      color:
                        milkChartType !== 'candle'
                          ? gluestackUIConfig.tokens.colors.onSurfaceBrightest
                          : gluestackUIConfig.tokens.colors.onSurface,
                      fontSize: 'md',
                    }}
                  >
                    変化
                  </Button>
                  <Button
                    variant={milkChartType !== 'group' ? 'link' : 'unstyled'}
                    disabled={milkChartType === 'group'}
                    onPress={() => setMilkChartType?.('group')}
                    px={0}
                    borderBottomWidth={2}
                    borderBottomColor={
                      milkChartType !== 'group' ? 'transparent' : gluestackUIConfig.tokens.colors.primary
                    }
                    padding={1}
                    _text={{
                      color:
                        milkChartType !== 'group'
                          ? gluestackUIConfig.tokens.colors.onSurfaceBrightest
                          : gluestackUIConfig.tokens.colors.onSurface,
                      fontSize: 'md',
                    }}
                  >
                    牛群別
                  </Button>
                  <Button
                    variant={milkChartType !== 'count' ? 'link' : 'unstyled'}
                    disabled={milkChartType === 'count'}
                    onPress={() => setMilkChartType?.('count')}
                    px={0}
                    borderBottomWidth={2}
                    borderBottomColor={
                      milkChartType !== 'count' ? 'transparent' : gluestackUIConfig.tokens.colors.primary
                    }
                    padding={1}
                    _text={{
                      color:
                        milkChartType !== 'count'
                          ? gluestackUIConfig.tokens.colors.onSurfaceBrightest
                          : gluestackUIConfig.tokens.colors.onSurface,
                      fontSize: 'md',
                    }}
                  >
                    産次別
                  </Button>
                </Button.Group>
              </>
            )}
            <Box paddingX="auto" width={width}>
              {!milkingsStatisticQuery.data?.has_milking_data ? null : milkChartType === 'all' ? (
                <MilkAmountChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  showTable={showTable}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={Math.min(windowWidth - 60, 400, width)}
                  height={Math.min(windowWidth - 60, 400, height)}
                />
              ) : milkChartType === 'candle' ? (
                <MilkAmountCandleChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  showTable={showTable}
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  onTypeChange={(type) => setMilkChartType(type)}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={Math.min(windowWidth - 60, 400, width)}
                  height={Math.min(windowWidth - 60, height)}
                />
              ) : milkChartType === 'group' ? (
                <MilkAmountByCowGroupChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  showTable={showTable}
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  onTypeChange={(type) => setMilkChartType(type)}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={Math.min(windowWidth - 60, 400, width)}
                  height={Math.min(windowWidth - 60, 400, height)}
                />
              ) : (
                <MilkAmountByLactationNumberChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  showTable={showTable}
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  onTypeChange={(type) => setMilkChartType(type)}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={Math.min(windowWidth - 60, 400, width)}
                  height={Math.min(windowWidth - 60, 400, height)}
                />
              )}
            </Box>
          </>
        </Hidden>
        <Hidden till="md">
          <VStack space={16}>
            <HStack flexWrap="wrap">
              <Box>
                <HStack justifyContent="center">
                  <Heading fontSize="lg" alignItems="center" mb={4} color="onSurface">
                    授乳量 | すべて
                  </Heading>
                </HStack>
                <MilkAmountChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  paddingX={4}
                  showTable={showTable}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={width}
                />
              </Box>
              <Box>
                <HStack justifyContent="center">
                  <Heading fontSize="lg" alignItems="center" mb={4} color="onSurface">
                    授乳量 | 変化
                  </Heading>
                </HStack>
                <MilkAmountCandleChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  paddingX={4}
                  showTable={showTable}
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  onTypeChange={(type) => setMilkChartType(type)}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={width}
                  height={height}
                />
              </Box>
            </HStack>
            <HStack flexWrap="wrap">
              <Box>
                <HStack justifyContent="center">
                  <Heading fontSize="lg" alignItems="center" mb={4} color="onSurface">
                    授乳量 | 牛群別
                  </Heading>
                </HStack>
                <MilkAmountByCowGroupChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  paddingX={4}
                  showTable={showTable}
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  onTypeChange={(type) => setMilkChartType(type)}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={width}
                  height={height}
                />
              </Box>
              <Box>
                <HStack justifyContent="center">
                  <Heading fontSize="lg" alignItems="center" mb={4} color="onSurface">
                    授乳量 | 産次別
                  </Heading>
                </HStack>
                <MilkAmountByLactationNumberChart
                  chatChannelId={chatChannelId}
                  farmId={farmId}
                  groupId={groupId}
                  endDate={endDate}
                  range={range}
                  paddingX={4}
                  showTable={showTable}
                  // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  onTypeChange={(type) => setMilkChartType(type)}
                  shareButton={shareButton()}
                  isPreview={isPreview}
                  width={width}
                  height={height}
                />
              </Box>
            </HStack>
          </VStack>
        </Hidden>
      </VStack>
    );
  }
);
