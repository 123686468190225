// @ts-nocheck
import { useNavigation } from '@react-navigation/native';
import { VStack, HStack, Text, Button } from 'native-base';
import { useFetchFarmCurrentQuery } from '~/packages/u-motion-api/api/farmApi';

export const UmotionFarmSwitcher = () => {
  const farmCurrentQuery = useFetchFarmCurrentQuery();
  const navigation = useNavigation();

  const onSwitchFarmPress = async () => {
    navigation.navigate('UmotionSwitchFarmModal');
  };

  return (
    <VStack>
      <HStack alignItems="center" justifyContent="space-between" space="md">
        <HStack space="sm">
          <Text bold>牧場:</Text>
          <Text>{farmCurrentQuery?.data?.farmName}</Text>
        </HStack>
        <Button
          onPress={onSwitchFarmPress}
          variant="outline"
          borderRadius="8px"
          backgroundColor="#ffffff"
          colorScheme="orange"
          borderColor="#d9d9d9"
        >
          牧場切替
        </Button>
      </HStack>
    </VStack>
  );
};
