// @ts-nocheck
import { setup, assign } from 'xstate';

export type ApplySensorData = {
  sensorId: string;
  cowUid: string;
};

const normalizeSensorId = (sensorId: string) => {
  return sensorId?.replace(/^0+/, '') ?? '';
};

export const umotionApplySensorMachine = setup({
  types: {
    context: {} as {
      data: ApplySensorData[];
      filteredData: ApplySensorData[];
      dupSensorCows: any[];
      existingCows: object;
    },
    events: {} as
      | { type: 'CHECK_DUPS' }
      | { type: 'DUPS_CHECKED'; dupSensorCows: any[] }
      | { type: 'CONFIRM_PROCESS' }
      | { type: 'COW_CHECKED'; existingCows: object }
      | { type: 'REGISTER_SUCCESS' }
      | { type: 'REGISTER_ERROR' }
      | { type: 'CANCEL' },
    input: {} as { data: ApplySensorData[] },
  },
}).createMachine({
  id: 'umotionApplySensorMachine',
  initial: 'checkingDups',
  context: ({ input }) => ({
    data: input.data,
    filteredData: [],
    dupSensorCows: [],
    existingCows: {},
  }),
  states: {
    exit: {
      type: 'final',
    },
    checkingDups: {
      invoke: {
        src: 'checkDups',
        input: ({ context }) => ({ data: context.data }),
        onDone: {
          target: 'dupsChecked',
          actions: assign({
            dupSensorCows: ({ event }) => event.output,
            filteredData: ({ context, event }) => {
              return context.data.filter(
                (data) =>
                  !event.output
                    .map((dupCow) => normalizeSensorId(dupCow.bleId))
                    .includes(normalizeSensorId(data?.sensor_id?.toString()))
              );
            },
          }),
        },
        onError: 'checkDupsError',
      },
    },
    checkDupsError: {
      type: 'final',
    },
    dupsChecked: {
      always: [
        {
          guard: ({ context }) => context.dupSensorCows.length === 0,
          target: 'checkingCowExists',
        },
      ],
      on: {
        CONFIRM_PROCESS: 'checkingCowExists',
        CANCEL: 'exit',
      },
    },
    checkingCowExists: {
      invoke: {
        src: 'checkCowExists',
        input: ({ context }) => ({ data: context.data }),
        onDone: {
          target: 'cowChecked',
          actions: assign({
            existingCows: ({ event }) => event.output,
            filteredData: ({ context, event }) => {
              return context.filteredData.filter((data) => !!event.output?.[data.uid]);
            },
          }),
        },
        onError: 'checkCowExistsError',
      },
    },
    checkCowExistsError: {
      type: 'final',
    },
    cowChecked: {
      always: [
        {
          guard: ({ context }) => context.filteredData.length === 0,
          target: 'cowCheckedNoCows',
        },
      ],
      on: {
        REGISTER_SUCCESS: 'registered',
        REGISTER_ERROR: 'registerError',
        CANCEL: 'exit',
      },
    },
    cowCheckedNoCows: {
      type: 'final',
    },
    registerError: {
      type: 'final',
    },
    registered: {
      type: 'final',
    },
  },
});
