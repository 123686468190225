import { Text, Spinner, Button, VStack } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';

import { usePostGroupsByGroupIdGroupUsersMutation, useGetUsersQuery } from '~/api/uFeedApi';
import { MultiSelectInput } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';

export const AdminGroupUserCreateScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isCreating, setIsCreating] = useState(false);

  const [post] = usePostGroupsByGroupIdGroupUsersMutation();

  const users = useGetUsersQuery();
  const options =
    users?.data?.map((user) => ({
      id: user.email ? user?.email : user?.sms_phone_number,
      name: user?.name,
    })) ?? [];

  const onSave = async () => {
    let errorCount = 0;
    setIsCreating(true);
    setErrorMessages([]);
    for (const key of selectedUsers) {
      await post({
        // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
        groupId: queryParams.groupId,
        body: {
          // @ts-expect-error TS(2339): Property 'includes' does not exist on type 'never'... Remove this comment to see the full error message
          group_user: key.includes('@')
            ? {
                email: key,
                roles: ['owner'],
              }
            : {
                sms_phone_number: key,
                roles: ['owner'],
              },
        },
      })
        .unwrap()
        .then((resp) => {
          //navigation.goBack();
        })
        .catch((error) => {
          errorCount++;
          if (error.status === 500) {
            setErrorMessages((errorMessages) => [
              ...errorMessages,
              `${options.find((option) => option.id === key)?.name}を追加できませんでした`,
            ]);
          }
          if (error.data?.base) {
            error.data.base.forEach((error: string) => {
              if (error.includes('はすでに存在します')) {
                setErrorMessages((errorMessages) => [
                  ...errorMessages,
                  `${options.find((option) => option.id === key)?.name}はすでに存在します`,
                ]);
              } else {
                setErrorMessages((errorMessages) => [
                  ...errorMessages,
                  `${options.find((option) => option.id === key)?.name}を追加できませんでした`,
                ]);
              }
            });
          }
        });
    }
    if (!errorCount) {
      setSelectedUsers([]);
      navigation.goBack();
    }
    setIsCreating(false);
  };

  return !options.length ? (
    <ScreenWidthModal>
      <BaseScreenBuilder>
        <Spinner />
      </BaseScreenBuilder>
    </ScreenWidthModal>
  ) : (
    <ScreenWidthModal title="グループユーザー管理">
      <BaseScreenBuilder title="グループユーザー管理">
        {errorMessages
          ? errorMessages.map((errorMessage, i) => (
              <Text fontSize="md" fontWeight="medium" color="red.400" key={i}>
                {errorMessage}
              </Text>
            ))
          : null}
        {/* @ts-expect-error TS(2322): Type '{ id: string | undefined; name: string | und... Remove this comment to see the full error message */}
        <MultiSelectInput options={options} selectedItems={selectedUsers} onSelectedItemsChange={setSelectedUsers} />
        <VStack gap="md" marginTop="lg">
          <Button
            onPress={onSave}
            isLoading={isCreating}
            isDisabled={!selectedUsers.length}
            rounded="md"
            backgroundColor="primary"
            _text={{
              color: 'onPrimary',
              fontWeight: 'bold',
            }}
          >
            保存
          </Button>
          <Button
            variant="outline"
            rounded="md"
            borderColor="outline"
            onPress={() => navigation.goBack()}
            _text={{
              color: 'primary',
              fontWeight: 'bold',
            }}
          >
            キャンセル
          </Button>
        </VStack>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
