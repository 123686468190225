import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { Alert, Text, HStack } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation, useIsFocused, useFocusEffect } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';

import {
  usePostGroupsByGroupIdTasksMutation,
  useGetTasksByIdQuery,
  usePutTasksByIdMutation,
  useGetCurrentUserGroupsQuery,
  useGetCurrentUserQuery,
} from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { Skeleton } from '~/components/Skeleton';
import { useCustomToast } from '~/hooks/useCustomToast';
import { useGroupChat } from '~/hooks/useGroupChat';

export const TaskCreateEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  const userGroups = useGetCurrentUserGroupsQuery();
  const [post, { isLoading: isUpdating, error: postError }] = usePostGroupsByGroupIdTasksMutation();
  const [put, putResult] = usePutTasksByIdMutation();

  const toast = useCustomToast();
  // @ts-expect-error TS(2322): Type 'undefined' is not assignable to type 'number... Remove this comment to see the full error message
  const { share } = useGroupChat({ farmId: undefined, groupId: undefined });

  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const [selectedGroupId, setSelectedGroupId] = useState(queryParams?.groupId ?? undefined);
  const [assigneeOptions, setAssigneeOptions] = useState([]);

  const isFocused = useIsFocused();
  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const isPost = !!(!queryParams?.id || queryParams?.id === 'undefined');

  // @ts-expect-error TS(2345): Argument of type 'GetCurrentUserGroupsApiResponse ... Remove this comment to see the full error message
  const groupOptions = getGroupOptions(userGroups?.data);
  const taskQueryResult = useGetTasksByIdQuery(
    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
    { id: queryParams?.id },
    {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      skip: !queryParams?.id,
    }
  );
  const cuurentUser = useGetCurrentUserQuery(
    {},
    {
      // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
      skip: queryParams?.id,
    }
  );
  const taskData = taskQueryResult.data;
  const [defaultValues, setDefaultValues] = useState({
    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
    groupId: queryParams?.groupId,
    status: taskData?.status,
    deadline: taskData?.deadline ? taskData?.deadline?.substring(0, 10) : undefined,
    assigned_user_id: taskData?.assigned_user?.id,
    content: taskData?.content,
  });

  const updateDefaultValues = () => {
    if (!taskQueryResult.isSuccess) {
      return;
    }
    const taskData = taskQueryResult.data;

    setDefaultValues({
      // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
      groupId: queryParams?.groupId,
      status: taskData?.status,
      deadline: taskData?.deadline ? taskData?.deadline?.substring(0, 10) : undefined,
      assigned_user_id: taskData?.assigned_user?.id,
      content: taskData?.content,
    });
    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
    setSelectedGroupId(queryParams?.groupId);
  };

  useDidUpdate(updateDefaultValues, [taskQueryResult.isSuccess]);

  const updateAssigneeOptions = () => {
    if (!selectedGroupId || !userGroups.data) {
      return;
    }
    const selectedGroup = userGroups?.data?.find((group) => group.id === Number(selectedGroupId));

    const groupUsers = selectedGroup ? selectedGroup.approved_users : [];

    setAssigneeOptions(
      // @ts-expect-error TS(2345): Argument of type '{ value: number | undefined; lab... Remove this comment to see the full error message
      groupUsers?.map((user) => ({
        value: user.id,
        label: user.name,
      })) ?? []
    );
  };

  const showToast = async (resp: any, farmId: any, groupId: any, chatChannelId: any) => {
    if (resp) {
      toast.showWithShare('タスクを作成しました', () => {
        share(
          `タスクを作成しました\n${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/tasks/${resp.id}`,
          chatChannelId
        );
      });
    }
  };

  const onCancel = () => {
    navigation.goBack();
  };

  useFocusEffect(
    React.useCallback(() => {
      userGroups.refetch();
    }, [isFocused])
  );

  useDidUpdate(
    () => {
      updateAssigneeOptions();
    },
    [selectedGroupId, userGroups.data],
    true
  );

  useDidUpdate(() => {
    updateAssigneeOptions();
  }, [defaultValues]);

  useDidUpdate(
    () => {
      if (isPost) {
        setDefaultValues({
          // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
          groupId: queryParams?.groupId,
          assigned_user_id: cuurentUser.data?.id,
          status: undefined,
          deadline: undefined,
          content: undefined,
        });
      }
    },
    [cuurentUser.isSuccess],
    true
  );

  const onSubmit = (sendingData: any) => {
    setErrorMessages((errorMessages) => []);
    const { assigned_user_id, content, deadline } = sendingData;
    const groupId = sendingData.groupId;
    const group = userGroups.data?.find((group) => group.id == groupId);
    const chatChannelId = group?.chat_channel_id;
    const farmId = group?.farm_id;
    if (isPost) {
      post({
        groupId,
        body: {
          task: {
            assigned_user_id,
            content,
            deadline,
            sub_tasks: [],
          },
        },
      })
        .unwrap()
        .then((resp) => {
          showToast(resp, farmId, groupId, chatChannelId);
          navigation.goBack();
        })
        .catch((error) => {
          setErrorMessages(errorMessageBuilder(error, fields));
        });
    } else {
      put({
        // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
        id: queryParams?.id,
        body: {
          task: {
            assigned_user_id,
            content,
            deadline,
            sub_tasks: [],
          },
        },
      })
        .unwrap()
        .then((resp) => {
          navigation.goBack();
        })
        .catch((error) => {
          setErrorMessages(errorMessageBuilder(error, fields));
        });
    }
  };

  const fields = [
    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
    ...(!queryParams?.groupId || queryParams?.groupId === 'undefined'
      ? [
          {
            key: 'groupId',
            label: 'グループ',
            type: 'select',
            rules: {
              required: {
                value: true,
                message: 'グループを選択してください',
              },
            },
            options: groupOptions,
            watch: true,
          },
        ]
      : []),
    {
      key: 'content',
      label: '内容',
      disabled: !selectedGroupId,
      rules: {
        required: {
          value: true,
          message: '内容を入力してください',
        },
      },
    },
    {
      key: 'deadline',
      label: '締め切り',
      type: 'date',
      disabled: !selectedGroupId,
    },
    {
      key: 'assigned_user_id',
      label: '担当',
      type: 'select',
      options: assigneeOptions,
      disabled: !selectedGroupId || !assigneeOptions.length,
      rules: {
        required: {
          value: true,
          message: '担当を設定してください',
        },
      },
    },
  ];
  if (taskQueryResult.isLoading) {
    return <Skeleton.Text />;
  }

  return (
    <ScreenWidthModal title="タスク作成・編集">
      <BaseScreenBuilder title="タスク作成・編集">
        <BaseFormBuilder
          isUpdating={isUpdating}
          error={postError || userGroups.error || putResult.error}
          onCancel={onCancel}
          onSubmit={onSubmit}
          onFieldUpdate={(updated) => {
            if (updated.groupId !== selectedGroupId) {
              setSelectedGroupId(updated.groupId);
            }
          }}
          defaultValues={defaultValues}
          // @ts-expect-error TS(2322): Type '({ key: string; label: string; type: string;... Remove this comment to see the full error message
          fields={fields}
          errorMessage={errorMessages}
        >
          <Alert marginBottom={4} status="warning">
            <HStack space={2} alignItems="center" width="100%">
              <Alert.Icon />
              <Text bold>同じグループのメンバーが閲覧できます</Text>
            </HStack>
          </Alert>
        </BaseFormBuilder>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};

const getGroupOptions = (groups: []) =>
  groups
    ? groups.map((group) => ({
        // @ts-expect-error TS(2339): Property 'id' does not exist on type 'never'.
        value: group.id,
        // @ts-expect-error TS(2339): Property 'name' does not exist on type 'never'.
        label: group.name,
      }))
    : [];
