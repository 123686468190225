import { Box } from '@gluestack-ui/themed-native-base';
import { useLinkTo } from '@react-navigation/native';
import { getAnalytics, logEvent } from 'firebase/analytics';
import React from 'react';
import { ChannelPreviewUIComponentProps } from 'stream-chat-react';

import { ChannelPreviewMessenger } from '~/components/ChannelPreviewMessenger';
import { ChatCustomChannelAvatar } from '~/components/ChatCustomChannelAvatar';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';
import { channelType, getStreamChatChannelTypeById } from '~/screens/ChatChannelMessagesScreen/channelType';
import { StreamChatGenerics } from '~/types';
import { CustomDMListAvatar } from './CustomDMListAvatar';

const UnMemoizedChannelPreviewMessenger = (
  props: ChannelPreviewUIComponentProps<StreamChatGenerics> & { isDM?: boolean }
) => {
  const { chatClient, setAppChannel } = useStreamChatContext();
  const { channel } = props;
  const linkTo = useLinkTo();
  const { pinnedChannels } = usePinnedChannels();
  const onSelectChannel = (_event: any) => {
    if (channel.data?.isDM) {
      linkTo(`/chat`);
      const newChannel = chatClient.channel(getStreamChatChannelTypeById(channel.id ?? ''), channel.id);
      setAppChannel(newChannel);
    } else {
      linkTo(`/chat/${channel.id}`);
      const newChannel = chatClient.channel(getStreamChatChannelTypeById(channel.id ?? ''), channel.id);
      setAppChannel(newChannel);
    }
  };

  try {
    const analytics = getAnalytics();
    logEvent(analytics, 'select_channel', { channel_type: `${channelType(channel.id ?? '')}` });
  } catch (error) {
    console.error('Failed to log event: ', error);
  }

  const ChannelAvatar = React.useCallback(() => {
    return (
      <Box paddingLeft={2}>
        {props.isDM ? <CustomDMListAvatar channel={channel} /> : <ChatCustomChannelAvatar channel={channel} />}
      </Box>
    );
  }, [props.isDM, channel]);

  const memberIds = Object.keys(channel?.state?.members);
  const channelTitle =
    !channel?.data?.isDM || memberIds.length <= 1
      ? props.displayTitle
      : memberIds
          .filter((key) => key !== chatClient.userID || memberIds.length === 1)
          .map((key) => channel?.state?.members[key]?.user?.name)
          .join(', ');
  const messages = channel?.state.messages;
  const lastMessage = messages.length ? messages[messages.length - 1] : undefined;

  return (
    <ChannelPreviewMessenger
      {...props}
      isPinned={pinnedChannels.includes(channel.cid)}
      displayTitle={channelTitle}
      Avatar={ChannelAvatar}
      latestMessage={lastMessage?.text?.split(/\n/)?.[0]}
      onSelect={onSelectChannel}
      unread={channel.countUnreadMentions()}
      isDM={!!channel.data?.isDM}
    />
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const CustomChannelPreviewMessenger = React.memo(
  UnMemoizedChannelPreviewMessenger
) as typeof UnMemoizedChannelPreviewMessenger;
