import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { FormControl, Input, VStack, HStack, Text, Radio } from '@gluestack-ui/themed-native-base';
import { Logic } from 'native-base-form-builder';
import * as React from 'react';
import { useController } from 'react-hook-form';

import { MultiSelectInput } from '~/components';

import { FormFieldLabel } from './FormFieldLabel';

const { useState } = React;

export const FormGroupDetails: React.FC<React.ComponentProps<typeof Logic>> = ({
  label,
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  options = [],
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  const [value, setValue] = useState('0');
  const [selectedHerds, setSelectedHerds] = useState([]);
  const [selectedPens, setSelectedPens] = useState([]);
  const [from, setFrom] = useState(undefined);
  const [to, setTo] = useState(undefined);

  const errorMessage = formState.errors?.[field.name]?.message;
  const isRequired = !!rules?.required;

  // @ts-expect-error TS(2769): No overload matches this call.
  const pens = options.reduce((accumulator, option, currentIndex) => {
    // @ts-expect-error TS(2339): Property 'pens' does not exist on type '{ label: s... Remove this comment to see the full error message
    if (option.pens) {
      // @ts-expect-error TS(2339): Property 'pens' does not exist on type '{ label: s... Remove this comment to see the full error message
      const herdsPens = option.pens?.split(',').map((pen: any, i: any) => {
        return {
          id: `${String(currentIndex)}${String(i)}`,
          // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ label: str... Remove this comment to see the full error message
          herdId: option.id,
          pen,
          // @ts-expect-error TS(2339): Property 'name' does not exist on type '{ label: s... Remove this comment to see the full error message
          name: `${option.name}（${pen}）`,
        };
      });
      return [...accumulator, ...herdsPens];
    } else {
      return [
        ...accumulator,
        {
          id: String(currentIndex),
          // @ts-expect-error TS(2339): Property 'id' does not exist on type '{ label: str... Remove this comment to see the full error message
          herdId: option.id,
          // @ts-expect-error TS(2339): Property 'name' does not exist on type '{ label: s... Remove this comment to see the full error message
          name: option.name,
        },
      ];
    }
  }, []);

  const setDefaultValues = (defaultValue: any) => {
    if (!defaultValue || defaultValue.length == 0) {
      field.onChange('none');
      return;
    }
    const type = Object.keys(defaultValue[0])[0];
    if (type === 'month_age') {
      setFrom(defaultValue[0].month_age.from);
      setTo(defaultValue[0].month_age.to);
      field.onChange([{ month_age: { from: defaultValue[0].month_age.from, to: defaultValue[0].month_age.to } }]);
      setValue('3');
    } else if (
      defaultValue.filter((val: any) => {
        return 'um_cattle_group' in val && 'pen' in val.um_cattle_group;
      }).length > 0
    ) {
      onSelectedPensChange(
        defaultValue.map((obj: any) => {
          // @ts-expect-error TS(2339): Property 'find' does not exist on type '{ label: s... Remove this comment to see the full error message
          return pens?.find((pen: any) => pen.herdId == obj.um_cattle_group.id && pen.pen == obj.um_cattle_group?.pen)
            ?.id;
        })
      );
      setValue('2');
    } else {
      onSelectedHerdsChange(
        defaultValue.map((obj: any) => {
          return obj.um_cattle_group?.id;
        })
      );
      setValue('1');
    }
  };

  const onSelectedHerdsChange = (newSelectedHerds: any) => {
    setSelectedHerds(newSelectedHerds);
    field.onChange(
      newSelectedHerds.map((newSelectedHerd: any) => {
        return { um_cattle_group: { id: newSelectedHerd } };
      })
    );
  };
  const onSelectedPensChange = (newSelectedPens: any) => {
    // @ts-expect-error TS(2339): Property 'filter' does not exist on type '{ label:... Remove this comment to see the full error message
    setSelectedPens(pens?.filter((pen: any) => newSelectedPens.includes(pen.id)).map((pen: any) => pen.id));
    field.onChange(
      newSelectedPens.map((newSelectedPen: any) => {
        // @ts-expect-error TS(2339): Property 'find' does not exist on type '{ label: s... Remove this comment to see the full error message
        const pen = pens?.find((pen: any) => pen.id == newSelectedPen);
        return { um_cattle_group: { id: pen?.herdId, pen: pen?.pen } };
      })
    );
  };

  const onChangeMonthAge = (value: any, state: any, type: any, setFunction: any) => {
    if (!isNumber(value) && value !== '') {
      field.onChange(undefined);
      return;
    }
    setFunction(value);
    if (
      value === undefined ||
      value === null ||
      value === '' ||
      state === undefined ||
      state === null ||
      state === ''
    ) {
      field.onChange(undefined);
    } else {
      type == 'from'
        ? field.onChange([{ month_age: { from: value, to: state } }])
        : field.onChange([{ month_age: { from: state, to: value } }]);
    }
  };

  const onChange = (value: any) => {
    setValue(value);
    field.onChange(value === '0' ? 'none' : undefined);
    setSelectedHerds([]);
    setSelectedPens([]);
    setFrom(undefined);
    setTo(undefined);
  };

  useDidUpdate(
    () => {
      if (value === '0' && field.value !== 'none') {
        setDefaultValues(field.value);
      }
    },
    [field.value],
    true
  );

  return (
    <VStack space="xs" flex={1} zIndex={1000}>
      <HStack space="none" alignItems="center">
        <FormFieldLabel label={label} isRequired={isRequired} />
      </HStack>
      <Radio.Group
        value={value}
        name="myRadioGroup"
        accessibilityLabel="詳細の種類を選択"
        // @ts-expect-error
        onChange={(value) => onChange(value)}
      >
        <Radio value="0" marginBottom="2xs" _icon={{ color: 'black' }}>
          なし
        </Radio>
        <Radio value="1" marginBottom="2xs" _icon={{ color: 'black' }}>
          牛群
        </Radio>
        <Radio value="2" marginBottom="2xs" _icon={{ color: 'black' }}>
          牛群＋牛房
        </Radio>
        <Radio value="3" marginBottom="2xs" _icon={{ color: 'black' }}>
          月齢
        </Radio>
      </Radio.Group>
      {value == '1' ? (
        <FormControl isRequired={isRequired} isInvalid={errorMessage}>
          <MultiSelectInput
            // @ts-expect-error TS(2322): Type 'OPTIONS' is not assignable to type 'option[]... Remove this comment to see the full error message
            options={options}
            selectedItems={selectedHerds}
            onSelectedItemsChange={onSelectedHerdsChange}
          />
          {errorMessage ? <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage> : null}
        </FormControl>
      ) : value == '2' ? (
        <FormControl isRequired={isRequired} isInvalid={errorMessage}>
          {/* @ts-expect-error TS(2740): Type '{ label: string; value: string | number; }' ... Remove this comment to see the full error message */}
          <MultiSelectInput options={pens} selectedItems={selectedPens} onSelectedItemsChange={onSelectedPensChange} />
          {errorMessage ? <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage> : null}
        </FormControl>
      ) : value == '3' ? (
        <FormControl isRequired={isRequired} isInvalid={errorMessage}>
          <Text fontSize="xs" fontWeight="medium" color="onSurfaceBright">
            月齢
          </Text>
          <HStack>
            <Input
              width={130}
              key="from"
              // @ts-expect-error TS(2339): Property 'value' does not exist on type 'number'.
              onChange={(e) => onChangeMonthAge(e.currentTarget.value, to, 'from', setFrom)}
              value={from}
              // @ts-expect-error
              onChangeText={(text) => onChangeMonthAge(text, to, 'from', setFrom)}
              maxLength={5}
              keyboardType="numeric"
              color="onSurface"
              fontSize="md"
              size="lg"
              variant="outline"
              borderColor="outline"
              py="sm"
              _focus={{
                borderColor: 'primary',
                backgroundColor: 'surfaceBrightest',
              }}
            />
            <Text fontSize="xs" fontWeight="medium" color="onSurfaceBright" padding="2xs">
              〜
            </Text>
            <Input
              width={130}
              key="to"
              // @ts-expect-error TS(2339): Property 'value' does not exist on type 'number'.
              onChange={(e) => onChangeMonthAge(e.currentTarget.value, from, 'to', setTo)}
              value={to}
              // @ts-expect-error
              onChangeText={(text) => onChangeMonthAge(text, from, 'to', setTo)}
              maxLength={5}
              keyboardType="numeric"
              color="onSurface"
              fontSize="md"
              size="lg"
              variant="outline"
              borderColor="outline"
              py="sm"
              _focus={{
                borderColor: 'primary',
                backgroundColor: 'surfaceBrightest',
              }}
            />
          </HStack>
          {errorMessage ? <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage> : null}
        </FormControl>
      ) : null}
    </VStack>
  );
};

const isNumber = (val: any) => {
  const regexp = new RegExp(/^[0-9]+(\.[0-9]+)?$/);
  return regexp.test(val);
};
