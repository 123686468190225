import { Pressable, Text, HStack } from '@gluestack-ui/themed-native-base';
import React from 'react';
import { Platform } from 'react-native';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import type { ColorType } from 'native-base/lib/typescript/components/types';

interface Props {
  onPress?: () => void;
  label?: string;
  labelColor?: ColorType;
  isPrimary?: boolean;
}

export const HeaderRight: React.FC<Props & React.PropsWithChildren> = ({
  onPress,
  label,
  labelColor = gluestackUIConfig.tokens.colors.onSurfaceBright,
  isPrimary,
  children,
}) => {
  return (
    <Pressable
      onPress={onPress}
      _pressed={{
        backgroundColor: gluestackUIConfig.tokens.colors.surface,
      }}
    >
      <HStack
        alignItems="center"
        borderRadius={30}
        borderWidth={isPrimary ? 1 : 0}
        borderColor="blue.500"
        paddingX={Platform.OS === 'web' || isPrimary ? 4 : 0}
      >
        <Text fontSize="lg" fontWeight="medium" color={labelColor}>
          {label}
        </Text>
        {children}
      </HStack>
    </Pressable>
  );
};
