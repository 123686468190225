import { Text, VStack, Tooltip, Box } from '@gluestack-ui/themed-native-base';
import { memo, useEffect } from 'react';

import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import ActionsIcons from '~/icons/ActionsIcons';
import ReactionIcons from '~/icons/ReactionIcons';
import ThreadIcons from '~/icons/ThreadIcons';
import { isMessageEdited, MessageOptions, useMessageContext } from '~/lib/StreamChatReact';
import type { DefaultStreamChatGenerics, StreamMessage } from '~/lib/StreamChatReact';

import { StreamChatGenerics } from '~/types';
import { CustomMessageTimestamp } from './CustomMessageTimestamp.web';
import type { ReactElement } from 'react';

type Props = {
  message: StreamMessage<DefaultStreamChatGenerics>;
};

const TooltipWrapper = ({ label, children }: { label: string; children: ReactElement }) => (
  <Tooltip
    label={label}
    placement="top"
    openDelay={100}
    bg="inverseSurface"
    _text={{ color: 'white' }}
    borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
    closeOnClick
  >
    <Box width={6} height={6} justifyContent="center" alignItems="center">
      {children}
    </Box>
  </Tooltip>
);

const ReactionIcon = () => {
  return (
    <TooltipWrapper label="リアクションする">
      <ReactionIcons />
    </TooltipWrapper>
  );
};

const ThreadIcon = () => {
  return (
    <TooltipWrapper label="スレッド返信する">
      <ThreadIcons />
    </TooltipWrapper>
  );
};

const ActionsIcon = () => {
  return (
    <TooltipWrapper label="その他">
      <ActionsIcons />
    </TooltipWrapper>
  );
};

const CustomMessageOptionsWithContext = ({ message }: Props): ReactElement | null => {
  const isEdited = isMessageEdited(message);
  const { isMyMessage } = useMessageContext<StreamChatGenerics>('MessageStatus');

  useEffect(() => {
    if (message?.id) {
      const escapedId = CSS.escape(message.id);

      const parentDiv = document.querySelector(`#${escapedId}`);

      if (parentDiv) {
        const actionBoxButton = parentDiv.querySelector('.str-chat__message-actions-box-button') as HTMLButtonElement;

        const handleAnyButtonClick = (event: Event) => {
          if (actionBoxButton && actionBoxButton.getAttribute('aria-expanded') === 'true') {
            actionBoxButton.click();
          }
        };

        const allActionBoxButtons = document.querySelectorAll('.str-chat__message-actions-box-button');
        allActionBoxButtons.forEach((button) => button.addEventListener('click', handleAnyButtonClick));

        return () => {
          allActionBoxButtons.forEach((button) => button.removeEventListener('click', handleAnyButtonClick));
        };
      }
    }
  }, [message?.id]);

  return (
    <VStack style={{ position: 'relative', minWidth: '100px', zIndex: 'unset' }}>
      <VStack
        style={[
          { position: 'absolute', bottom: 0, minWidth: '100px', zIndex: 'unset' },
          isMyMessage() ? { right: 0 } : { left: 0 },
        ]}
        justifyContent="flex-end"
        alignItems={isMyMessage() ? 'flex-end' : 'flex-start'}
      >
        <VStack px="2xs" style={{ zIndex: 'unset' }}>
          <div id={message?.id} style={{ height: gluestackUIConfig.tokens.space[6] }}>
            <MessageOptions
              ReactionIcon={ReactionIcon}
              ThreadIcon={ThreadIcon}
              ActionsIcon={ActionsIcon}
              displayReplies={!message.show_in_channel}
            />
          </div>
        </VStack>
        <VStack px="xs">
          <Text color="onSurfaceBright" fontSize="sm">
            {isEdited && '編集済み'}
          </Text>
          <CustomMessageTimestamp />
        </VStack>
      </VStack>
    </VStack>
  );
};

const MemorizedCustomMessageOptions = memo(
  CustomMessageOptionsWithContext,
  (prev, next) => prev.message.show_in_channel === next.message.show_in_channel
);

const CustomMessageOptions = (): ReactElement | null => {
  const { message } = useMessageContext();
  return <MemorizedCustomMessageOptions message={message} />;
};

export default CustomMessageOptions;
