import { Ionicons } from '@expo/vector-icons';
import { LinearGradient, ImageBackground } from '@gluestack-ui/themed';
import { HStack, Icon, Pressable, Text, View, VStack } from '@gluestack-ui/themed-native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { LinearGradient as ExpoLinearGradient } from 'expo-linear-gradient';
import { DimensionValue } from 'react-native';
import { useGetCurrentUserSettingQuery, usePutCurrentUserSettingMutation } from '~/api/uFeedApi';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
// import { changeChatBackgroundColor, userSettingSelector } from '~/slices/userSettingSlice';
import { useAppDispatch } from '~/store';
import type { StackNavigationProp } from '@react-navigation/stack';
export const BackgroundChannelPreviewScreen: React.FC = () => {
  const navigation = useNavigation<StackNavigationProp<any>>();
  const route = useRoute();
  // @ts-expect-error TS(2339): Property 'colorI' does not exist on type 'object'.
  const param = route.params?.colorI ? route.params?.colorI : route.params?.localImageUri;
  const { appChannel, chatUserId } = useStreamChatContext();
  const dispatch = useAppDispatch();
  // const userSetting = useAppSelector(userSettingSelector);
  const userSetting = useGetCurrentUserSettingQuery();
  const [put] = usePutCurrentUserSettingMutation();
  const userChatBackground = userSetting.data?.details?.channel_background ?? {};

  const hexRegex = /^#([0-9A-F]{3}|[0-9A-F]{6})$/i;

  navigation?.setOptions({
    title: '',
    headerStyle: {
      backgroundColor: hexRegex.test(param) ? param : gluestackUIConfig.tokens.colors.onPrimary,
    },
    headerLeft: () => (
      <HStack alignItems="center" gap="2xs">
        <Icon
          onPress={() => {
            navigation.goBack();
          }}
          as={Ionicons}
          name="chevron-back"
          size="2xl"
          color="onSurface"
        />
        <Text fontSize="xl" bold color="onSurface">
          プレビュー確認
        </Text>
      </HStack>
    ),
    headerRight: () => (
      <Icon
        onPress={() => {
          navigation.goBack();
        }}
        as={Ionicons}
        name="close"
        size="xl"
        color="onSurface"
      />
    ),
  });

  const addColorChannel = async (channelId: string, color: string) => {
    await put({
      body: {
        user_setting: {
          details: {
            ...userSetting.data?.details,
            channel_background: {
              ...(userSetting.data?.details?.channel_background || {}),
              [channelId]: color,
            },
          },
        },
      },
    }).unwrap();
  };

  return (
    <ImageBackground
      style={{ flex: 1 }}
      source={{
        uri: hexRegex.test(param) ? '' : param,
      }}
      backgroundColor={hexRegex.test(param) ? param : ''}
    >
      <VStack flex="1" paddingTop="62" justifyContent="space-between">
        <VStack space="md" paddingHorizontal="xs">
          <HStack space="xs">
            <View width={30} height={30} backgroundColor="noticeContainer" borderRadius={99} />
            <VStack>
              <Text color="onSurface" fontSize="sm" bold>
                Chumly
              </Text>
              <View
                backgroundColor="surfaceBrightest"
                paddingHorizontal="sm"
                paddingVertical="xs"
                borderBottomLeftRadius={16}
                borderBottomRightRadius={16}
                borderTopRightRadius={16}
                minWidth={240}
              >
                <Text color="onSurface" fontSize="md">
                  こちらはいかがですか？
                </Text>
              </View>
            </VStack>
            <VStack alignItems="flex-end">
              <Text color="onSurfaceBright" fontSize="sm" position="absolute" bottom="0" left="0">
                17:19
              </Text>
            </VStack>
          </HStack>
          <HStack space="xs" justifyContent="flex-end">
            <VStack justifyContent="flex-end">
              <Text color="onSurfaceBright" fontSize="sm" bottom="0" left="0">
                17:19
              </Text>
            </VStack>
            <VStack>
              <LinearGradient
                borderBottomLeftRadius={16}
                borderBottomRightRadius={16}
                borderTopLeftRadius={16}
                paddingHorizontal={gluestackUIConfig.tokens.space.md}
                paddingVertical={gluestackUIConfig.tokens.space.xs}
                // @ts-ignore
                colors={[gluestackUIConfig.tokens.colors.primary, gluestackUIConfig.tokens.colors.primaryContainer]}
                start={[0.2, 0] as unknown as DimensionValue}
                end={[1, 1] as unknown as DimensionValue}
                as={ExpoLinearGradient}
              >
                <Text color="onPrimary" fontSize="md">
                  いいですね！
                </Text>
              </LinearGradient>
            </VStack>
          </HStack>
        </VStack>

        <HStack px="md" py="sm" gap="sm" justifyContent="space-between" backgroundColor="onPrimary">
          <Pressable
            onPress={() => {
              navigation.goBack();
              // @ts-expect-error TS(2339): Property 'colorI' does not exist on type 'object'.
              if (userChatBackground[appChannel?.id as string] === route.params?.colorI) {
                addColorChannel(appChannel?.id as string, gluestackUIConfig.tokens.colors.surfaceBrightest);
              }
            }}
            flex={1}
            borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
            borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
            borderColor="outline"
            backgroundColor="surfaceBrightest"
            height={46}
            alignItems="center"
            justifyContent="center"
          >
            <Text color="primary" fontWeight="bold" fontSize="md">
              キャンセル
            </Text>
          </Pressable>
          <Pressable
            onPress={() => {
              addColorChannel(appChannel?.id as string, param);
              navigation.navigate('ChatChannelMessages', { channelId: appChannel?.id });
            }}
            flex={1}
            borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
            backgroundColor="primaryContainer"
            height={44}
            alignItems="center"
            justifyContent="center"
          >
            <Text color="onPrimary" fontWeight="bold" fontSize="md">
              適用する
            </Text>
          </Pressable>
        </HStack>
      </VStack>
    </ImageBackground>
  );
};
