// @ts-nocheck
import { baseApi } from '../uMotionBaseQuery';

export interface CowGroup {
  cowGroupId: number;
  cowshedId: number;
  cowGroupName: string;
  cowCount: number;
  calfGroup: boolean;
  reproductionGroup: boolean;
  cullingGroup: boolean;
  milkingGroup: boolean;
  dryGroup: boolean;
  fatteningGroup: boolean;
  menuDisplayed: boolean;
  cowshedName: string;
  pens: string;
}

const CACHE_TAG_TYPE = 'CowGroup';
const apiWithTag = baseApi.enhanceEndpoints({ addTagTypes: [CACHE_TAG_TYPE] });

const mutationResponseHandler = (resp) => {
  if (resp.ok === true) {
    return Promise.resolve({});
  } else {
    return Promise.reject(`ERROR: ${resp.url}`);
  }
};

export const cowGroupApi = apiWithTag.injectEndpoints({
  endpoints: (builder) => ({
    fetchCowGroupIndex: builder.query<CowGroup[], void>({
      query: (response) => ({
        url: '/cow_group',
        body: response,
      }),
      providesTags: [CACHE_TAG_TYPE],
    }),
    fetchCowGroup: builder.query<CowGroup[], void>({
      query: (response) => ({
        url: '/cow_group/summary',
        body: response,
      }),
      providesTags: (result) =>
        result && Array.isArray(result)
          ? [
              ...result?.map?.(({ cowGroupId }) => ({ type: CACHE_TAG_TYPE, id: cowGroupId }) as const),
              { type: CACHE_TAG_TYPE, id: 'LIST' },
            ]
          : [{ type: CACHE_TAG_TYPE, id: 'LIST' }],
    }),
    fetchCowGroupById: builder.query<CowGroup, number>({
      query: (cowGroupId) => `/cow_group/${cowGroupId}`,
      providesTags: (result) => [{ type: CACHE_TAG_TYPE, id: result?.cowGroupId }],
    }),
    createCowGroup: builder.mutation<CowGroup, Partial<CowGroup> & Pick<CowGroup, 'cowshedId' | 'cowGroupName'>>({
      query: (newCowGroup) => ({
        url: '/cow_group',
        method: 'POST',
        body: newCowGroup,
        responseHandler: mutationResponseHandler,
      }),
      invalidatesTags: [{ type: CACHE_TAG_TYPE, id: 'LIST' }],
    }),
    updateCowGroup: builder.mutation<CowGroup, Partial<CowGroup> & Pick<CowGroup, 'cowGroupId'>>({
      query: (newCowGroup) => ({
        url: `/cow_group/${newCowGroup.cowGroupId}`,
        method: 'PUT',
        body: newCowGroup,
        responseHandler: mutationResponseHandler,
      }),
      invalidatesTags: (result, error, { cowGroupId }) => [{ type: CACHE_TAG_TYPE, id: cowGroupId }],
    }),
    deleteCowGroup: builder.mutation<CowGroup, number>({
      query: (cowGroupId) => {
        return {
          url: `/cow_group/${cowGroupId}`,
          method: 'DELETE',
          responseHandler: mutationResponseHandler,
        };
      },
      invalidatesTags: (result, error) => [{ type: CACHE_TAG_TYPE, id: 'LIST' }],
    }),
    fetchCowGroupActive: builder.query<CowGroup[], void>({
      query: (response) => ({
        url: '/cow_group/active',
        body: response,
      }),
      providesTags: (result) =>
        result && Array.isArray(result)
          ? [
              ...result?.map?.(({ cowGroupId }) => ({ type: CACHE_TAG_TYPE, id: cowGroupId }) as const),
              { type: CACHE_TAG_TYPE, id: 'LIST' },
            ]
          : [{ type: CACHE_TAG_TYPE, id: 'LIST' }],
    }),
  }),
  overrideExisting: false,
});

export const {
  useFetchCowGroupIndexQuery,
  useFetchCowGroupQuery,
  useFetchCowGroupByIdQuery,
  useCreateCowGroupMutation,
  useDeleteCowGroupMutation,
  useUpdateCowGroupMutation,
  useFetchCowGroupActiveQuery,
} = cowGroupApi;
