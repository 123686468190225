import { VStack, Text } from '@gluestack-ui/themed-native-base';
import React from 'react';

import { MilkAmountDashboard } from '../MilkAmountDashboard';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { DetailList } from '~/components/builder/DetailList';
import { DetailListItem } from '~/components/builder/DetailListItem';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  farmId: number;
  groupId: number;
  endDate: string;
}

export const PreviewMilkAmoutDashboard: React.FC<Props> = React.memo(({ farmId, groupId, endDate }) => {
  const group = useGetGroupsByGroupIdQuery({
    groupId,
  });

  if (group.isLoading || group.isError) {
    return null;
  }

  return (
    <VStack
      marginTop={1}
      marginBottom={2}
      space={0}
      padding={2}
      backgroundColor="gray.50"
      borderRadius={6}
      borderWidth={1}
      borderColor="onPrimary"
      overflow="hidden"
    >
      <DetailList space={2}>
        <Text marginBottom={2} color="onPrimary">
          搾乳量ダッシュボード
        </Text>
        <DetailListItem title="グループ" value={group.data?.name || ''} color="onPrimary" />
        <DetailListItem
          title="日付"
          value={`${endDate[0]}${endDate[1]}${endDate[2]}${endDate[3]}/${endDate[4]}${endDate[5]}/${endDate[6]}${endDate[7]}`}
          color="onPrimary"
        />
      </DetailList>
      <MilkAmountDashboard
        isPreview
        farmId={farmId}
        groupId={groupId}
        endDate={endDate ? DateUtil.dateHourToDate(endDate) : new Date()}
        range={30}
        paddingTop={5}
      />
    </VStack>
  );
});
