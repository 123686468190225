import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { HStack, Spinner } from '@gluestack-ui/themed-native-base';
import { createContext, FC, useState, useMemo, useEffect } from 'react';

const COLUMN_WIDTH = 400;

interface SortableItemProps {
  id: string;
  index: number;
  timeout?: number;
}

interface Context {
  index: number | undefined;
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

export const SortableItemContext = createContext<Context>({
  index: undefined,
  attributes: {},
  listeners: undefined,
  ref() {},
});

export const SortableItem: FC<React.PropsWithChildren<SortableItemProps>> = ({ id, index, timeout, children }) => {
  const [isShow, setIsShow] = useState<boolean>(!timeout);
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition } = useSortable({
    id,
  });

  const context = useMemo(
    () => ({
      index,
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [index, attributes, listeners, setActivatorNodeRef]
  );

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (timeout) {
      timeoutId = setTimeout(() => {
        setIsShow(true);
      }, timeout);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  if (!isShow) {
    return (
      <HStack
        width={COLUMN_WIDTH}
        height="100%"
        backgroundColor="surfaceBrightest"
        justifyContent="center"
        alignItems="center"
      >
        <Spinner color="primary" />
      </HStack>
    );
  }

  return (
    <SortableItemContext.Provider value={context}>
      <div
        ref={setNodeRef}
        style={{
          transition,
          transform: CSS.Transform.toString(transform),
        }}
      >
        {children}
      </div>
    </SortableItemContext.Provider>
  );
};
