import { Platform } from 'react-native';

const commonScreens = {
  FarmList: 'farms',
  FarmDetail: 'farms/:id',
  GroupDetail: 'groups/:groupId',
  AdminAccountEdit: 'admin/account/edit',
  AdminFarmList: 'admin/farms',
  AdminFarmDetail: 'admin/farms/:id',
  AdminFarmCreateEdit: 'admin/farms/:id/create_edit',
  AdminUserList: 'admin/users',
  AdminUserDetail: 'admin/users/:id',
  AdminUserEdit: 'admin/users/:id/edit',
  AdminUserCreate: 'admin/users/create',
  AdminGroupUserCreate: 'admin/group_user/create/:groupId',
  AdminGroupUserDetail: 'admin/group_user/groups/:groupId/users/:userId',
  AdminGroupDetail: 'admin/farms/:farmId/groups/:groupId',
  AdminExternalGroupUserInvite: 'admin/external_group_user/invite/groups/:groupId',
  AdminGroupCreateEdit: 'admin/group/create_edit/farms/:farmId/groups/:groupId?',
  SettingsProfile: 'settings/profile',
  SettingsAccount: 'settings/account',
  SettingsNotification: 'settings/notification',
  SettingsKanasapo: 'settings/kanasapo',
  SettingsOther: 'settings/other',
  UserSettings: 'settings',
  UserSettingsEdit: 'settings/edit',
  UserSettingsPasswordEdit: 'settings/password/edit',
  AboutThisApp: 'about',
  Help: 'help',
  PrivacyPolicy: 'privacy-policy',
  TermsOfUse: 'terms-of-use',
  MyAppointmentServiceList: 'provider/appointments/services',
  MyAppointmentServiceDetail: 'provider/appointments/services/:id',
  MyAppointmentServiceCreateEdit: 'provider/appointments/services/:id?/edit',
  MyAppointmentScheduleList: 'provider/appointments/schedules/:date?',
  MyAppointmentList: 'provider/appointments',
  AppointmentServiceList: 'appointments/services',
  AppointmentServiceDetail: 'appointments/services/:id',
  AppointmentList: 'appointments',
  AppointmentScheduleApply: 'appointments/services/:serviceId/apply/:date?',
  GroupUserDetail: 'groups/:groupId/user/:userId',
};

export const linking = {
  config: {
    screens: {
      //Chat: {
      //  screens: {
      //    ChatTabbedChannelList: 'chat/:channelId?/:messageId?',
      //  },
      //},
      //ChatChannelMessages: 'chat/:channelId?/:messageId?',
      DashboardNavigator: {
        screens: {
          Dashboard: 'dashboard',
        },
      },
      BookmarkNavigator: {
        screens: {
          ChatBookmarkList: 'bookmarks',
        },
      },
      ChatBotNavigator: {
        screens: {
          ChatBot: 'concierge',
        },
      },
      DMNavigator: {
        screens: {
          DirectMessageList: 'dm/:channelId?/:messageId?',
          ChatMemberList: 'dm/memberlist/:channelId',
          ChatDMCreateEdit: 'dm/new',
        },
      },
      DrawerNavigator: {
        screens: {
          Home: '',
          TaskCreateEdit: 'farms/:farmId/groups/:groupId/tasks/:id?/create_edit',
          TaskDetail: 'farms/:farmId/groups/:groupId/tasks/:id',
          TaskList: 'farms/:farmId/groups/:groupId/tasks',
        },
      },
      TaskSummaryNavigator: {
        screens: {
          TaskSummary: 'tasks',
          TaskDetail: 'farms/:farmId/groups/:groupId/tasks/:id/mytask',
          TaskCreateEdit: 'farms/:farmId/groups/:groupId/tasks/:id?/create_edit/mytask',
        },
      },
      NotificationsNavigator: {
        screens: {
          Notifications: 'notifications',
        },
      },
      [Platform.OS === 'web' ? 'MenuNavigator' : 'DrawerNavigator']: {
        screens: commonScreens,
      },
      UmotionLogin: 'login/umotion',
      UmotionResetPassword: 'login/reset_password',
    },
  },
};
