import { Text, HStack } from '@gluestack-ui/themed-native-base';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { Platform, TouchableOpacity } from 'react-native';

import { renderHeaderLeft } from './index';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { streami18n } from '~/hooks/useStreamChat';
import { Chat } from '~/lib/StreamChatReact';
import {
  NotificationsScreen,
  ChatChannelMessagesScreen,
  ChatThreadMessagesScreen,
  ChatMemberListScreen,
  ChatChannelOverviewEditScreen,
  MenuScreen,
} from '~/screens';

import { commonModalScreens } from './BottomTabNavigator';
import { HeaderMenuButton } from './HeaderMenuButton';
import { SideNavigation } from './SideNavigation';

const Stack = createNativeStackNavigator();

export const NotificationsNavigator = () => {
  const { chatClient, isUserConnected } = useStreamChatAuthContext();

  if (!chatClient) {
    return null;
  }

  const stackNavigator = (
    <Stack.Navigator
      // @ts-expect-error TS(2322): Type '({ navigation }: { route: RouteProp<ParamLis... Remove this comment to see the full error message
      screenOptions={({ navigation, route }) => ({
        headerBackTitle: '戻る',
        title: '',
        headerTitleStyle: {
          fontWeight: 'bold',
        },
        headerStyle:
          Platform.OS === 'web'
            ? {
                borderBottomWidth: 0,
              }
            : {},
        headerLeft: () => renderHeaderLeft(navigation, '通知'),
        headerRight: () => {
          return route.name !== 'Menu' && <HeaderMenuButton navigation={navigation} />;
        },
      })}
    >
      <Stack.Screen
        name="Notifications"
        component={NotificationsScreen}
        options={{
          title: Platform.OS === 'web' ? '通知 - チャムリー' : '',
          headerShown: Platform.OS !== 'web',
        }}
      />
      <Stack.Screen name="ChatChannelMessages" component={ChatChannelMessagesScreen} />
      <Stack.Screen name="ChatThreadMessages" component={ChatThreadMessagesScreen} />
      <Stack.Screen
        name="ChatMemberList"
        // @ts-expect-error TS(2322): Type 'FC<Props>' is not assignable to type 'Screen... Remove this comment to see the full error message
        component={ChatMemberListScreen}
        options={{
          headerShown: true,
        }}
      />
      <Stack.Screen name="ChatChannelOverviewEdit" component={ChatChannelOverviewEditScreen} />
      <Stack.Screen
        name="Menu"
        component={MenuScreen}
        options={{
          title: 'メニュー',
        }}
      />
      <Stack.Group
        screenOptions={({ navigation }) => ({
          presentation: 'modal',
          headerLeft: () => {
            return (
              <TouchableOpacity onPress={navigation.goBack}>
                <Text color="blue.500" bold fontSize="lg">
                  閉じる
                </Text>
              </TouchableOpacity>
            );
          },
        })}
      >
        {commonModalScreens}
      </Stack.Group>
    </Stack.Navigator>
  );

  return (
    <Chat client={chatClient} i18nInstance={streami18n}>
      {Platform.OS === 'web' ? (
        <HStack>
          <SideNavigation />
          {stackNavigator}
        </HStack>
      ) : (
        <>{stackNavigator}</>
      )}
    </Chat>
  );
};
