import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { U_FEED_URL_BASE } from '@env';
import { Ionicons } from '@expo/vector-icons';
import { Box, VStack, HStack, Text, Button, Icon } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import { StyledProps } from 'native-base';
import { useMemo, memo, useCallback } from 'react';
import { Platform } from 'react-native';

import { GraphAlertBox } from '../GraphAlertBox';

import { useGetGroupsByGroupIdActivitiesStatisticQuery } from '~/api/uFeedApi';
import { Skeleton } from '~/components/Skeleton';
import { useStreamChatAuthContext } from '~/contexts/StreamChatContext';
import { useActivitiesByRange } from '~/hooks/useActivitiesByRange';
import { DateUtil } from '~/utils/DateUtils';

import { DashboardPanel } from './DashboardPanel';
import type { FC } from 'react';

interface Props {
  endDate: Date;
  range: number;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
}

export const ActivityDashboard: FC<Props & StyledProps> = memo(
  ({
    endDate,
    range,
    chatChannelId,
    groupId,
    farmId,
    isPreview = false,
    width = 400,
    height = 400,
    barWidth = 8,
    ...props
  }) => {
    const { setEndDate, setRange, activities, preActivities } = useActivitiesByRange(groupId || 0, endDate, range);
    const activitiesStatistic = useGetGroupsByGroupIdActivitiesStatisticQuery(
      groupId
        ? {
            groupId,
          }
        : skipToken
    );
    const { chatClient } = useStreamChatAuthContext();
    const navigation = useNavigation();
    const linkTo = useLinkTo();

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    const feedTotal = useMemo(
      () => activities.data?.slice(-1).reduce((acc, cur) => acc + (cur.average_feed_min || 0), 0) || 0,
      [activities.data]
    );

    const moveTotal = useMemo(
      () => activities.data?.slice(-1).reduce((acc, cur) => acc + (cur.average_move_min || 0), 0) || 0,
      [activities.data]
    );

    const ruminationStandingTotal = useMemo(
      () => activities.data?.slice(-1).reduce((acc, cur) => acc + (cur.average_rumination_standing_min || 0), 0) || 0,
      [activities.data]
    );

    const ruminationLyingTotal = useMemo(
      () => activities.data?.slice(-1).reduce((acc, cur) => acc + (cur.average_rumination_lying_min || 0), 0) || 0,
      [activities.data]
    );

    const standTotal = useMemo(
      () => activities.data?.slice(-1).reduce((acc, cur) => acc + (cur.average_stand_min || 0), 0) || 0,
      [activities.data]
    );

    const lieTotal = useMemo(
      () => activities.data?.slice(-1).reduce((acc, cur) => acc + (cur.average_lie_min || 0), 0) || 0,
      [activities.data]
    );

    const preFeedMA = useMemo(
      () => (activities.data?.slice(-6, -1).reduce((acc, cur) => acc + (cur.average_feed_min || 0), 0) || 0) / 5,
      [activities.data]
    );

    const preMoveMA = useMemo(
      () => (activities.data?.slice(-6, -1).reduce((acc, cur) => acc + (cur.average_move_min || 0), 0) || 0) / 5,
      [activities.data]
    );

    const preRuminationStandingMA = useMemo(
      () =>
        (activities.data?.slice(-6, -1).reduce((acc, cur) => acc + (cur.average_rumination_standing_min || 0), 0) ||
          0) / 5,
      [activities.data]
    );

    const preRuminationLyingMA = useMemo(
      () =>
        (activities.data?.slice(-6, -1).reduce((acc, cur) => acc + (cur.average_rumination_lying_min || 0), 0) || 0) /
        5,
      [activities.data]
    );

    const preStandMA = useMemo(
      () => (activities.data?.slice(-6, -1).reduce((acc, cur) => acc + (cur.average_stand_min || 0), 0) || 0) / 5,
      [activities.data]
    );

    const preLieMA = useMemo(
      () => (activities.data?.slice(-6, -1).reduce((acc, cur) => acc + (cur.average_lie_min || 0), 0) || 0) / 5,
      [activities.data]
    );

    const total = moveTotal + feedTotal + ruminationStandingTotal + ruminationLyingTotal + standTotal + lieTotal;

    const onPressShare = useCallback(async () => {
      const endDateParams = DateUtil.toYYYYMMDD(endDate).split('/');
      const message = `${U_FEED_URL_BASE}/farms/${farmId}/groups/${groupId}/dashboards/activity/${endDateParams[0]}/${endDateParams[1]}/${endDateParams[2]}?target=all`;

      const channel = chatClient?.channel('team', chatChannelId);
      if (Platform.OS === 'web') {
        linkTo(`/chat/${channel?.id}?message=${encodeURIComponent(message)}`);
      } else {
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        navigation.navigate('ChatChannelMessages', {
          channelId: channel.id,
          message,
        });
      }
    }, [chatChannelId, chatClient, endDate, farmId, groupId, linkTo, navigation]);

    if (activities?.isLoading || preActivities?.isLoading || activitiesStatistic?.isLoading) {
      return <Skeleton height={height} />;
    }

    if (activities?.isError || preActivities?.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            activities.refetch();
            preActivities.refetch();
          }}
        />
      );
    }

    if (!activities?.data || !preActivities?.data) {
      return <Skeleton height={height} />;
    }

    return (
      <>
        <Box paddingX="auto" paddingBottom={4} {...props}>
          <VStack>
            <HStack flex={1} space={{ base: 2, md: 5 }} minW={320} mb="xs" mr="xs">
              <DashboardPanel
                label="採食"
                prevMin={preFeedMA}
                curMin={feedTotal}
                labelColor="#074f07"
                isPreview={isPreview}
              />
              <DashboardPanel
                label="動態"
                prevMin={preMoveMA}
                curMin={moveTotal}
                labelColor="#31a062"
                isPreview={isPreview}
              />
            </HStack>
            <HStack flex={1} space={{ base: 2, md: 5 }} minW={320} mb="xs" mr="xs">
              <DashboardPanel
                label="起立(反芻)"
                prevMin={preRuminationStandingMA}
                curMin={ruminationStandingTotal}
                labelColor="#ff8c00"
                isPreview={isPreview}
              />
              <DashboardPanel
                label="横臥(反芻)"
                prevMin={preRuminationLyingMA}
                curMin={ruminationLyingTotal}
                labelColor="#ffd700"
                labelTextColor="black"
                isPreview={isPreview}
              />
            </HStack>
            <HStack flex={1} space={{ base: 2, md: 5 }} minW={320} mb="xs" mr="xs">
              <DashboardPanel
                label="起立(非活動)"
                prevMin={preStandMA}
                curMin={standTotal}
                labelColor="#dcdcdc"
                labelTextColor="black"
                isPreview={isPreview}
              />
              <DashboardPanel
                label="横臥(非活動)"
                prevMin={preLieMA}
                curMin={lieTotal}
                labelColor="#a9a9a9"
                labelTextColor="black"
                isPreview={isPreview}
              />
            </HStack>

            {!isPreview ? (
              <VStack mt="xs" space={{ base: 1, md: 4 }}>
                <HStack space={1}>
                  <Text bold color="onSurface" mr="xs">
                    合計時間
                  </Text>
                  <Text color="onSurface">{DateUtil.minToHours(total)}</Text>
                  {/* <Hidden till="md">
                      <Box w="px" h={4} bg="black" />
                    </Hidden> */}
                </HStack>
                <HStack space={1}>
                  <Text bold color="onSurface" mr="xs">
                    最終更新
                  </Text>
                  <Text color="onSurface">{activitiesStatistic?.data?.updated_at}</Text>
                </HStack>
                <HStack mt="xs" justifyContent="flex-end">
                  <Button
                    variant="unstyled"
                    onPress={onPressShare}
                    leftIcon={<Icon as={Ionicons} name="share-outline" size="md" color="onSurface" />}
                    testID="activity-dashboard-share-button"
                    _text={{ fontWeight: 'bold', color: 'onSurface' }}
                  >
                    チャットで共有
                  </Button>
                </HStack>
              </VStack>
            ) : null}
          </VStack>
        </Box>
      </>
    );
  }
);
