import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Ionicons } from '@expo/vector-icons';
import { Fab, Icon, VStack } from '@gluestack-ui/themed-native-base';
import { useDrawerStatus } from '@react-navigation/drawer';
import { useRoute, useIsFocused, useNavigation, useLinkTo } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import {
  useGetGroupsByGroupIdTasksQuery,
  useGetCurrentUserQuery,
  useGetGroupsByGroupIdQuery,
  useGetFarmsByIdQuery,
} from '~/api/uFeedApi';
import { SectionTitle, TaskList } from '~/components';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';
import { Skeleton } from '~/components/Skeleton';
import { DateUtil } from '~/utils/DateUtils';

const { useMemo, useState } = React;

export const TaskListScreen: React.FC = () => {
  const route = useRoute();
  const { params } = route;
  const isFocused = useIsFocused();
  const queryParams = params ? { ...params } : {};
  const currentUser = useGetCurrentUserQuery({});
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const tasks = useGetGroupsByGroupIdTasksQuery(queryParams);

  // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
  const group = useGetGroupsByGroupIdQuery({ groupId: queryParams.groupId });
  // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
  const farm = useGetFarmsByIdQuery({ id: queryParams.farmId });
  const [title, setTitle] = useState('タスク');
  const linkTo = useLinkTo();

  useDidUpdate(
    () => {
      if (group.data && farm.data) {
        setTitle(`${farm.data.name}：${group.data.name}`);
      }
    },
    [group, farm],
    true
  );

  const decorated = useMemo(
    () =>
      tasks.data?.map((task) => ({
        ...task,
        deadlineLabel: task.deadline ? DateUtil.toJapaneseYYYYMMDDOrMMDD(new Date(task.deadline)) : '',
      })) || [],
    [tasks.data]
  );
  const orderByDeadline = (a: any, b: any) => {
    if (!a.deadline && !b.deadline) {
      return 0;
    }
    if (a.deadline && !b.deadline) {
      return -1;
    }
    if (!a.deadline && b.deadline) {
      return 1;
    }
    return new Date(a.deadline).valueOf() - new Date(b.deadline).valueOf();
  };

  const ordered = useMemo(
    () => [
      ...decorated.filter((task) => task.status !== 'closed').sort(orderByDeadline),
      ...decorated.filter((task) => task.status === 'closed').sort(orderByDeadline),
    ],
    [decorated]
  );
  const navigation = useNavigation();
  const isDrawerOpen = useDrawerStatus() === 'open';

  if (tasks.isLoading) {
    return (
      <BaseScreenBuilder title="タスク">
        <VStack space={6}>
          {[...new Array(10)].map((item, index) => (
            <Skeleton.Text key={index.toString()} />
          ))}
        </VStack>
      </BaseScreenBuilder>
    );
  }

  return (
    <BaseScreenBuilder flex={1} noScroll>
      <VStack space={4} flex={1}>
        <SectionTitle title={title} maxHeight={8} />
        {/* @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'. */}
        <TaskList tasks={ordered} showAssignedUser groupId={queryParams?.groupId} farmId={queryParams?.farmId} />
        {isFocused && !isDrawerOpen ? (
          <Fab
            onPress={() =>
              Platform.OS === 'web'
                ? // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                  linkTo(`/farms/${queryParams.farmId}/groups/${queryParams.groupId}/tasks/${undefined}/create_edit`)
                : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  navigation.navigate('TaskCreateEdit', {
                    ...queryParams,
                  })
            }
            placement="bottom-right"
            bottom={Platform.OS === 'web' ? 5 : 120}
            right={5}
            size="lg"
            backgroundColor="#0EC9E5"
            icon={<Icon as={Ionicons} name="add-outline" backgroundColor="#0EC9E5" size="md" />}
          />
        ) : null}
      </VStack>
    </BaseScreenBuilder>
  );
};
