import { Ionicons } from '@expo/vector-icons';
import { HStack, Text, VStack, Icon } from '@gluestack-ui/themed-native-base';
import * as React from 'react';

interface PanelProps {
  label: string;
  targetAmount: number;
  amount: number;
  isPreview?: boolean;
}

export const MilkAmountPanel = ({ label, targetAmount, amount, isPreview = false }: PanelProps) => {
  const amountRounded = Math.round(amount);
  const targetAmountRounded = Math.round(targetAmount);
  const diff = amountRounded - targetAmountRounded;
  const diffRounded = Math.round(diff);
  const diffAbs = Math.abs(diff);

  const isMinus = diff < 0;
  const isEqual = Math.round(diffAbs) === 0;

  return (
    <VStack
      p="xs"
      m="xs"
      borderWidth="none"
      //borderColor="black.500"
      borderRadius="md"
      backgroundColor="surface"
      flex={1}
      space={1}
    >
      <HStack alignItems="center">
        <Text bold fontSize={isPreview ? '2xs' : 'sm'} flexGrow={1} color="onSurface">
          {label}
        </Text>

        <HStack alignItems="center">
          {isEqual ? null : isMinus ? (
            <Icon as={Ionicons} name="remove-outline" size={isPreview ? '2xs' : 'sm'} color="onSurface" />
          ) : (
            <Icon as={Ionicons} name="add-outline" size={isPreview ? '2xs' : 'sm'} color="onSurface" />
          )}

          <Text bold fontSize={isPreview ? '2xs' : 'lg'} color="onSurface">
            {Math.round((Math.abs(diffRounded) / targetAmountRounded) * 100)}%
          </Text>
        </HStack>
      </HStack>

      <VStack>
        <HStack flex={1}>
          <Text fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1} color="onSurface">
            5日間平均
          </Text>
          <Text fontSize={isPreview ? '3xs' : 'xs'} color="onSurface">
            {Math.round(targetAmount)} kg/頭
          </Text>
        </HStack>
        <HStack>
          <Text fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1} color="onSurface">
            昨日
          </Text>
          <Text fontSize={isPreview ? '3xs' : 'xs'} color="onSurface">
            {Math.round(amount)} kg/頭
          </Text>
        </HStack>
        <HStack flex={1} alignItems="center">
          <Text fontSize={isPreview ? '3xs' : 'xs'} flexGrow={1} color="onSurface">
            差分
          </Text>
          <HStack alignItems="center">
            {isEqual ? null : isMinus ? (
              <Icon as={Ionicons} name="remove-outline" size={isPreview ? '2xs' : 'xs'} color="onSurface" />
            ) : (
              <Icon as={Ionicons} name="add-outline" size={isPreview ? '2xs' : 'xs'} color="onSurface" />
            )}
            <Text fontSize={isPreview ? '3xs' : 'xs'} color="onSurface">
              {Math.round(diffAbs)} kg
            </Text>
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};
