import { Center, HStack, Pressable, Text } from '@gluestack-ui/themed-native-base';
import clsx from 'clsx';
import React, { useRef, useState } from 'react';

import { ChannelPreviewUIComponentProps, DefaultStreamChatGenerics } from 'stream-chat-react';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { usePinnedChannels } from '~/hooks';
import PinIcons from '~/icons/PinIcons';

import { DateUtil } from '~/utils/DateUtils';

const UnMemoizedChannelPreviewMessenger = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics,
>(
  props: ChannelPreviewUIComponentProps<StreamChatGenerics> & { isPinned?: boolean; isDM: boolean }
) => {
  const {
    active,
    Avatar,
    channel,
    className: customClassName = '',
    displayImage,
    displayTitle,
    latestMessage,
    onSelect: customOnSelectChannel,
    setActiveChannel,
    unread,
    watchers,
    isPinned = false,
    isDM,
  } = props;
  const [isHovered, setIsHovered] = useState(false);
  const { pinnedChannels, addPinnedChannel, removePinnedChannel } = usePinnedChannels();

  const channelPreviewButton = useRef<HTMLButtonElement | null>(null);

  const avatarName = displayTitle || channel.state.messages[channel.state.messages.length - 1]?.user?.id;

  const onSelectChannel = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (customOnSelectChannel) {
      customOnSelectChannel(e);
    } else if (setActiveChannel) {
      setActiveChannel(channel, watchers);
    }
    if (channelPreviewButton?.current) {
      channelPreviewButton.current.blur();
    }
  };

  const onPinChannel = () => {
    const pinned = pinnedChannels.includes(props.channel.cid);
    if (!pinned) {
      addPinnedChannel(props.channel.cid);
    } else {
      removePinnedChannel(props.channel.cid);
    }
  };

  const lastMessageCreatedAt = props.lastMessage?.created_at ? new Date(props.lastMessage?.created_at) : undefined;
  const isToday = lastMessageCreatedAt ? DateUtil.isToday(lastMessageCreatedAt) : false;
  const isCurrentYear = lastMessageCreatedAt ? DateUtil.isCurrentYear(lastMessageCreatedAt) : false;

  const lastmassageCreateAtString = lastMessageCreatedAt
    ? isToday
      ? DateUtil.tohmm(lastMessageCreatedAt)
      : isCurrentYear
        ? DateUtil.toMMDD2(lastMessageCreatedAt)
        : DateUtil.toYYYYMMDD(lastMessageCreatedAt)
    : '';

  return (
    <HStack
      style={{ position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <button
        aria-label={`Select Channel: ${displayTitle || ''}`}
        aria-selected={active}
        className={clsx(
          `str-chat__channel-preview-messenger str-chat__channel-preview`,
          active && 'str-chat__channel-preview-messenger--active',
          unread && unread >= 1 && 'str-chat__channel-preview-messenger--unread',
          customClassName
        )}
        data-testid="channel-preview-button"
        onClick={onSelectChannel}
        ref={channelPreviewButton}
        role="option"
      >
        <div className="str-chat__channel-preview-messenger--left" style={{ alignSelf: 'flex-start', padding: 0 }}>
          {Avatar && <Avatar image={displayImage} name={avatarName} size={40} />}
        </div>
        <div className="str-chat__channel-preview-messenger--right str-chat__channel-preview-end">
          <div className="str-chat__channel-preview-end-first-row">
            <div className="str-chat__channel-preview-messenger--name">
              <HStack gap={1} alignItems="center">
                {isPinned && (
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 10 16" fill="none">
                    <path
                      d="M8.33333 2.16667V8L9.75 9.41667C9.83333 9.5 9.89583 9.59375 9.9375 9.69792C9.97917 9.80208 10 9.90972 10 10.0208V10.5C10 10.7361 9.92014 10.934 9.76042 11.0938C9.60069 11.2535 9.40278 11.3333 9.16667 11.3333H5.83333V16.2083C5.83333 16.4444 5.75347 16.6424 5.59375 16.8021C5.43403 16.9618 5.23611 17.0417 5 17.0417C4.76389 17.0417 4.56597 16.9618 4.40625 16.8021C4.24653 16.6424 4.16667 16.4444 4.16667 16.2083V11.3333H0.833333C0.597222 11.3333 0.399306 11.2535 0.239583 11.0938C0.0798611 10.934 0 10.7361 0 10.5V10.0208C0 9.90972 0.0208333 9.80208 0.0625 9.69792C0.104167 9.59375 0.166667 9.5 0.25 9.41667L1.66667 8V2.16667C1.43056 2.16667 1.23264 2.08681 1.07292 1.92708C0.913194 1.76736 0.833333 1.56944 0.833333 1.33333C0.833333 1.09722 0.913194 0.899306 1.07292 0.739583C1.23264 0.579861 1.43056 0.5 1.66667 0.5H8.33333C8.56944 0.5 8.76736 0.579861 8.92708 0.739583C9.08681 0.899306 9.16667 1.09722 9.16667 1.33333C9.16667 1.56944 9.08681 1.76736 8.92708 1.92708C8.76736 2.08681 8.56944 2.16667 8.33333 2.16667Z"
                      fill={gluestackUIConfig.tokens.colors.orange500}
                    />
                  </svg>
                )}
                <Text color="inverseSurface" fontWeight="bold" fontSize="md" numberOfLines={isDM ? 3 : 1}>
                  {displayTitle}
                </Text>
              </HStack>
            </div>
            <Text
              style={{ textAlign: 'right', whiteSpace: 'nowrap' }}
              fontSize="sm"
              fontWeight="400"
              textAlign="right"
              color="onSurfaceBright"
            >
              {lastmassageCreateAtString}
            </Text>
          </div>
          <div className="str-chat__channel-preview-messenger--last-message_container">
            <HStack justifyContent="space-between" alignItems="center" width="full">
              <Text color="onSurfaceBright" numberOfLines={2} lineHeight="xs">
                {latestMessage}
              </Text>
              {!!unread && (
                <Center
                  ml="2xs"
                  height="5"
                  width="5"
                  borderRadius={gluestackUIConfig.tokens.radii.full}
                  backgroundColor="primary"
                >
                  <Text color="onPrimary" fontWeight="bold">
                    {unread}
                  </Text>
                </Center>
              )}
            </HStack>
          </div>
        </div>
      </button>
      {isHovered && (
        <Pressable
          style={{
            position: 'absolute',
            top: -24,
            right: 0,
            zIndex: 200,
          }}
          p="2xs"
          borderWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderRadius={gluestackUIConfig.tokens.borderRadius.medium}
          borderColor="outline"
          backgroundColor="surfaceBrightest"
          onPress={onPinChannel}
        >
          <PinIcons />
        </Pressable>
      )}
    </HStack>
  );
};

/**
 * Used as preview component for channel item in [ChannelList](#channellist) component.
 * Its best suited for messenger type chat.
 */
export const ChannelPreviewMessenger = React.memo(
  UnMemoizedChannelPreviewMessenger
) as typeof UnMemoizedChannelPreviewMessenger;
