import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Box, HStack, Text, VStack, Switch } from '@gluestack-ui/themed-native-base';
import { StyledProps } from 'native-base';
import * as React from 'react';
import { useState } from 'react';
import { TableChart, MilkAmountUpDownChart, GraphAlertBox } from '~/components/';
import { Skeleton } from '~/components/Skeleton';

import { useMilkAmountByRange } from '~/hooks/useMilkAmountByRange';
import { VictoryLine, VictoryChart, VictoryTheme, VictoryAxis, VictoryScatter, VictoryLegend } from '~/lib/Victory';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  endDate: Date;
  range: number;
  showTable: boolean;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  queryParameters?: {
    endDate: string;
    range: number;
    mode: 'table' | 'graph';
  };
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  onTypeChange?: (type: string) => void;
  shareButton?: React.ReactElement;
}

export const MilkAmountChart: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    showTable,
    chatChannelId,
    groupId,
    farmId,
    queryParameters,
    isPreview = false,
    width = 420,
    height = 350,
    barWidth = 8,
    onTypeChange,
    shareButton,
  }) => {
    // @ts-expect-error
    const { setEndDate, setRange, milkings, preMilkings, milkingsMA } = useMilkAmountByRange(groupId, endDate, range);
    const [shouldShowPrev, setShouldShowPrev] = useState(false);

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    useDidUpdate(() => {
      queryParameters?.endDate && setEndDate(DateUtil.dateHourToDate(queryParameters?.endDate));
      queryParameters?.range && setRange(queryParameters?.range);
    }, [queryParameters]);

    useDidMount(() => {
      queryParameters?.endDate && setEndDate(DateUtil.dateHourToDate(queryParameters?.endDate));
      queryParameters?.range && setRange(queryParameters?.range);
    });

    const Chart = React.useMemo(
      () => (
        <VictoryChart
          width={isPreview ? 220 : width}
          height={isPreview ? 182 : height}
          theme={VictoryTheme.material}
          domainPadding={{ x: [10, 10], y: [0, 0] }}
          padding={isPreview ? { top: 20, bottom: 40, left: 40, right: 20 } : undefined}
        >
          <VictoryAxis
            fixLabelOverlap
            tickFormat={(x, i) => {
              const date = new Date(x);
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }}
          />
          <VictoryAxis dependentAxis tickFormat={(y) => y} />
          {isPreview ? null : (
            <VictoryLegend
              orientation="horizontal"
              x={52}
              gutter={60}
              data={[
                { name: '日毎の搾乳量', symbol: { fill: '#aaa' } },
                { name: '5日間移動平均', symbol: { fill: '#aaa', type: 'minus' } },
              ]}
            />
          )}
          <VictoryScatter
            data={
              milkings.data?.map((cur) => {
                return {
                  x: cur.date,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  y: cur.amount || 0,
                };
              }) ?? []
            }
            style={{
              data: { fill: '#70A1D7', opacity: 1 },
            }}
            size={isPreview ? 2 : 3}
          />
          <VictoryLine
            data={
              milkingsMA.data?.map((cur) => {
                return {
                  x: cur.date,
                  // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                  y: cur.amount || 0,
                };
              }) ?? []
            }
            style={{
              data: { stroke: 'blue', strokeWidth: isPreview ? 2 : 4 },
            }}
          />
          {shouldShowPrev ? (
            <VictoryLine
              data={
                preMilkings?.data?.map((cur) => {
                  const utcDate = DateUtil.dateHourToDate(cur.date, 'yyyy-MM-dd');

                  return {
                    x: DateUtil.toYYYYMMDD(DateUtil.addDays(utcDate, 30), '-'),
                    // @ts-expect-error TS(2339): Property 'amount' does not exist on type 'Additive... Remove this comment to see the full error message
                    y: cur.amount || 0,
                  };
                }) ?? []
              }
              style={{
                data: { stroke: 'blue', strokeOpacity: 0.5, strokeDasharray: 2 },
              }}
            />
          ) : (
            false
          )}
        </VictoryChart>
      ),
      [milkings, milkingsMA, preMilkings, shouldShowPrev]
    );

    if (milkings?.isLoading) {
      return <Skeleton height={isPreview ? 216 : height} />;
    }

    if (milkings.isError || preMilkings.isError || milkingsMA.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            milkings.refetch();
            preMilkings.refetch();
            milkingsMA.refetch();
          }}
        />
      );
    }

    if (isPreview) {
      return <VStack>{Chart}</VStack>;
    }

    return (
      <>
        <Box paddingX="auto">{Chart}</Box>
        {false && (
          <HStack alignItems="center" justifyContent="flex-end" space={2} paddingX={4}>
            <Text>前の期間と比較</Text>
            {/* @ts-expect-error */}
            <Switch value={shouldShowPrev} onToggle={(newValue) => setShouldShowPrev(newValue)} size="sm" />
          </HStack>
        )}
        <MilkAmountUpDownChart
          chatChannelId={chatChannelId}
          farmId={farmId}
          groupId={groupId}
          endDate={endDate}
          range={range}
        />
        <TableChart
          // @ts-expect-error TS(2532): Object is possibly 'undefined'.
          data={milkings.data.map((item) => ({
            ...item,
            date_label: DateUtil.toMMDD(item.date),
          }))}
          headers={['日付', '搾乳量']}
          columns={['date_label', 'amount']}
          padding={4}
          showTable={showTable}
          widthArr={[100, 100]}
          width={width}
        />
        {shareButton ? shareButton : null}
      </>
    );
  }
);
