import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';

import { Skeleton } from '@gluestack-ui/themed-native-base';
import { useRoute, useNavigation } from '@react-navigation/native';
import * as React from 'react';
import { useState } from 'react';

import { useGetUsersByIdQuery, usePutUsersByIdMutation } from '~/api/uFeedApi';
import { BaseFormBuilder, errorMessageBuilder } from '~/components/builder/BaseFormBuilder';
import { BaseScreenBuilder } from '~/components/builder/BaseScreenBuilder';

export const AdminUserEditScreen: React.FC = () => {
  const { params } = useRoute();
  const navigation = useNavigation();
  const queryParams = params ? { ...params } : {};
  // @ts-expect-error TS(2345): Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
  const queryResult = useGetUsersByIdQuery(queryParams);
  const [update, { isLoading: isUpdating, data }] = usePutUsersByIdMutation();

  const [errorMessages, setErrorMessages] = useState<string[]>([]);

  // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
  const id = queryParams.id;

  useDidUpdate(() => {
    if (queryResult.data?.name) {
      navigation?.setOptions({
        title: queryResult.data?.name,
      });
    }
  }, [queryResult.data?.name]);

  const onCancel = () => {
    navigation.goBack();
  };

  const onSubmit = (sendingData: any) => {
    setErrorMessages((errorMessages) => []);
    const { name, email, roles } = sendingData;

    update({
      id,
      body: {
        user: {
          name,
          email,
          roles: roles.includes('member') ? roles : [...roles, 'member'],
        },
      },
    })
      .unwrap()
      .then((resp) => {
        navigation.goBack();
      })
      .catch((error) => {
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      });
  };

  const fields = [
    {
      key: 'name',
      label: '名前',
      rules: {
        required: {
          value: true,
          message: '名前を入力してください',
        },
      },
    },
    {
      key: 'email',
      label: 'メールアドレス',
      rules: {
        required: {
          value: true,
          message: 'メールアドレスを入力してください',
        },
        pattern: {
          value:
            /^([\w!#$%&'*+\-\/=?^`{|}~]+(\.[\w!#$%&'*+\-\/=?^`{|}~]+)*|"([\w!#$%&'*+\-\/=?^`{|}~. ()<>\[\]:;@,]|\\[\\"])+")@(([a-zA-Z\d\-]+\.)+[a-zA-Z]+|\[(\d{1,3}(\.\d{1,3}){3}|IPv6:[\da-fA-F]{0,4}(:[\da-fA-F]{0,4}){1,5}(:\d{1,3}(\.\d{1,3}){3}|(:[\da-fA-F]{0,4}){0,2}))\])$/,
          message: 'メールアドレスの形式に誤りがあります',
        },
      },
    },
    {
      key: 'roles',
      label: 'システム権限',
      type: 'sectionedMultiselect',
      exclusionDisplay: ['member'],
      options: roleMatser,
    },
  ];

  if (queryResult.isLoading) {
    return <Skeleton.Text />;
  }

  return (
    <BaseScreenBuilder>
      <BaseFormBuilder
        // @ts-expect-error TS(2322): Type '({ error?: undefined; data?: undefined; fulf... Remove this comment to see the full error message
        queryResult={queryResult}
        onSubmit={onSubmit}
        onCancel={onCancel}
        updateResult={{ isUpdating, data }}
        emptyMessage="ユーザーがありません"
        errorMessage={errorMessages}
        // @ts-expect-error TS(2322): Type '({ key: string; label: string; rules: { requ... Remove this comment to see the full error message
        fields={fields}
      />
    </BaseScreenBuilder>
  );
};

const roleMatser = [
  { id: 'account_admin', name: '企業管理者' },
  { id: 'group_admin', name: 'グループ管理者' },
  { id: 'member_admin', name: 'メンバー管理者' },
];
