import { Ionicons } from '@expo/vector-icons';
import { HStack, VStack, Text, Icon, Pressable, Image } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation, useRoute } from '@react-navigation/native';
import * as React from 'react';
import { Platform } from 'react-native';

import { Task } from '~/api/uFeedApi';
import { useAvatars } from '~/hooks';

interface Props {
  item: Task;
  groupId: number;
  farmId: number;
  showAssignedUser?: boolean;
  showGroup?: boolean;
}

export const TaskListItem: React.FC<Props> = React.memo(
  ({ item, showAssignedUser = false, showGroup = true, groupId, farmId }) => {
    const navigation = useNavigation();
    const route = useRoute();
    const subColor = 'gray.500';
    const linkTo = useLinkTo();

    const { avatarsByUserId } = useAvatars();

    return (
      <Pressable
        onPress={() => {
          Platform.OS === 'web'
            ? route.name === 'TaskSummary'
              ? // @ts-expect-error TS(2339): Property 'group' does not exist on type 'Task'.
                linkTo(`/farms/${farmId}/groups/${groupId || item?.group?.id}/tasks/${item?.id}/mytask`)
              : // @ts-expect-error TS(2339): Property 'group' does not exist on type 'Task'.
                linkTo(`/farms/${farmId}/groups/${groupId || item?.group?.id}/tasks/${item?.id}`)
            : // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
              navigation.navigate('TaskDetail', {
                farmId,
                // @ts-expect-error TS(2339): Property 'group' does not exist on type 'Task'.
                groupId: groupId || item?.group?.id,
                id: item?.id,
              });
        }}
        px={{ base: 0, md: 4 }}
        py={3}
        _hover={{
          backgroundColor: 'gray.50',
        }}
      >
        <HStack justifyContent="space-between" space={2}>
          <HStack space={2} flexShrink={1}>
            <VStack alignContent="center">
              <HStack alignContent="center" paddingTop={1}>
                <Icon
                  as={Ionicons}
                  name={item.status === 'closed' ? 'checkmark-circle' : 'ellipse-outline'}
                  alignSelf="center"
                  size={5}
                  color={item.status === 'closed' ? '#005FFF' : null}
                />
              </HStack>
            </VStack>
            <VStack alignContent="center" space={1} flexShrink={1}>
              <HStack alignContent="center">
                {item.status === 'closed' ? (
                  <Text textDecorationLine="line-through" textDecorationStyle="solid" bold>
                    {item.content}
                  </Text>
                ) : (
                  <Text bold>{item.content}</Text>
                )}
              </HStack>
              <HStack space={{ base: 2, md: 4 }} borderWidth={0}>
                {item.assigned_user && showAssignedUser !== false ? (
                  <HStack alignItems="center" space={1} flexShrink={1}>
                    {avatarsByUserId[Number(item.assigned_user.id)] ? (
                      <Image
                        source={{ uri: avatarsByUserId[Number(item.assigned_user.id)].avatar }}
                        size="2xs"
                        borderRadius="full"
                        alt="user avater"
                      />
                    ) : (
                      <Icon as={Ionicons} name="person-circle-outline" color={subColor} />
                    )}
                    <Text numberOfLines={1} color={subColor}>
                      {item.assigned_user.name}
                    </Text>
                  </HStack>
                ) : null}
                {item.deadline ? (
                  <HStack alignItems="center" space={1}>
                    <Icon as={Ionicons} name="calendar-outline" color={subColor} />
                    {/* @ts-expect-error TS(2339): Property 'deadlineLabel' does not exist on type 'T... Remove this comment to see the full error message */}
                    <Text color={subColor}>{item.deadlineLabel}</Text>
                  </HStack>
                ) : null}
              </HStack>
            </VStack>
          </HStack>
          <HStack alignContent="center" paddingTop={1}>
            <Icon as={Ionicons} name="chevron-forward-outline" alignSelf="center" size={6} />
          </HStack>
        </HStack>
      </Pressable>
    );
  }
);
