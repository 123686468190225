import { format, parse, parseISO, subDays, subMinutes, addDays, isToday } from 'date-fns';
import ja from 'date-fns/locale/ja';

export class DateUtil {
  /**
   * MM/DDにフォーマットする
   *
   * @param {string} value 元値
   * @param {string} separator セパレータ
   * @param {string} formatStr 元値のフォーマット
   * @return {String} MM/DD
   */
  static toMMDD = (value: any, separator = '/', formatStr = 'yyyy-MM-dd') => {
    if (!value) return '';
    const date = DateUtil.dateHourToDate(value, formatStr);
    return format(date, `MM${separator}dd`);
  };

  /**
   * YYYY/MM/DDにフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY/MM/DD
   */
  static toYYYYMMDD = (value: any, separator = '/') => {
    if (!value) return '';

    if (Number.isNaN(value.getTime())) {
      console.error('date parsing error,', value);
      return '';
    }
    return format(value, `yyyy${separator}MM${separator}dd`);
  };

  /**
   * YYYY年MM月DD日にフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY年MM月DD日
   */
  static toJapaneseYYYYMMDD = (value: any, separator?: string) => {
    if (!value) return '';
    if (separator) {
      return format(value, `yyyy${separator}M${separator}dd`);
    } else {
      return format(value, `yyyy年M月d日`);
    }
  };

  /**
   * MM月DD日にフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY年MM月DD日
   */
  static toJapaneseMMDD = (value: any, separator?: string) => {
    if (!value) return '';
    if (separator) {
      return format(value, `M${separator}d`);
    } else {
      return format(value, `M月d日`);
    }
  };

  static toMMDD2 = (value: any, separator?: string) => {
    if (!value) return '';
    if (separator) {
      return format(value, `M${separator}d`);
    } else {
      return format(value, `M/d`);
    }
  };

  /**
   * MM月DD日にフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY年MM月DD日
   */
  static toJapaneseYYYYMMDDOrMMDD = (value: any, separator?: string) => {
    if (!value) return '';
    try {
      const date = new Date(value);
      const current = new Date();

      if (date.getFullYear() !== current.getFullYear()) {
        return this.toYYYYMMDD(value, separator);
      } else {
        return this.toJapaneseMMDD(value, separator);
      }
    } catch (error) {
      console.error('date parsing error,', value);
      return '';
    }
  };

  static toYYYYMMDDOrMMDD = (value: any, separator?: string) => {
    if (!value) return '';
    try {
      const date = new Date(value);
      const current = new Date();

      if (date.getFullYear() !== current.getFullYear()) {
        return this.toYYYYMMDD(value, separator);
      } else {
        return this.toMMDD2(value, separator);
      }
    } catch (error) {
      console.error('date parsing error,', value);
      return '';
    }
  };

  /**
   * YYYYMMDDをDateに変換する
   */
  static dateHourToDate = (dateStr: any, formatStr = 'yyyyMMdd') => {
    const utcDate = parse(dateStr, formatStr, new Date());
    return subMinutes(utcDate, utcDate.getTimezoneOffset());
  };

  static subDays = (utcDate: any, days: any) => {
    return subDays(utcDate, days);
  };

  static addDays = (utcDate: any, days: any) => {
    return addDays(utcDate, days);
  };

  static minToHours = (min: number): string => {
    const minFixed = min < 0 ? -min : min;
    const hours = Math.floor(minFixed / 60);

    return `${hours > 0 ? hours + '時間' : ''} ${Math.round(minFixed % 60)}分`;
  };

  /**
   * h:mmにフォーマットする
   *
   * @param {mixed} value 元値
   * @return {String} YYYY/MM/DD
   */
  static tohmm = (value: any) => {
    if (!value) return '';
    return format(value, `H:mm`);
  };

  static parseISODate = (dateStr: string) => {
    parseISO(dateStr);
  };

  static utcToJst = (utcDate: Date) => {
    return subMinutes(utcDate, utcDate.getTimezoneOffset());
  };

  static toDayOfWeek = (date: Date) => {
    return format(date, `E`, { locale: ja });
  };

  /**
   * dateが今日か判定する
   *
   * @param {mixed} date 元値
   * @return {boolean}
   */
  static isToday = (date: Date): boolean => {
    return isToday(date);
  };

  static isCurrentYear = (date: Date): boolean => {
    return date.getFullYear() === new Date().getFullYear();
  };
}
