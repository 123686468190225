import { Feather } from '@expo/vector-icons';
import { HStack, Text, Alert, Button, Checkbox, Divider } from '@gluestack-ui/themed-native-base';
import { StyledProps } from 'native-base';
import * as React from 'react';

import { useGetGroupsByGroupIdTasksQuery } from '~/api/uFeedApi';
import { TaskList, Section } from '~/components';
import { Skeleton } from '~/components/Skeleton';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  farmId: number;
  groupId: number;
  max?: number;
  onAddPress: () => void;
}

const MAX_NUMBER_OF_TASKS = 5;

export const TaskCard: React.FC<Props & StyledProps> = React.memo(
  ({ farmId, groupId, onAddPress, max = MAX_NUMBER_OF_TASKS, ...remainingProps }) => {
    const [showOnlyOpened, toggleShowOnlyOpened] = React.useReducer((val) => !val, false);
    const tasks = useGetGroupsByGroupIdTasksQuery({
      groupId,
    });

    const orderByDeadline = (a: any, b: any) => {
      if (!a.deadline && !b.deadline) {
        return 0;
      }
      if (a.deadline && !b.deadline) {
        return -1;
      }
      if (!a.deadline && b.deadline) {
        return 1;
      }
      return new Date(a.deadline).valueOf() - new Date(b.deadline).valueOf();
    };

    const decoratedTask = React.useMemo(() => {
      return [...(tasks.data || [])]
        .sort(orderByDeadline)
        .slice(0, max)
        .map((task) => ({
          ...task,
          // @ts-expect-error TS(2769): No overload matches this call.
          deadlineLabel: DateUtil.toJapaneseYYYYMMDDOrMMDD(new Date(task.deadline)),
        }));
    }, [tasks.data, orderByDeadline, max]);

    const openedDecoratedTask = React.useMemo(() => {
      return decoratedTask.filter((task) => task?.status !== 'closed') || [];
    }, [decoratedTask]);

    if (tasks?.isLoading) {
      return (
        // @ts-expect-error
        <Section {...remainingProps}>
          <Skeleton.Text height="12" borderRadius={10} />
        </Section>
      );
    }

    return (
      // @ts-expect-error
      <Section {...remainingProps}>
        {tasks?.isSuccess ? (
          <>
            {tasks?.data.length > 0 ? (
              <>
                <HStack mb={3}>
                  <Button
                    variant="unstyled"
                    onPress={toggleShowOnlyOpened}
                    px={0}
                    leftIcon={
                      <Checkbox
                        value=""
                        isChecked={showOnlyOpened}
                        colorScheme="blue"
                        size="sm"
                        aria-label="checkbox_showOnlyOpened"
                      />
                    }
                  >
                    未完了のみを表示
                  </Button>
                </HStack>
                <Divider />
                <TaskList
                  tasks={showOnlyOpened ? openedDecoratedTask : decoratedTask}
                  groupId={groupId}
                  farmId={farmId}
                  showAssignedUser
                  scroll={false}
                />
              </>
            ) : (
              <Text>タスクがありません</Text>
            )}
          </>
        ) : null}
        {tasks?.isError ? (
          <Alert status="error">
            {/* @ts-expect-error TS(2339): Property 'error' does not exist on type 'FetchBase... Remove this comment to see the full error message */}
            <Text>エラーが発生しました: {tasks?.error?.error}</Text>
          </Alert>
        ) : null}
        <HStack justifyContent="flex-end" mt={6}>
          <Button
            px={6}
            variant="solid"
            backgroundColor="#005FFF"
            onPress={onAddPress}
            rounded="full"
            leftIcon={<Feather name="plus" color="white" size={16} />}
          >
            タスクを追加
          </Button>
        </HStack>
      </Section>
    );
  }
);
