import { Ionicons } from '@expo/vector-icons';
import {
  Button,
  Icon,
  HStack,
  VStack,
  Input,
  Pressable,
  Text,
  Image,
  Box,
  useToken,
} from '@gluestack-ui/themed-native-base';
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useState, useMemo, useCallback } from 'react';
import { Platform } from 'react-native';
import { BaseScreenBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { useYomi } from '~/hooks/useYomi';

type Option = {
  id: string;
  avatar: string;
  name: string;
  yomi: string;
};

type ParamList = {
  ChatChannelSelectMembers: {
    options: Option[];
    selectedOptions: string[];
    onSubmit: (idList: string[]) => void;
    isDM?: boolean;
    chatUserId?: string;
  };
};

export const ChatChannelSelectMembersScreen: React.FC = () => {
  const navigation = useNavigation();
  const { searchYomi } = useYomi();
  const { params } = useRoute<RouteProp<ParamList, 'ChatChannelSelectMembers'>>();
  const [onSurfaceBright] = useToken('color', ['onSurfaceBright']);

  const [query, setQuery] = useState<string>('');
  const [selectedOptionList, setSelectedOptionList] = useState<string[]>(params.selectedOptions || []);

  const filteredOptions = useMemo(() => {
    if (!query) return params.options;

    const matchedYomi = searchYomi(query);
    return params.options.filter(
      (opt) => typeof opt.id !== 'undefined' && matchedYomi.find((m) => m.chatUserId === opt.id)
    );
  }, [params.options, query]);

  const isAllSelected = useMemo(() => {
    return filteredOptions.every((opt) => selectedOptionList.includes(opt.id));
  }, [params.options, selectedOptionList]);

  const onSelect = useCallback(
    (id: string) => {
      setSelectedOptionList((prev) => {
        if (prev.includes(id)) {
          return params.isDM && id === params.chatUserId ? prev : prev.filter((i) => i !== id);
        }
        return [...prev, id];
      });
    },
    [params.isDM, params.chatUserId]
  );

  const onSelectAll = useCallback(() => {
    setSelectedOptionList(filteredOptions.map((opt) => opt.id));
  }, [params.options]);

  const onRemoveAll = useCallback(() => {
    params.isDM
      ? setSelectedOptionList((prev) =>
          prev.filter((i) => !filteredOptions.find((opt) => opt.id === i) || i === params.chatUserId)
        )
      : setSelectedOptionList((prev) => prev.filter((i) => !filteredOptions.find((opt) => opt.id === i)));
  }, []);

  const onSubmit = useCallback(() => {
    params.onSubmit(selectedOptionList);
    navigation.goBack();
  }, [params.onSubmit, selectedOptionList]);

  return (
    <ScreenWidthModal title="メンバーを選択">
      <Box position="relative" minHeight="100%" maxHeight="100%">
        <BaseScreenBuilder title="メンバーを選択">
          <Input
            value={query}
            onChangeText={setQuery}
            placeholder="検索"
            placeholderTextColor="onSurfaceBright"
            variant="rounded"
            color="onSurface"
            // FIXME placeholderTextColor="onSurfaceBright"
            py="sm"
            px="2xs"
            mb="md"
            fontSize="md"
            size="md"
            borderWidth="none"
            backgroundColor="surfaceBright"
            InputLeftElement={
              <VStack backgroundColor="surfaceBright">
                <Icon
                  m="xs"
                  ml="sm"
                  height="xl"
                  width="xl"
                  fontSize="2xl"
                  color="onSurfaceBright"
                  backgroundColor="surfaceBright"
                  as={Ionicons}
                  name="search-outline"
                />
              </VStack>
            }
          />
          <Pressable onPress={isAllSelected ? onRemoveAll : onSelectAll}>
            <HStack justifyContent="flex-end" py="xs">
              <Text fontSize="sm" fontWeight="bold" color="primary">
                {isAllSelected ? 'すべて解除' : 'すべて選択'}
              </Text>
            </HStack>
          </Pressable>
          {filteredOptions.map((item) => (
            <Pressable key={`option-${item.id}`} onPress={() => onSelect(item.id)} width="100%">
              <HStack py="xs" alignItems="center" justifyContent="space-between">
                <HStack alignItems="center" justifyContent="flex-start">
                  {item.avatar ? (
                    <Image source={{ uri: item.avatar }} alt={item.name} height="2xl" width="2xl" borderRadius="full" />
                  ) : (
                    <Box height="2xl" width="2xl" borderRadius="full" backgroundColor="onSurfaceBright" />
                  )}
                  <Text color="onSurface" ml="xs" fontSize="md" fontWeight="bold" numberOfLines={1}>
                    {item.name}
                  </Text>
                  <Text color="onSurfaceBright" ml="2xs" fontSize="sm">
                    {item.yomi}
                  </Text>
                </HStack>
                {selectedOptionList.includes(item.id) && (
                  <Ionicons name="checkmark-outline" size={24} color={onSurfaceBright} />
                )}
              </HStack>
            </Pressable>
          ))}
          <Box height="lg" />
        </BaseScreenBuilder>
        <HStack
          position="absolute"
          right={Platform.OS === 'web' ? -24 : 0}
          bottom={0}
          left={Platform.OS === 'web' ? -24 : 0}
          py="sm"
          px="md"
          backgroundColor="surfaceBrightest"
          shadowColor="inverseSurface"
          style={{
            shadowOffset: {
              width: 0,
              height: 8,
            },
            shadowOpacity: 0.1,
            shadowRadius: 40,
            elevation: 1,
          }}
          gap="sm"
          borderRadius={10}
          safeAreaBottom
        >
          <Button
            flex={1}
            onPress={navigation.goBack}
            variant="outline"
            rounded="md"
            borderColor="outline"
            _text={{
              color: 'primary',
              fontWeight: 'bold',
            }}
          >
            キャンセル
          </Button>
          <Button
            flex={1}
            onPress={onSubmit}
            rounded="md"
            backgroundColor="primary"
            _text={{
              color: 'onPrimary',
              fontWeight: 'bold',
            }}
          >
            保存
          </Button>
        </HStack>
      </Box>
    </ScreenWidthModal>
  );
};
