import { HStack, VStack, Text, Pressable } from '@gluestack-ui/themed-native-base';
import { useCallback, useContext } from 'react';
import { NotificationList } from '../NotificationsScreen/NotificationList';
import { SortableItemContext } from '~/components/SortableItem';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useDashboardColumns } from '~/hooks/useDashboardColumns';
import CloseIcons from '~/icons/CloseIcons';
import DragIcons from '~/icons/DragIcons';

type Props = {
  width: number;
  showHeader: boolean;
};

export const NotificationColumn = ({ width: columnWidth = 400, showHeader = true }: Props) => {
  const { removeColumn } = useDashboardColumns();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { attributes, listeners, ref, index } = useContext(SortableItemContext);

  const handleClose = useCallback(() => {
    if (typeof index === 'number') {
      removeColumn(index);
    }
  }, [index, removeColumn]);

  return (
    <VStack width={columnWidth} height="100%" flexGrow={0} backgroundColor="surfaceBrightest">
      {showHeader && (
        <HStack
          px="md"
          py="xl"
          justifyContent="space-between"
          alignItems="center"
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outlineVariant"
        >
          <HStack>
            <strong>
              <Pressable {...attributes} {...listeners} ref={ref}>
                <DragIcons />
              </Pressable>
            </strong>
            <Text fontSize="xl" fontWeight="bold" color="onSurface">
              通知
            </Text>
          </HStack>
          <Pressable onPress={handleClose}>
            <CloseIcons />
          </Pressable>
        </HStack>
      )}
      <NotificationList />
    </VStack>
  );
};
