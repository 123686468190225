// @ts-nocheck

import { U_FEED_URL_BASE } from '@env';
import getUrls from 'get-urls';
import { StreamMessage } from 'stream-chat-react';
import { DefaultStreamChatGenerics } from 'stream-chat-react/dist/types/types';
import { generateNavigateParameterFromPath } from '~/utils/generateNavigateParameterFromPath';
import { OpenInUmotionButton } from './OpenInUmotionButton';
import { PreviewActivityChart } from './PreviewActivityChart';
import { PreviewActivityDashboard } from './PreviewActivityDashboard';
import { PreviewCattlePedigreeParams } from './PreviewCattlePedigreeParams';
import { PreviewCattleSensorMapping } from './PreviewCattleSensorMapping';
import { PreviewEventSuggestion } from './PreviewEventSuggestion';
import { PreviewMilkAmountGraphs } from './PreviewMilkAmountGraphs';
import { PreviewMilkAmoutDashboard } from './PreviewMilkAmoutDashboard';
import { PreviewTask } from './PreviewTask';
interface Props {
  message: StreamMessage<DefaultStreamChatGenerics>;
}
export const Previews: React.FC<Props> = ({ message }) => {
  const getUrlsFromLargeText = (text: string, chunkSize = 500, overlapSize = 50) => {
    const urls = new Set<string>();

    for (let i = 0; i < text.length; i += chunkSize) {
      try {
        const chunk = text.slice(i, i + chunkSize + overlapSize);
        getUrls(chunk).forEach((url) => urls.add(url));
      } catch (e) {
        console.warn('URL解析エラー:', e);
        continue;
      }
    }

    return Array.from(urls);
  };

  //const urls = getUrlsFromLargeText(message?.text?.replaceAll('\\', '') || '');

  const urls = message?.attachments?.filter((a) => a.og_scrape_url !== undefined).map((a) => a.og_scrape_url) ?? [];

  if (message.um_event_params) {
    return (
      <PreviewEventSuggestion
        // @ts-expect-error
        key={`preview-event-suggestion-${message.um_event_params.id}`}
        eventSuggestion={message.um_event_params}
      />
    );
  }

  if (message.um_ocr_data) {
    if (message.um_ocr_data?.type === 'pedigree') {
      return (
        <PreviewCattlePedigreeParams key={`cattle-pedigree-params-${message.id}`} data={message.um_ocr_data?.data} />
      );
    } else if (message.um_ocr_data?.type === 'sensor_apply') {
      return (
        <PreviewCattleSensorMapping
          key={`preview-cattle-params-${message.id}`}
          data={message.um_ocr_data?.data ?? []}
        />
      );
    }
  }

  const previews =
    message?.alert_type !== undefined
      ? // @ts-expect-error TS(2322): Type 'unknown' is not assignable to type 'string'.
        [<OpenInUmotionButton key={message.id} mobileUrl={message?.cow_url_mobile} pcUrl={message?.cow_url_pc} />]
      : urls.map((url) => {
          if (url.match(U_FEED_URL_BASE)) {
            const parsed = generateNavigateParameterFromPath(url.split(']')[0].replace(U_FEED_URL_BASE, ''));

            // 念の為こちらにもそれぞれ key を付与
            switch (parsed.screenName) {
              case 'TaskDetail':
                return (
                  <PreviewTask
                    key={`preview-task-${parsed.params?.id as string}`}
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'id' does not exist on type '{}'.
                    id={parsed.params?.id}
                  />
                );
              case 'ActivityDashboard':
                return (
                  <PreviewActivityDashboard
                    key={`preview-dashboard-${parsed.params?.farmId as string}`}
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parsed.params?.farmId}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                  />
                );
              case 'MilkAmoutDashboard':
                return (
                  <PreviewMilkAmoutDashboard
                    key={`preview-milk-amount-dashboard-${parsed.params?.farmId as string}`}
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parsed.params?.farmId}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                  />
                );
              case 'MilkAmountGraphs':
                return (
                  <PreviewMilkAmountGraphs
                    key={`preview-milk-amount-graphs-${parsed.params?.farmId as string}`}
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parsed.params?.farmId}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parsed.params?.groupId}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                    // @ts-expect-error TS(2339): Property 'range' does not exist on type '{}'.
                    range={parsed.params?.range}
                    // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
                    type={parsed.params?.type}
                  />
                );
              case 'ActivityGraphs':
                return (
                  <PreviewActivityChart
                    // @ts-expect-error TS(2339): Property 'farmId' does not exist on type '{}'.
                    farmId={parseInt(parsed.params?.farmId)}
                    // @ts-expect-error TS(2339): Property 'groupId' does not exist on type '{}'.
                    groupId={parseInt(parsed.params?.groupId)}
                    // @ts-expect-error TS(2339): Property 'endDate' does not exist on type '{}'.
                    endDate={parsed.params?.endDate}
                    // @ts-expect-error TS(2339): Property 'range' does not exist on type '{}'.
                    range={parsed.params?.range}
                    // @ts-expect-error TS(2339): Property 'type' does not exist on type '{}'.
                    type={parsed.params?.type}
                  />
                );
            }
          }
        });

  return previews;
};
