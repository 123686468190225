import { useState } from 'react';
import type { Media } from '~/components/ChannelInfo/Media';
import type { Channel } from 'stream-chat';
import type { DefaultStreamChatGenerics } from 'stream-chat-react';

const PER_PAGE = 10;

export const useChannelMediaList = (appChannel: Channel<DefaultStreamChatGenerics> | undefined) => {
  const [mediaList, setMediaList] = useState<Media[]>([]);
  const [offset, setOffset] = useState(0);

  const fetchMedia = async (currentOffset: number) => {
    const result = await appChannel?.search(
      {
        'attachments.type': {
          $in: ['image', 'video'],
        },
      },
      {
        limit: PER_PAGE,
        offset: currentOffset * PER_PAGE,
        sort: [{ created_at: -1 }],
      }
    );
    if (!result) {
      return;
    }
    const mediaList = result?.results.map((message) => ({
      id: message.message.id,
      url: message.message.attachments?.[0].image_url || message.message.attachments?.[0].asset_url || '',
      type: (message.message.attachments?.[0].type || 'image') as 'image' | 'video',
    }));
    setMediaList((prevData) => [...prevData, ...mediaList]);
  };
  return { mediaList, fetchMedia, offset, setOffset, setMediaList };
};
