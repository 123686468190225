import { HStack, VStack, Link } from '@gluestack-ui/themed-native-base';

import { Icon } from '~/components/builder/Icon';

interface Props {
  files: any[];
}

export const FilesThumbnail: React.FC<Props> = ({ files }) => {
  return (
    <VStack>
      {files.map((file) => (
        <HStack alignItems="center" space="2xs">
          <Icon name="document-outline" />
          <Link href={file.url}>ブラウザで開く</Link>
        </HStack>
      ))}
    </VStack>
  );
};
