export type EmojiData = {
  name: string;
  short_names: string[];
  keywords: string[];
  yomi: string;
  image: any;
};

const umotionEmojis = [
  {
    name: '乳房炎 [にゅうぼうえん]',
    short_names: ['nyuubouen'],
    keywords: ['乳房炎', 'にゅうぼうえん', 'nyuubouen'],
    yomi: 'にゅうぼうえん',
    image: require('../../assets/emoji/nyuubouen.png'),
  },
  {
    name: '乾乳 [かんにゅう]',
    short_names: ['kannyuu'],
    keywords: ['乾乳', 'かんにゅう', 'kannyuu'],
    yomi: 'かんにゅう',
    image: require('../../assets/emoji/kannyuu.png'),
  },
  {
    name: '体重測定 [たいじゅうそくてい]',
    short_names: ['taijyusokutei'],
    keywords: ['体重測定', 'たいじゅうそくてい', 'taijyusokutei'],
    yomi: 'たいじゅうそくてい',
    image: require('../../assets/emoji/taijyusokutei.png'),
  },
  {
    name: '肥育 [ひいく]',
    short_names: ['hiiku'],
    keywords: ['肥育', 'ひいく', 'hiiku'],
    yomi: 'ひいく',
    image: require('../../assets/emoji/hiiku.png'),
  },
  {
    name: '種付 [たねつけ]',
    short_names: ['tanetuke'],
    keywords: ['種付', 'たねつけ', 'tanetuke'],
    yomi: 'たねつけ',
    image: require('../../assets/emoji/tanetuke.png'),
  },
  {
    name: '除角 [じょかく]',
    short_names: ['jokaku'],
    keywords: ['除角', 'じょかく', 'jokaku'],
    yomi: 'じょかく',
    image: require('../../assets/emoji/jokaku.png'),
  },
  {
    name: '去勢 [きょせい]',
    short_names: ['kyosei'],
    keywords: ['去勢', 'きょうせい', 'kyosei'],
    yomi: 'きょうせい',
    image: require('../../assets/emoji/kyosei.png'),
  },
  {
    name: '枝肉成績 [えだにくせいせき]',
    short_names: ['edanikuseiseki'],
    keywords: ['枝肉成績', 'えだにくせいせき', 'edanikuseiseki'],
    yomi: 'えだにくせいせき',
    image: require('../../assets/emoji/edanikuseiseki.png'),
  },
  {
    name: '削蹄 [さくてい]',
    short_names: ['sakutei'],
    keywords: ['削蹄', 'さくてい', 'sakutei'],
    yomi: 'さくてい',
    image: require('../../assets/emoji/sakutei.png'),
  },
  {
    name: '出荷 [しゅっか]',
    short_names: ['syukka'],
    keywords: ['出荷', 'しゅっか', 'syukka'],
    yomi: 'しゅっか',
    image: require('../../assets/emoji/syukka.png'),
  },
  {
    name: '繁殖除外 [はんしょくじょがい]',
    short_names: ['hansyokujogai'],
    keywords: ['繁殖除外', 'はんしょくじょがい', 'hansyokujogai'],
    yomi: 'はんしょくじょがい',
    image: require('../../assets/emoji/hansyokujogai.png'),
  },
  {
    name: '採卵 [さいらん]',
    short_names: ['sairan'],
    keywords: ['採卵', 'さいらん', 'sairan'],
    yomi: 'さいらん',
    image: require('../../assets/emoji/sairan.png'),
  },
  {
    name: '分娩 [ぶんべん]',
    short_names: ['bunben'],
    keywords: ['分娩', 'ぶんべん', 'bunben'],
    yomi: 'ぶんべん',
    image: require('../../assets/emoji/bunben.png'),
  },
  {
    name: '流産 [りゅうざん]',
    short_names: ['ryuzan'],
    keywords: ['流産', 'りゅうざん', 'ryuzan'],
    yomi: 'りゅうざん',
    image: require('../../assets/emoji/ryuzan.png'),
  },
  {
    name: '妊娠検定 [にんしんかんてい]',
    short_names: ['ninsinkantei'],
    keywords: ['妊娠検定', 'にんしんかんてい', 'ninsinkantei'],
    yomi: 'にんしんかんてい',
    image: require('../../assets/emoji/ninsinkantei.png'),
  },
  {
    name: 'ホルモンプログラム [ほるもんぷろぐらむ]',
    short_names: ['horumonpuroguramu'],
    keywords: ['ホルモンプログラム', 'ほるもんぷろぐらむ', 'horumonpuroguramu'],
    yomi: 'ほるもんぷろぐらむ',
    image: require('../../assets/emoji/horumonpuroguramu.png'),
  },
  {
    name: '感染症 [かんせんしょう]',
    short_names: ['kansenshou'],
    keywords: ['感染症', 'かんせんしょう', 'kansenshou'],
    yomi: 'かんせんしょう',
    image: require('../../assets/emoji/kansenshou.png'),
  },
  {
    name: '跳行・蹄病 [はこうていびょう]',
    short_names: ['hakouteibyou'],
    keywords: ['跳行・蹄病', 'はくていびょう', 'hakouteibyou'],
    yomi: 'はこうていびょう',
    image: require('../../assets/emoji/hakouteibyou.png'),
  },
  {
    name: '周産病・代謝病 [しゅうさんびょう・たいしゃびょう]',
    short_names: ['syusanbyoutaishabyou'],
    keywords: ['周産病・代謝病', 'しゅっさんびょうたいしさんびょう', 'syusanbyoutaishabyou'],
    yomi: 'しゅっさんびょうたいしさんびょう',
    image: require('../../assets/emoji/syusanbyoutaishabyou.png'),
  },
  {
    name: '繁殖障害 [はんしょくしょうがい]',
    short_names: ['hansyokusyougai'],
    keywords: ['繁殖障害', 'はんしょくしょうがい', 'hansyokusyougai'],
    yomi: 'はんしょくしょうがい',
    image: require('../../assets/emoji/hansyokusyougai.png'),
  },
];

export const customEmojis = [
  {
    name: 'ありがとうございます',
    short_names: ['arigatougozaimasu'],
    keywords: ['ありがとうございます', 'arigatougozaimasu'],
    yomi: 'ありがとうございます',
    image: require('../../assets/emoji/arigatougozaimasu.png'),
  },
  {
    name: 'ありがとうございました',
    short_names: ['arigatougozaimasita'],
    keywords: ['ありがとうございました', 'arigatougozaimashita'],
    yomi: 'ありがとうございました',
    image: require('../../assets/emoji/arigatougozaimasita-.png'),
  },
  {
    name: 'お気になさらず [おきになさらず]',
    short_names: ['okininasarazu'],
    keywords: ['お気になさらず', 'okininasarazu', 'おきになさらず'],
    yomi: 'おきになさらず',
    image: require('../../assets/emoji/okininasarazu.png'),
  },
  {
    name: 'お大事に [おだいじに]',
    short_names: ['odaijini'],
    keywords: ['おだいじに', 'お大事に', 'odaijini'],
    yomi: 'おだいじに',
    image: require('../../assets/emoji/odaijini.png'),
  },
  {
    name: 'お疲れ様でした',
    short_names: ['otukaresamadesita'],
    keywords: ['thank you for your hard work', 'お疲れ様でした'],
    yomi: 'おつかれさまでした',
    image: require('../../assets/emoji/otukaresamadesita.png'),
  },
  {
    name: 'お願いします []',
    short_names: ['onegaishimasu'],
    keywords: ['お願いします', 'onegaishimasu', 'おねがいします'],
    yomi: 'おねがいします',
    image: require('../../assets/emoji/onegaisimasu.png'),
  },
  {
    name: '確認中 []',
    short_names: ['kakunintyuu'],
    keywords: ['confirming', '確認中'],
    yomi: 'かくにんちゅう',
    image: require('../../assets/emoji/kakunintyuu.png'),
  },
  {
    name: 'さすがだ',
    short_names: ['sasugada'],
    keywords: ['さすがだ', 'sasugada'],
    yomi: 'さすがだ',
    image: require('../../assets/emoji/sasugada.png'),
  },
  {
    name: 'そうなんだ []',
    short_names: ['sounanda'],
    keywords: ['そうなんだ', 'sounanda'],
    yomi: 'そうなんだ',
    image: require('../../assets/emoji/sounanda.png'),
  },
  {
    name: 'そうなんです',
    short_names: ['sounandesu'],
    keywords: ['そうなんです', 'sounandesu'],
    yomi: 'そうなんです',
    image: require('../../assets/emoji/sounandesu.png'),
  },
  {
    name: '承知しました []',
    short_names: ['syoutisimasita'],
    keywords: ['しょうちしました', '承知しました', 'syoutisimasita'],
    yomi: 'しょうちしました',
    image: require('../../assets/emoji/syoutisimasita.png'),
  },
  {
    name: '対応不要 []',
    short_names: ['taioufuyou'],
    keywords: ['たいおうふよう', '対応不要', 'taioufuyou'],
    yomi: 'たいおうふよう',
    image: require('../../assets/emoji/taioufuyou.png'),
  },
  {
    name: '対応済み []',
    short_names: ['taiouzumi'],
    keywords: ['response complete', '対応済み'],
    yomi: 'たいおうずみ',
    image: require('../../assets/emoji/taiouzumi.png'),
  },
  {
    name: '確かに',
    short_names: ['tasikani'],
    keywords: ['たしかに', '確かに'],
    yomi: 'たしかに',
    image: require('../../assets/emoji/tasikani.png'),
  },
  {
    name: 'ですね',
    short_names: ['desune'],
    keywords: ['desune', 'ですね'],
    yomi: 'ですね',
    image: require('../../assets/emoji/desune.png'),
  },
  {
    name: '同感です [どうかんです]',
    short_names: ['doukandesu'],
    keywords: ['同感です', 'doukandesu', 'どうかんです'],
    yomi: 'どうかんです',
    image: require('../../assets/emoji/doukandesu.png'),
  },
  {
    name: 'なるほど',
    short_names: ['naruhodo'],
    keywords: ['naruhodo', 'なるほど'],
    yomi: 'なるほど',
    image: require('../../assets/emoji/naruhodo.png'),
  },
  {
    name: '便利 [べんり]',
    short_names: ['benri'],
    keywords: ['便利', 'benri', 'べんり'],
    yomi: 'べんり',
    image: require('../../assets/emoji/benri.png'),
  },
  {
    name: '問題なし [もんだいなし]',
    short_names: ['mondainasi'],
    keywords: ['もんだいなし', '問題なし', 'mondainasi'],
    yomi: 'もんだいなし',
    image: require('../../assets/emoji/mondainasi.png'),
  },
  {
    name: 'Will Do [やります]',
    short_names: ['yarimasu'],
    keywords: ['will do', 'やります'],
    yomi: 'やります',
    image: require('../../assets/emoji/yarimasu.png'),
  },
  {
    name: 'Will Try [やってみます]',
    short_names: ['yattemimasu'],
    keywords: ['will try', 'やってみます'],
    yomi: 'やってみます',
    image: require('../../assets/emoji/yattemimasu.png'),
  },
  {
    name: "That's Great [よかった]",
    short_names: ['yokatta'],
    keywords: ["that's great", 'よかった'],
    yomi: 'よかった',
    image: require('../../assets/emoji/yokatta.png'),
  },
  {
    name: 'Please [よろしくお願いします]',
    short_names: ['yorosikuonegaisimasu'],
    keywords: ['please', 'よろしくお願いします'],
    yomi: 'よろしくおねがいします',
    image: require('../../assets/emoji/yorosikuonegaisimasu.png'),
  },
  {
    name: 'Welcome [ようこそ]',
    short_names: ['youkoso'],
    keywords: ['welcome', 'ようこそ'],
    yomi: 'ようこそ',
    image: require('../../assets/emoji/youkoso.png'),
  },
  {
    name: '了解です [りょうかいです]',
    short_names: ['ryoukaidesu'],
    keywords: ['りょうかいです', '了解です', 'ryoukaidesu'],
    yomi: 'りょうかいです',
    image: require('../../assets/emoji/ryoukaidesu.png'),
  },
  {
    name: '直接話した [ちょくせつはなした]',
    short_names: ['tyokusetuhanasita'],
    keywords: ['直接話した', 'chokusetsuhanashita', 'ちょくせつはなした'],
    yomi: 'ちょくせつはなした',
    image: require('../../assets/emoji/tyokusetuhanasita.png'),
  },
  {
    name: 'なるほどなるほど [なるほどなるほど]',
    short_names: ['naruhodonaruhodo'],
    keywords: ['なるほどなるほど', 'naruhodonaruhodo'],
    yomi: 'なるほどなるほど',
    image: require('../../assets/emoji/naruhodonaruhodo.png'),
  },
  {
    name: 'それだ [それだ]',
    short_names: ['soreda'],
    keywords: ['それだ', 'soreda'],
    yomi: 'それだ',
    image: require('../../assets/emoji/soreda.png'),
  },
  {
    name: '少々お待ちを [しょうしょうおまちを]',
    short_names: ['syousyouomatio'],
    keywords: ['少々お待ちを', 'shoushouomachio'],
    yomi: 'しょうしょうおまちを',
    image: require('../../assets/emoji/syousyouomatio.png'),
  },
  {
    name: '感 [かん]',
    short_names: ['kan'],
    keywords: ['感', 'kan', 'かん'],
    yomi: 'かん',
    image: require('../../assets/emoji/kan.png'),
  },
  {
    name: '良さそう [よさそう]',
    short_names: ['yosasou'],
    keywords: ['良さそう', 'yosasou', 'よさそう'],
    yomi: 'よさそう',
    image: require('../../assets/emoji/yosasou.png'),
  },
  {
    name: 'そうだそうだ [そうだそうだ]',
    short_names: ['soudasouda'],
    keywords: ['そうだそうだ', 'soudasouda'],
    yomi: 'そうだそうだ',
    image: require('../../assets/emoji/soudasouda.png'),
  },
  {
    name: '無念 [もんもん]',
    short_names: ['munen'],
    keywords: ['むねん', 'munen', '無念'],
    yomi: 'むねん',
    image: require('../../assets/emoji/munen.png'),
  },
  {
    name: 'すごい [すごい]',
    short_names: ['sugoi'],
    keywords: ['すごい', 'sugoi'],
    yomi: 'すごい',
    image: require('../../assets/emoji/sugoi.png'),
  },
  {
    name: 'ごゆっくり [ごゆっくり]',
    short_names: ['goyukkuri'],
    keywords: ['ごゆっくり', 'goyukkuri'],
    yomi: 'ごゆっくり',
    image: require('../../assets/emoji/goyukkuri.png'),
  },
  {
    name: '確認 [かくにん]',
    short_names: ['kakunin'],
    keywords: ['確認', 'kakunin', 'かくにん'],
    yomi: 'かくにん',
    image: require('../../assets/emoji/kakunin.png'),
  },
  {
    name: '確認OK [かくにんOK]',
    short_names: ['kakuninok'],
    keywords: ['確認OK', 'kakuninok', 'かくにんOK'],
    yomi: 'かくにんOK',
    image: require('../../assets/emoji/kakuninok.png'),
  },
  {
    name: 'お手数おかけします [おてすうおかけします]',
    short_names: ['otesuuokakesimasu'],
    keywords: ['お手数おかけします', 'otesuuokakesimasu', 'おてすうおかけします'],
    yomi: 'おてすうおかけします',
    image: require('../../assets/emoji/otesuuokakesimasu.png'),
  },
  {
    name: 'ですです [ですです]',
    short_names: ['desudesu'],
    keywords: ['ですです', 'desudesu'],
    yomi: 'ですです',
    image: require('../../assets/emoji/desudesu.png'),
  },
  {
    name: 'それです',
    short_names: ['soredesu'],
    keywords: ['それです', 'soredesu'],
    yomi: 'それです',
    image: require('../../assets/emoji/soredesu.png'),
  },
  {
    name: 'ですよね',
    short_names: ['desuyone'],
    keywords: ['ですよね', 'desuyone'],
    yomi: 'ですよね',
    image: require('../../assets/emoji/desuyone.png'),
  },
  {
    name: '承知 [しょうち]',
    short_names: ['syouti'],
    keywords: ['承知', 'shouchi', 'しょうち'],
    yomi: 'しょうち',
    image: require('../../assets/emoji/syouti.png'),
  },
  {
    name: '様子見で [ようすみで]',
    short_names: ['yousumide'],
    keywords: ['ようすみで', 'yousumide', '様子見で'],
    yomi: 'ようすみで',
    image: require('../../assets/emoji/yousumide.png'),
  },
  {
    name: 'そうですね',
    short_names: ['soudesune'],
    keywords: ['そうですね', 'soudesune'],
    yomi: 'そうですね',
    image: require('../../assets/emoji/soudesune.png'),
  },
  {
    name: 'いえいえ',
    short_names: ['ieie'],
    keywords: ['いえいえ', 'ieie'],
    yomi: 'いえいえ',
    image: require('../../assets/emoji/ieie.png'),
  },
  {
    name: 'すみません',
    short_names: ['sumimasen'],
    keywords: ['すみません', 'sumimasen'],
    yomi: 'すみません',
    image: require('../../assets/emoji/sumimasen.png'),
  },
  {
    name: '対応中 [たいおうちゅう]',
    short_names: ['taioutyu'],
    keywords: ['対応中', 'taiouchuu', 'たいおうちゅう'],
    yomi: 'たいおうちゅう',
    image: require('../../assets/emoji/taioutyu.png'),
  },
  {
    name: '気をつけて [きをつけて]',
    short_names: ['okiwotukete'],
    keywords: ['気をつけて', 'kiwotsukete', 'きをつけて'],
    yomi: 'きをつけて',
    image: require('../../assets/emoji/okiwotukete.png'),
  },
  {
    name: '戻ってます [もどってます]',
    short_names: ['modottemasu'],
    keywords: ['戻ってます', 'modottemasu', 'もどってます'],
    yomi: 'もどってます',
    image: require('../../assets/emoji/modottemasu.png'),
  },
  {
    name: 'なんと',
    short_names: ['nanto'],
    keywords: ['なんと', 'nanto'],
    yomi: 'なんと',
    image: require('../../assets/emoji/nanto.png'),
  },
  {
    name: '完了 [かんりょう]',
    short_names: ['kanryou'],
    keywords: ['完了', 'kanryou', 'かんりょう'],
    yomi: 'かんりょう',
    image: require('../../assets/emoji/kanryou.png'),
  },
  {
    name: '解決済み [かいけつずみ]',
    short_names: ['kaiketuzumi'],
    keywords: ['解決済み', 'kaiketsuzumi', 'かいけつずみ'],
    yomi: 'かいけつずみ',
    image: require('../../assets/emoji/kaiketuzumi.png'),
  },
  {
    name: '解答済み [かいとうずみ]',
    short_names: ['kaitouzumi'],
    keywords: ['解答済み', 'kaitouzumi', 'かいとうずみ'],
    yomi: 'かいとうずみ',
    image: require('../../assets/emoji/kaitouzumi.png'),
  },
  {
    name: '確認します [かくにんします]',
    short_names: ['kakuninsimasu'],
    keywords: ['確認します', 'kakuninshimasu', 'かくにんします'],
    yomi: 'かくにんします',
    image: require('../../assets/emoji/kakuninsimasu.png'),
  },
  {
    name: 'かしこまりました',
    short_names: ['kasikomarimasita'],
    keywords: ['かしこまりました', 'kasikomarimashita', '確かに'],
    yomi: 'かしこまりました',
    image: require('../../assets/emoji/kasikomarimasita.png'),
  },
  {
    name: 'お客様確認 [おきゃくさまかくにん]',
    short_names: ['okyakusamakiten'],
    keywords: ['お客様確認', 'okyakusamakakunin', 'おきゃくさまかくにん'],
    yomi: 'おきゃくさまかくにん',
    image: require('../../assets/emoji/okyakusamakiten.png'),
  },
  {
    name: '不要です [ふようです]',
    short_names: ['fuyoudesu'],
    keywords: ['不要です', 'fuyoudesu', 'ふようです'],
    yomi: 'ふようです',
    image: require('../../assets/emoji/fuyoudesu.png'),
  },
  {
    name: '見たよ [みたよ]',
    short_names: ['mitayo'],
    keywords: ['見たよ', 'mitayo', 'みたよ'],
    yomi: 'みたよ',
    image: require('../../assets/emoji/mitayo.png'),
  },
  {
    name: 'ドンマイ [どんまい]',
    short_names: ['donmai'],
    keywords: ['ドンマイ', 'donmai', 'どんまい'],
    yomi: 'どんまい',
    image: require('../../assets/emoji/donmai.png'),
  },
  {
    name: '後で教えて [あとでおしえて]',
    short_names: ['atodekyouyu'],
    keywords: ['後で教えて', 'atodeoshiete', 'あとでおしえて'],
    yomi: 'あとでおしえて',
    image: require('../../assets/emoji/atodekyouyu.png'),
  },
  {
    name: '確認待ち [かくにんまち]',
    short_names: ['kakuninmati'],
    keywords: ['確認待ち', 'kakuninmachi', 'かくにんまち'],
    yomi: 'かくにんまち',
    image: require('../../assets/emoji/kakuninmati.png'),
  },
  {
    name: 'タイポ [たいぽ]',
    short_names: ['taipo'],
    keywords: ['タイポ', 'typo', 'たいぽ'],
    yomi: 'たいぽ',
    image: require('../../assets/emoji/taipo.png'),
  },
  {
    name: '応援 [おうえん]',
    short_names: ['oufu'],
    keywords: ['応援', 'ouen', 'おうえん'],
    yomi: 'おうえん',
    image: require('../../assets/emoji/oufu.png'),
  },
  {
    name: '素敵 [すてき]',
    short_names: ['suteki'],
    keywords: ['素敵', 'suteki', 'すてき'],
    yomi: 'すてき',
    image: require('../../assets/emoji/suteki.png'),
  },
  {
    name: 'こちらこそ [こちらこそ]',
    short_names: ['kotirakoso'],
    keywords: ['こちらこそ', 'kochirakoso', 'こちらこそ'],
    yomi: 'こちらこそ',
    image: require('../../assets/emoji/kotirakoso.png'),
  },
  {
    name: '面白い [おもしろい]',
    short_names: ['omosiro'],
    keywords: ['面白い', 'omoshiroi', 'おもしろい'],
    yomi: 'おもしろい',
    image: require('../../assets/emoji/omosiro.png'),
  },
  {
    name: 'そうです [そうです]',
    short_names: ['soudesu'],
    keywords: ['そうです', 'soudesu', 'そうです'],
    yomi: 'そうです',
    image: require('../../assets/emoji/soudesu.png'),
  },
  {
    name: 'つらい',
    short_names: ['turai'],
    keywords: ['tsurai', 'つらい'],
    yomi: 'つらい',
    image: require('../../assets/emoji/turai.png'),
  },
  {
    name: '素晴らしい [すばらしい]',
    short_names: ['subarasii'],
    keywords: ['素晴らしい', 'subarashii', 'すばらしい'],
    yomi: 'すばらしい',
    image: require('../../assets/emoji/subarasii.png'),
  },
  {
    name: 'それな [それな]',
    short_names: ['sorena'],
    keywords: ['それな', 'sorena', 'それな'],
    yomi: 'それな',
    image: require('../../assets/emoji/sorena.png'),
  },
  {
    name: '要対応',
    short_names: ['youtaiou', 'ようたいおう'],
    keywords: ['要対応', 'ようたいおう'],
    yomi: 'ようたいおう',
    image: require('../../assets/emoji/youtaiou.png'),
  },
  {
    name: '至急',
    short_names: ['sikyuu', 'しきゅう'],
    keywords: ['至急', 'しきゅう'],
    yomi: 'しきゅう',
    image: require('../../assets/emoji/sikyuu.png'),
  },
  {
    name: 'U-motion Platform',
    short_names: ['UMP'],
    keywords: ['U-motion Platform', 'ゆーもーしょんぷらっとふぉーむ', 'ゆーえむぴー', 'UMP'],
    yomi: 'ゆーえむぴー',
    image: require('../../assets/emoji/UMP.png'),
  },
  {
    name: 'デザミス',
    short_names: ['desamis'],
    keywords: ['desamis', 'デザミス', 'でざみす'],
    yomi: 'でざみす',
    image: require('../../assets/emoji/desamis.png'),
  },
  {
    name: 'U-motion',
    short_names: ['umotion'],
    keywords: ['u-motion', 'umotion', 'ユーモーション', 'ゆーもーしょん', 'ゆーえむ'],
    yomi: 'ゆーもーしょん',
    image: require('../../assets/emoji/u-motion.png'),
  },
  {
    name: 'Chumly',
    short_names: ['Chumly'],
    keywords: ['Chumly', 'tyamuri', 'ちゃむりー', 'チャムリー'],
    yomi: 'ちゃむりー',
    image: require('../../assets/emoji/chumly.png'),
  },
]
  .sort((a, b) => a.yomi.localeCompare(b.yomi, 'ja', { sensitivity: 'accent' }))
  .concat(umotionEmojis)
  .reduce(
    (prev, curr) => {
      prev[curr.short_names[0]] = curr;
      return prev;
    },
    {} as { [key: string]: EmojiData }
  );

export const customNativeEmojis = Object.keys(customEmojis).map((emoji) => customEmojis[emoji]);

export const customWebEmojis = [
  {
    id: 'custom',
    name: 'Custom',
    emojis: Object.keys(customEmojis).map((emoji) => ({
      id: customEmojis[emoji].short_names[0],
      name: customEmojis[emoji].short_names[0],
      keywords: customEmojis[emoji].keywords,
      skins: [{ src: customEmojis[emoji].image }],
    })),
  },
];
