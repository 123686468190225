// https://getstream.io/chat/docs/sdk/react/guides/persist-input-text-in-localstorage/
import { useCallback, useRef } from 'react';
import { useUConciergeNotify } from '~/hooks/useUConciergeNotify';
import { MessageToSend, useChannelActionContext, useChannelStateContext } from '~/lib/StreamChatReact.web';
import { setChatBotQuickAction } from '~/slices/chatBotQuickActionSlice';
import { useAppDispatch } from '~/store';
import { getDraftById, removeDraft, updateDraft } from '~/utils/draft.web';
import type { Message, MessageResponse, Channel as StreamChannel } from 'stream-chat';

const getDraftKey = (channel: StreamChannel, thread?: Pick<MessageResponse, 'id'> | null): string => {
  if (thread) {
    return `${channel.cid}:${thread.id}`;
  } else {
    return channel.cid;
  }
};

export const useDraft = () => {
  const { channel, thread } = useChannelStateContext();
  const { sendMessage } = useChannelActionContext();
  const isCheckedRef = useRef<boolean>(false);
  const isMarkdownRef = useRef(false);
  const { isUConciergeInitialized, subscribeEventListener } = useUConciergeNotify();
  const dispatch = useAppDispatch();

  const handleDraftChange = useCallback(
    (draft: { text: string; mentioned_users: object[] }) => {
      updateDraft(getDraftKey(channel, null), { version: 'v1', ...draft });
    },
    [channel.cid]
  );

  const handleThreadDraftChange = useCallback(
    (draft: { text: string; mentioned_users: object[] }) => {
      updateDraft(getDraftKey(channel, thread), { version: 'v1', ...draft });
    },
    [channel.cid, thread]
  );

  const getMainInputDraft = useCallback(() => {
    return getDraftById(getDraftKey(channel, null));
  }, [channel.cid]);

  const getThreadInputDraft = useCallback(() => {
    if (!thread) return;
    return getDraftById(getDraftKey(channel, thread));
  }, [channel.cid, thread]);

  const overrideSubmitHandler = useCallback(
    async (message: MessageToSend, channelCid: string, customMessageData?: Partial<Message>) => {
      const key = message.parent ? `${channelCid}:${message.parent.id}` : channelCid;

      const mantineInputRef = document.querySelectorAll('.ProseMirror');

      mantineInputRef.forEach((mantineInputRef) => {
        const messageInputRef = mantineInputRef.closest('.str-chat__message-input');
        const mainContainerRef = mantineInputRef.closest('.str-chat__main-panel');
        const threadContainerRef = mantineInputRef.closest('.str-chat__thread-container');

        // @ts-ignore
        const dataKey = messageInputRef?.dataset.key;
        if (!dataKey.includes(channelCid)) {
          return;
        }

        // remove channel input, when submitting a channel message
        if (!message.parent && mainContainerRef) {
          mantineInputRef.innerHTML = '';
        }

        // remove thread input, when submitting a thread message
        if (message.parent && threadContainerRef) {
          mantineInputRef.innerHTML = '';
        }
      });

      // Format to rich text before sending
      isMarkdownRef.current && (message.text = message.text?.replaceAll('\u00a0', ' ').replace(/\\/g, ''));

      if (isUConciergeInitialized) {
        subscribeEventListener();
      }

      dispatch(setChatBotQuickAction(true));

      await Promise.all([
        removeDraft(key),
        // @ts-expect-error TS(2345): Argument of type '{ parent_id: string | undefined;... Remove this comment to see the full error message
        sendMessage(message, {
          ...customMessageData,
          parent_id: message.parent?.id,
          show_in_channel: message.parent && isCheckedRef.current,
        }),
      ]);
    },
    [sendMessage, isUConciergeInitialized]
  );

  const toggleCheckedRef = useCallback(() => {
    isCheckedRef.current = !isCheckedRef.current;
  }, []);

  const setIsMarkdownRef = useCallback((value: boolean) => {
    isMarkdownRef.current = value;
  }, []);
  const uncheckedRef = useCallback(() => {
    isCheckedRef.current = false;
  }, []);

  return {
    getMainInputDraft,
    getThreadInputDraft,
    handleDraftChange,
    handleThreadDraftChange,
    overrideSubmitHandler,
    isCheckedRef,
    toggleCheckedRef,
    setIsMarkdownRef,
    uncheckedRef,
  };
};
