import { ScrollView, FormControl, HStack, VStack, Input, Text } from '@gluestack-ui/themed-native-base';
import { Logic } from 'native-base-form-builder';
import { useController } from 'react-hook-form';

import { FormFieldLabel } from './FormFieldLabel';

export const FormInput: React.FC<React.ComponentProps<typeof Logic> & { note?: string }> = ({
  name,
  rules,
  shouldUnregister,
  defaultValue,
  control,
  label,
  note,
  textInputProps,
}) => {
  const { field, formState } = useController({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,
  });

  const isRequired = !!rules?.required;
  const errorMessage = formState.errors?.[field.name]?.message;

  return (
    <FormControl isRequired={isRequired} isInvalid={errorMessage}>
      <VStack>
        <FormFieldLabel label={label} isRequired={isRequired} />
        <Input
          value={field.value}
          // @ts-expect-error TS(2339): Property 'value' does not exist on type 'number'.
          onChange={(e) => {
            if (e.currentTarget.value === undefined) {
              return;
            }
            field.onChange(e.currentTarget.value);
          }}
          // @ts-expect-error
          onChangeText={(text) => field.onChange(text)}
          color="onSurface"
          //FIXplaceholderTextColor="onSurfaceBright"
          fontSize="md"
          size="lg"
          variant="outline"
          borderColor="outline"
          py="sm"
          testID={textInputProps?.testID}
          _focus={{
            borderColor: 'primary',
            backgroundColor: 'surfaceBrightest',
          }}
          {...textInputProps}
        />
        {rules?.maxLength && !errorMessage && (
          <HStack alignItems="center" justifyContent="space-between">
            <Text fontSize="sm" color="onSurfaceBright">
              {/* @ts-expect-error TS(2339): Property 'value' does not exist on type 'MaxLengthRule'. */}
              {rules?.maxLength?.value}文字以内
            </Text>
            <Text fontSize="sm" color="onSurfaceBright">
              {/* @ts-expect-error TS(2339): Property 'value' does not exist on type 'MaxLengthRule'. */}
              {field.value?.length}/{rules?.maxLength?.value}
            </Text>
          </HStack>
        )}
        {note && (
          <Text fontSize="sm" color="onSurfaceBright">
            {note}
          </Text>
        )}
        {errorMessage && (
          <ScrollView horizontal showsHorizontalScrollIndicator={false} bounces={false}>
            <FormControl.ErrorMessage>{errorMessage}</FormControl.ErrorMessage>
          </ScrollView>
        )}
      </VStack>
    </FormControl>
  );
};
