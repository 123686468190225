import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { Box, Divider, FlatList, Spinner, VStack } from '@gluestack-ui/themed-native-base';
import { useLinkTo, useNavigation } from '@react-navigation/native';
import { memo, useMemo, useCallback, useState, useEffect } from 'react';
import { Platform } from 'react-native';

import {
  GetNotificationsApiResponse,
  uFeedApi,
  useGetNotificationsQuery,
  usePatchNotificationsByIdMutation,
  useGetCurrentUserSettingQuery,
  usePutCurrentUserSettingMutation,
  usePatchNotificationsReadMutation,
} from '~/api/uFeedApi';
import { EmptyMessage } from '~/components/EmptyMessage';
import { Skeleton } from '~/components/Skeleton';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { useAppDispatch } from '~/store';

import { NotificationItem } from './NotificationItem';
import type { NotificationInfo } from './types';

type Props = {
  messagePressFn?: (message: { type: 'my' | 'dm'; channelId: string; messageId: string }) => void;
};

const SkeletonItem = memo(() => (
  <VStack space={8}>
    <Skeleton.Text />
    <Skeleton.Text />
    <Skeleton.Text />
  </VStack>
));

const Loading = memo(() => (
  <VStack backgroundColor="white" padding={4} flex={1} space={8}>
    <SkeletonItem />
    <SkeletonItem />
    <SkeletonItem />
  </VStack>
));

const NotificationListContent = memo(({ messagePressFn }: Props) => {
  const { chatClient, setAppChannel } = useStreamChatContext();
  const notifications = useGetNotificationsQuery({ withReaction: 1 });
  const [trigger, result] = uFeedApi.endpoints.getNotifications.useLazyQuery();
  const [readNotification] = usePatchNotificationsByIdMutation();
  const [notificationData, setNotificationData] = useState<NotificationInfo[]>([]);
  const [refreshing, setRefreshing] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const navigation = useNavigation();
  const linkTo = useLinkTo();
  const [noMoreData, setNoMoreData] = useState(false);
  const [lastId, setLastId] = useState<number | null>(null);
  const [initialized, setInitialized] = useState(false);
  const dispatch = useAppDispatch();
  const userSetting = useGetCurrentUserSettingQuery();
  const [put] = usePutCurrentUserSettingMutation();
  const [readNotifications] = usePatchNotificationsReadMutation();

  const isLoading = useMemo(() => {
    return notifications.isLoading && !notificationData;
  }, [notifications, notificationData]);

  const handleNotificationData = useCallback(
    async (newData: GetNotificationsApiResponse, shouldAppend: boolean = false) => {
      const messageIds = newData.map((item) => item.source_message_id);
      const messageInfo = (await chatClient.search({ frozen: false }, { id: { $in: messageIds } })).results.map(
        (r) => r.message
      );
      setNotificationData((preData) => {
        const data = shouldAppend ? [...preData] : [];
        newData.forEach((item) => {
          if (data.findIndex((d) => d.id === item.id) === -1) {
            const res = messageInfo.find((m) => m.id === item.source_message_id);
            data.push({
              id: item.id,
              // API のレスポンスが `2024-10-26 11:24:09 +0900` のような形式なのでタイムゾーン情報を削除
              notifiedAt: item.created_at.replace(/ \+\d{4}$/, ''),
              type: item.source_type,
              notifiedUser: item.source_user,
              notificationBody: item.body,
              // @ts-expect-error TODO: OpenAPI の定義を修正して isRead を追加する
              isRead: item.read,
              isDM: item.source_channel_is_dm,
              sourceLink: item.source_link,
              streamMessageObj: res,
            });
          }
        });
        return data;
      });
    },
    []
  );

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      if (userSetting.isSuccess) {
        put({
          body: {
            user_setting: {
              details: {
                ...userSetting?.data?.details,
                notificationsTabLastOpenAt: new Date().getTime(),
              },
            },
          },
        })
          .unwrap()
          .catch((error) => {
            console.error('update error: ', error);
          });
      }
    });

    return unsubscribe;
  }, [navigation, userSetting, put]);

  useDidMount(() => {
    // 通知ページを開いたときにReaction通知を既読にする
    readNotifications({ type: 'reaction' });

    const messageNewEventListener = chatClient.on('chumly-notification-new', async () => {
      setInitialized(false);
      setNoMoreData(false);
      setLastId(null);
      dispatch(uFeedApi.util.invalidateTags(['Notification']));
    });

    return () => {
      messageNewEventListener.unsubscribe();
    };
  });

  useDidUpdate(
    () => {
      (async () => {
        if (initialized) return;

        if (notifications.data && notifications.isSuccess && notifications.status === 'fulfilled') {
          await handleNotificationData(notifications.data);
          if (notifications.data.length > 0) {
            const last = notifications.data[notifications.data.length - 1];
            setLastId(last.id);
          }
          setInitialized(true);
          setRefreshing(false);
        }
      })();
    },
    [notifications],
    true
  );

  useDidUpdate(() => {
    (async () => {
      if (result.isSuccess && result.data.length > 0) {
        if (loadingMore) {
          const newData: GetNotificationsApiResponse = result.data;
          await handleNotificationData(newData, true);
          setLoadingMore(false);
        }
        setLastId(result.data[result.data.length - 1].id);
      } else if (result.isError || result.isSuccess) {
        setNoMoreData(true);
        setLoadingMore(false);
      }
    })();
  }, [result]);

  const loadMore = useCallback(async () => {
    if (noMoreData || loadingMore || lastId == null) {
      return;
    }
    setLoadingMore(true);
    trigger({ lastId, withReaction: 1 });
  }, [noMoreData, loadingMore, lastId, trigger]);

  const handleMessagePress = useCallback(
    (arg: {
      id: number;
      sourceChannelId?: string;
      sourceChannelType?: string;
      sourceMessageId?: string;
      sourceLink: string;
      isDM: boolean;
    }) => {
      const { id, sourceChannelId, sourceChannelType, sourceMessageId, sourceLink, isDM } = arg;
      if (!sourceChannelId || !sourceMessageId || !sourceChannelType) return;
      readNotification({ id });
      if (Platform.OS === 'web') {
        const type = isDM ? 'dm' : 'my';
        if (messagePressFn) messagePressFn({ type, channelId: sourceChannelId, messageId: sourceMessageId });
      } else {
        if (isDM) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('ChatChannelMessages', {
            channelId: sourceChannelId,
            messageId: sourceMessageId,
          });
        } else {
          if (id) {
            const newChannel = chatClient.channel(sourceChannelType, sourceChannelId);
            setAppChannel(newChannel);
            linkTo(sourceLink);
          }
        }
      }
    },
    [readNotification, navigation, linkTo, chatClient, setAppChannel]
  );

  const keyExtractor = useCallback(
    (item: GetNotificationsApiResponse[number], index: number) => `${item.id}-${index}`,
    []
  );

  const renderItem = useCallback(({ item }: { item: NotificationInfo }) => {
    return <NotificationItem {...item} handleMessagePress={handleMessagePress} />;
  }, []);

  const refresh = useCallback(async () => {
    if (refreshing) {
      return;
    }

    setRefreshing(true);
    setInitialized(false);
    setNoMoreData(false);
    setLastId(null);
    notifications.refetch();
  }, [refreshing, notifications]);

  const ListFooterComponent = useCallback(() => {
    return loadingMore ? <Spinner /> : null;
  }, [loadingMore]);

  const ItemSeparatorComponent = useCallback(
    () => (
      <VStack paddingHorizontal="md">
        <Divider backgroundColor="outline" />
      </VStack>
    ),
    []
  );

  const ListEmptyComponent = useCallback(() => {
    // @ts-expect-error TS(2322): Type '{ message: string; }' is not assignable to t... Remove this comment to see the full error message
    return <EmptyMessage message="通知はありません" />;
  }, []);

  if (isLoading || !initialized) {
    return <Loading />;
  }

  return (
    <Box flex={1}>
      <FlatList
        data={notificationData}
        keyExtractor={keyExtractor}
        renderItem={renderItem}
        onRefresh={refresh}
        refreshing={refreshing}
        onEndReached={loadMore}
        onEndReachedThreshold={Platform.OS === 'web' ? 0.1 : 0.5}
        ItemSeparatorComponent={ItemSeparatorComponent}
        padding={0}
        flex={1}
        backgroundColor="white"
        ListFooterComponent={ListFooterComponent}
        ListEmptyComponent={ListEmptyComponent}
      />
    </Box>
  );
});

export const NotificationList: React.FC<Props> = ({ messagePressFn }) => {
  const { isUserConnected } = useStreamChatContext();
  if (!isUserConnected) {
    return <Loading />;
  } else {
    return <NotificationListContent messagePressFn={messagePressFn} />;
  }
};
