import { Ionicons, MaterialIcons } from '@expo/vector-icons';
import {
  Box,
  Center,
  Input,
  Icon,
  VStack,
  useTheme,
  KeyboardAvoidingView,
  HStack,
  Text,
  Spinner,
} from '@gluestack-ui/themed-native-base';
import { useNavigation, useRoute } from '@react-navigation/native';
import { useState, useMemo, useRef, useCallback, useEffect } from 'react';
import {
  Pressable,
  Dimensions,
  Platform,
  TextInput,
  useWindowDimensions,
  SafeAreaView,
  StyleSheet,
  StatusBar,
} from 'react-native';
import { TabView, TabBar, Route } from 'react-native-tab-view';
import { Channel, ChannelMemberResponse } from 'stream-chat';

import { colorTheme } from '~/config/build/tokens';
import { useChannelQuery } from '~/hooks/useChannelQuery';
import { useInputFocus } from '~/hooks/useInputFocus';
import { useMessageQuery } from '~/hooks/useMessageQuery';
import { useSearchHistory } from '~/hooks/useSearchHistory';

import { ChannelSearchResults } from './ChannelSearchResults';
import { ChatSearchResults } from './ChatSearchResults';
import { SearchHistory } from './SearchHistory';

const initialLayout = {
  width: Dimensions.get('window').width,
};

type RouteParams = {
  id?: string;
};

export const SearchScreen = () => {
  const textInputRef = useRef<TextInput | null>(null);
  const theme = useTheme();
  const { width } = useWindowDimensions();
  const [isSearching, setIsSearching] = useState(false);
  const [index, setIndex] = useState(0);
  const [targetUser, setTargetUser] = useState<ChannelMemberResponse | undefined>();
  const [showTag, setShowTag] = useState<boolean>(false);
  const [selectedChannel, setSelectedChannel] = useState<Channel | undefined>();
  const { query, setQuery, channelQuery, channels, resetChannels, usersChannels, userList } = useChannelQuery();
  const { messageQuery, messages, resetMessages, isReachingEnd, pagingMessageQuery } = useMessageQuery();
  const { putSearchHistory } = useSearchHistory();
  const { inputFocus, setInputFocus } = useInputFocus(textInputRef);
  const isWeb = Platform.OS === 'web';

  const route = useRoute();
  const navigation = useNavigation();
  const { id } = (route.params as RouteParams) || {};

  const routes = useMemo<(Route & { count: number })[]>(
    () => [
      {
        key: 'chat',
        title: 'チャット',
        count: messages?.length ?? 0,
      },
      {
        key: 'channel',
        title: 'チャンネル',
        count: channels?.length ?? 0,
      },
    ],
    [channels, messages]
  );

  useEffect(() => {
    if (id) {
      setShowTag(true);
      setSelectedChannel(usersChannels.find((obj) => obj.id === id));
    }
  }, [id, usersChannels]);

  const renderScene = useCallback(
    ({ route }: { route: Route }) => {
      switch (route.key) {
        case 'chat':
          return (
            <ChatSearchResults
              messages={messages}
              paginationSubmit={chatPaginationSubmit}
              isReachingEnd={isReachingEnd}
              query={query}
            />
          );
        case 'channel':
          return <ChannelSearchResults channels={channels} />;
        default:
          return null;
      }
    },
    [messages, channels, isReachingEnd]
  );

  const renderTabBar = (props: any) => {
    return (
      <TabBar<Route & { count: number }>
        {...props}
        index={index}
        setIndex={setIndex}
        indicatorStyle={{ backgroundColor: theme.colors.primary, marginHorizontal: theme.space.sm }}
        style={{ backgroundColor: theme.colors.surfaceBrightest }}
        renderLabel={({ route, focused }) => (
          <Text color={focused ? theme.colors.onSurface : theme.colors.onSurfaceBrightest} size="md" fontWeight="bold">
            {route.title}
            <Text>({route.count})</Text>
          </Text>
        )}
      />
    );
  };

  const chatPaginationSubmit = async () => {
    await pagingMessageQuery(query, targetUser, showTag ? selectedChannel?.cid : null, true);
  };

  const onSubmit = async (historyItem: string | undefined) => {
    if (!targetUser && query === '' && !historyItem) {
      return;
    }
    if (query !== historyItem) {
      reset();
    }
    if (historyItem) {
      setQuery(historyItem);
    }

    setIsSearching(true);

    const chat = messageQuery(historyItem ? historyItem : query, targetUser, showTag ? selectedChannel?.cid : null);

    const channel = channelQuery(historyItem ? historyItem : query);

    await Promise.all([chat, channel]);

    putSearchHistory(query);
  };

  const reset = () => {
    resetChannels();
    resetMessages();
  };

  const onPressUser = useCallback(
    (user: ChannelMemberResponse) => {
      setTargetUser(user);
      setQuery((prev) => prev.replace(/^from:[^\s]*((?![^\s])$|\s(?![^\s]))/i, ''));
    },
    [query]
  );

  const resetTag = () => {
    resetChannels();
    setShowTag(false);
    setInputFocus(true);
    setIsSearching(false);
    setSelectedChannel(undefined);
    // @ts-expect-error TS(2345): Argument of type '{ id: undefined; }' is not assignable to parameter of type 'undefined'.
    navigation.setParams({ id: undefined });
  };

  const styles = StyleSheet.create({
    container: {
      backgroundColor: theme.colors.surfaceBrightest,
      paddingTop: Platform.OS === 'android' ? StatusBar.currentHeight : 0,
    },
  });

  return (
    <>
      <SafeAreaView style={styles.container} />
      <KeyboardAvoidingView
        keyboardVerticalOffset={Platform.select({ ios: 0, android: 100 })}
        flex={1}
        behavior={Platform.OS === 'ios' ? 'padding' : 'height'}
      >
        <VStack backgroundColor="onPrimary" flex={1} space="2xs" paddingX="2xs" zIndex={1}>
          <HStack
            px="sm"
            pb="xs"
            pt={isWeb ? 'xs' : 'none'}
            color="onSurface"
            borderBottomWidth={isWeb ? 'none' : 'medium'}
            borderColor="outlineVariant"
          >
            {!isWeb ? (
              <Pressable onPress={() => navigation.goBack()}>
                <Icon size={10} color="onSurfaceBright" as={<MaterialIcons name="chevron-left" />} />
              </Pressable>
            ) : null}
            <Input
              placeholder="メッセージ、チャンネルを検索"
              variant="rounded"
              py="sm"
              px="2xs"
              fontSize="md"
              size="md"
              borderWidth="none"
              backgroundColor="surfaceBright"
              placeholderTextColor="onSurface"
              style={
                inputFocus && !isWeb
                  ? { flex: 0.9, color: theme.colors.onSurface }
                  : { flex: 1, color: theme.colors.onSurface }
              }
              InputLeftElement={
                <HStack alignItems="center" backgroundColor="surfaceBright">
                  <Icon m="2" ml="3" size="6" color="onSurfaceBright" as={<MaterialIcons name="search" />} />
                  {!!targetUser && (
                    <Box
                      px={2}
                      py={1}
                      my={1}
                      backgroundColor="primary.700"
                      rounded="lg"
                      mr="1"
                      maxW={`${Math.floor(width * 0.3)}px`}
                    >
                      <Text fontSize="sm" noOfLines={1} color="onSurface">
                        @{targetUser?.user?.name}
                      </Text>
                    </Box>
                  )}
                  {showTag ? (
                    <Pressable onPress={() => {}}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                        px={2}
                        py={1}
                        my={1}
                        borderWidth="medium"
                        borderColor="outline"
                        borderRadius="full"
                        backgroundColor={colorTheme.mono0}
                      >
                        <Text fontSize="xs" color="onSurface">
                          in:{selectedChannel?.data?.name}
                        </Text>
                        <Icon ml="1" size="3" color="onSurface" as={Ionicons} onPress={resetTag} name="close-outline" />
                      </Box>
                    </Pressable>
                  ) : null}
                </HStack>
              }
              InputRightElement={
                query ? (
                  <Pressable
                    onPress={() => {
                      setTargetUser(undefined);
                      textInputRef.current?.clear();
                      setQuery('');
                      setIsSearching(false);
                      reset();
                    }}
                  >
                    <HStack alignItems="center" backgroundColor="surfaceBright">
                      <Icon m="2" mr="3" size={6} color="onSurfaceBrightest" as={Ionicons} name="close-circle" />
                    </HStack>
                  </Pressable>
                ) : undefined
              }
              // @ts-expect-error
              onKeyPress={({ nativeEvent }) => {
                if (query === '' && nativeEvent.key === 'Backspace' && targetUser) {
                  setQuery(`from:@${targetUser?.user?.name}`);
                  setTargetUser(undefined);
                }
              }}
              // @ts-expect-error
              onChangeText={(text) => {
                setQuery(text);
                reset();
              }}
              returnKeyType="search"
              onSubmitEditing={() => onSubmit(undefined)}
              autoFocus
              ref={textInputRef}
              value={query}
              onFocus={() => {
                setInputFocus(true);
                setIsSearching(false);
              }}
              onBlur={() => {
                // web版で onBlur イベントを使うとクリアボタンが動作しない不具合が発生する
                // web版では inputFocus は参照していないためイベントをスキップして問題ない
                // https://github.com/u-motion/u-motion-apps/issues/1926
                if (!isWeb) {
                  setInputFocus(false);
                }
              }}
            />
            {inputFocus && !isWeb ? (
              <Box style={inputFocus ? { flex: 0.05 } : { flex: 0 }}>
                <Pressable
                  onPress={() => {
                    textInputRef.current?.clear();
                    setQuery('');
                    textInputRef.current?.blur();
                  }}
                >
                  <Icon m="2" mr="3" size={6} color="onSurfaceBright" as={<MaterialIcons name="close" />} />
                </Pressable>
              </Box>
            ) : null}
          </HStack>
          {!isSearching ? (
            <SearchHistory
              query={query}
              onSubmit={onSubmit}
              usersChannels={usersChannels}
              userList={userList}
              targetUser={targetUser}
              onPressUser={onPressUser}
            />
          ) : (
            <>
              {!isReachingEnd && messages.length === 0 ? (
                <Center flex={1}>
                  <Spinner />
                </Center>
              ) : (
                <TabView
                  navigationState={{
                    index,
                    routes,
                  }}
                  onIndexChange={setIndex}
                  renderScene={renderScene}
                  renderTabBar={renderTabBar}
                  initialLayout={initialLayout}
                  lazy
                />
              )}
            </>
          )}
        </VStack>
      </KeyboardAvoidingView>
    </>
  );
};
