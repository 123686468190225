import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useLinkTo } from '@react-navigation/native';
import { Audio } from 'expo-av';
import React from 'react';
import { usePostPushNotificationsTokenMutation } from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';
import { requestForToken, onMessageListener } from '~/lib/FirebaseMessagingWeb';
import { SettingsState, setEnableWebNotification, settingsSelector } from '~/slices/settingsSlice';
import { useAppDispatch, useAppSelector } from '~/store';

const NotificationSound = require('../../assets/sounds/notification_sound_default.wav');

export const PushNotificationHandler: React.FC = () => {
  const [postToken] = usePostPushNotificationsTokenMutation();
  const settings: SettingsState = useAppSelector(settingsSelector);
  const dispatch = useAppDispatch();
  const linkTo = useLinkTo();

  useDidMount(() => {
    const fn = async () => {
      if (Notification?.permission === 'default') {
        return;
      }

      const token = await requestForToken();

      if (token == null) {
        dispatch(setEnableWebNotification(false));
      } else {
        await postToken({ body: { push_token: token, push_provider_name: 'firebase' } });
      }

      if (!settings.enableWebNotification || token == null) {
        navigator.serviceWorker.getRegistration().then((registration) => {
          registration?.active?.postMessage({ chumlyWebPush: false });
        });
      }
    };
    fn();
  });

  useDidMount(() => {
    const handleMessage = (event: MessageEvent) => {
      if (event.data.type === 'webpush') {
        playSound();
      } else if (event.data.type === 'notificationclick' && event.data.linkPath) {
        linkTo(event.data.linkPath);
      }
    };
    navigator.serviceWorker.addEventListener('message', handleMessage, false);
    return () => {
      navigator.serviceWorker.removeEventListener('message', handleMessage, false);
    };
  });

  useDidUpdate(() => {
    if (settings.enableWebNotification) {
      requestForToken().then((token) => {
        if (token == null) {
          Alert.alert('ウェブ通知を有効にできません', 'ブラウザ・OSの設定で通知を許可してください', [{ text: 'OK' }]);
          dispatch(setEnableWebNotification(false));
        } else {
          navigator.serviceWorker.getRegistration().then((registration) => {
            registration?.active?.postMessage({ chumlyWebPush: true });
          });
        }
      });
    } else {
      navigator.serviceWorker.getRegistration().then((registration) => {
        registration?.active?.postMessage({ chumlyWebPush: false });
      });
    }
  }, [settings.enableWebNotification]);

  const playSound = async () => {
    const { sound } = await Audio.Sound.createAsync(NotificationSound);
    await sound.playAsync();
  };

  onMessageListener()
    .then(async (payload: any) => {
      if (settings.enableWebNotification) {
        try {
          await playSound();
        } catch (error) {
          console.error('notification sound error: ', error);
        }
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          return;
        }

        const notification = new Notification(payload?.data?.title, {
          body: payload?.data?.body,
          icon: '/app_icon.png',
          tag: 'webpush',
          renotify: true,
        });

        notification.addEventListener('click', (e) => {
          linkTo(payload?.data?.link_path || '/notifications');
        });
      }
    })
    .catch((err) => console.error('failed: ', err));
  return null;
};
