import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useState } from 'react';
import {
  useGetCurrentUserSettingQuery,
  usePutCurrentUserSettingMutation,
  useGetCurrentUserGroupsQuery,
  uFeedApi,
} from '~/api/uFeedApi';

export interface DashboardColumn {
  type:
    | 'activity_trend'
    | 'milkings_trend'
    | 'activity_graph'
    | 'milkings_graph'
    | 'chat_channel'
    | 'notifications'
    | 'add_columns';
  chatChannelId?: string;
  title: string;
}

const GRAPH_COLUMNS_ACTIVITY: DashboardColumn[] = [
  {
    type: 'notifications',
    title: '通知',
  },
  {
    type: 'activity_trend',
    title: '活動量トレンド',
  },
  {
    type: 'activity_graph',
    title: '活動量',
  },
];

const GRAPH_COLUMNS_MILKINGS: DashboardColumn[] = [
  {
    type: 'milkings_trend',
    title: '搾乳量トレンド',
  },
  {
    type: 'milkings_graph',
    title: '搾乳量',
  },
];

export const useDashboardColumns = () => {
  const userSettingsQuery = useGetCurrentUserSettingQuery();
  const [columns, setColumns] = useState<DashboardColumn[]>([]);
  const [columnsMap, setColumnsMap] = useState<Record<string, DashboardColumn>>({});
  const userSettings = userSettingsQuery.data?.details;
  const [put] = usePutCurrentUserSettingMutation();
  const groupsQuery = useGetCurrentUserGroupsQuery();
  const [getMilkingsStatisticTrigger, milkingsStatistic] =
    uFeedApi.endpoints.getGroupsByGroupIdMilkingsStatistic.useLazyQuery();
  const [isDairyFarm, setIsDairyFarm] = useState<boolean | undefined>(undefined);
  const [columnOptions, setColumnOptions] = useState<DashboardColumn[]>([]);

  useDidUpdate(
    () => {
      if (isDairyFarm === undefined) {
        return;
      }
      if (groupsQuery.isSuccess && groupsQuery.data?.length === 0) {
        return;
      }

      const userSettings = userSettingsQuery.data?.details;
      const options = [...GRAPH_COLUMNS_ACTIVITY, ...GRAPH_COLUMNS_MILKINGS];

      setColumnOptions(options);

      if (userSettings) {
        if (userSettings.dashboard_columns === undefined) {
          return;
        }
        setColumns(userSettings.dashboard_columns);
      } else {
        setColumns(options);
      }
    },
    [userSettingsQuery.data, isDairyFarm, groupsQuery.data],
    true
  );

  useDidUpdate(
    () => {
      if (groupsQuery.isSuccess) {
        getMilkingsStatisticTrigger({ groupId: groupsQuery.data?.[0]?.id ?? 0 })
          .then((res) => {
            if (res.data?.has_milking_data) {
              setIsDairyFarm(true);
            } else {
              setIsDairyFarm(false);
            }
          })
          .catch((err) => {
            console.error('milkingsStatistic', err);
          });
      }
    },
    [groupsQuery.data],
    true
  );

  useDidUpdate(
    () => {
      setColumnsMap(
        columns?.reduce((acc, column, index) => {
          return {
            ...acc,
            [`${index}`]: column,
          };
        }, {})
      );
    },
    [columns],
    true
  );

  const insertColumn = async (index: number, column: DashboardColumn) => {
    const newColumns = [...columns.slice(0, index), column, ...columns.slice(index)];
    setColumns(newColumns);
    await put({
      body: {
        user_setting: {
          details: {
            ...userSettings,
            dashboard_columns: newColumns,
          },
        },
      },
    }).unwrap();
  };

  const removeColumn = async (index: number) => {
    const newColumns = columns.filter((_, i) => i != index);
    setColumns(newColumns);
    await put({
      body: {
        user_setting: {
          details: {
            ...userSettings,
            dashboard_columns: newColumns,
          },
        },
      },
    }).unwrap();
  };

  const updateColumn = async (newColumn: DashboardColumn[]) => {
    await put({
      body: {
        user_setting: {
          details: {
            ...userSettings,
            dashboard_columns: newColumn,
          },
        },
      },
    }).unwrap();
  };

  return {
    columns,
    columnsMap,
    insertColumn,
    removeColumn,
    setColumns,
    updateColumn,
    columnOptions,
    isDairyFarm,
  };
};
