import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { HStack, VStack, Text, ScrollView, Pressable } from '@gluestack-ui/themed-native-base';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useGetCurrentUserGroupsQuery } from '~/api/uFeedApi';
import { ActivityDashboard } from '~/components/ActivityDashboard';
import { FormSelectPopover } from '~/components/builder/FormSelectPopover';
import { SortableItemContext } from '~/components/SortableItem';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { useDashboardColumns } from '~/hooks/useDashboardColumns';
import CloseIcons from '~/icons/CloseIcons';
import DragIcons from '~/icons/DragIcons';

export const ActivityTrendPanelColumn = ({
  showHeader = true,
  width = 500,
}: {
  showHeader?: boolean;
  width?: number;
}) => {
  const [groupsOption, setGroupsOption] = useState<{ label: string; value: number }[]>([]);
  const [farmId, setFarmId] = useState<number>(0);
  const [groupId, setGroupId] = useState<number | undefined>(undefined);
  const [chatChannelId, setChatChannelId] = useState<string | undefined>(undefined);
  const groupsQuery = useGetCurrentUserGroupsQuery();
  const { removeColumn } = useDashboardColumns();

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { attributes, listeners, ref, index } = useContext(SortableItemContext);

  const { control, setValue, watch } = useForm({
    defaultValues: {
      groupId: undefined as number | undefined,
    },
    mode: 'onChange',
  });

  const handleClose = useCallback(() => {
    if (typeof index === 'number') {
      removeColumn(index);
    }
  }, [index, removeColumn]);

  useEffect(() => {
    const subscription = watch((value) => setGroupId(value?.groupId));
    return subscription.unsubscribe;
  }, [watch]);

  useDidUpdate(
    () => {
      if (groupsQuery.data) {
        setGroupsOption(groupsQuery.data?.map((group) => ({ label: group.name, value: group.id || 0 })) || []);
        setValue('groupId', groupsQuery.data[0].id);
      }
    },
    [groupsQuery.data],
    true
  );

  useDidUpdate(
    () => {
      if (groupId) {
        const group = groupsQuery.data?.find((group) => group.id === groupId);
        if (group) {
          setFarmId(group.farm_id || 0);
          setChatChannelId(group.chat_channel_id);
        }
      }
    },
    [groupId],
    true
  );

  return (
    <VStack width={width} height="100%" flexGrow={0} backgroundColor="surfaceBrightest">
      {showHeader && (
        <HStack
          px="md"
          py="xl"
          justifyContent="space-between"
          alignItems="center"
          borderBottomWidth={gluestackUIConfig.tokens.borderWidths.medium}
          borderColor="outlineVariant"
        >
          <HStack>
            <Pressable {...attributes} {...listeners} ref={ref}>
              <DragIcons />
            </Pressable>
            <Text fontSize="xl" fontWeight="bold" color="onSurface">
              活動量トレンド
            </Text>
          </HStack>
          <Pressable onPress={handleClose}>
            <CloseIcons />
          </Pressable>
        </HStack>
      )}

      {groupsOption.length > 0 && (
        <VStack padding="md">
          <FormSelectPopover
            type="select"
            control={control}
            name="groupId"
            options={groupsOption}
            label="グループ"
            defaultValue={{ groupId: '' }}
            wrapperStyle={{ zIndex: 1000 }}
          />
        </VStack>
      )}

      {!!(farmId && groupId) && (
        <ScrollView>
          <ActivityDashboard
            chatChannelId={chatChannelId}
            endDate={new Date()}
            farmId={farmId}
            groupId={groupId}
            range={30}
            padding="md"
            width={width}
          />
        </ScrollView>
      )}
    </VStack>
  );
};
