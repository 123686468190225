import { VStack, Text } from '@gluestack-ui/themed-native-base';
import React from 'react';

import { ActivityLineChart } from '../ActivityLineChart';

import { useGetGroupsByGroupIdQuery } from '~/api/uFeedApi';
import { DetailList } from '~/components/builder/DetailList';
import { DetailListItem } from '~/components/builder/DetailListItem';
import { DateUtil } from '~/utils/DateUtils';

interface Props {
  farmId: number;
  groupId: number;
  endDate: string;
  range?: number;
  type?: 'line' | 'stack';
}

export const PreviewActivityChart: React.FC<Props> = React.memo(
  ({ farmId, groupId, endDate, range = '30', type = 'line' }) => {
    const group = useGetGroupsByGroupIdQuery({
      groupId,
    });

    if (group.isLoading || group.isError) {
      return null;
    }

    return (
      <VStack
        marginTop={1}
        marginBottom={2}
        space={0}
        backgroundColor="gray.50"
        borderRadius={6}
        borderWidth={1}
        borderColor="onPrimary"
        overflow="hidden"
      >
        <DetailList space={2} padding={2}>
          <Text marginBottom={2} color="onPrimary">
            活動量グラフ
          </Text>
          <DetailListItem title="グループ" value={group.data?.name || ''} color="onPrimary" />
          <DetailListItem
            title="日付"
            value={`${endDate[0]}${endDate[1]}${endDate[2]}${endDate[3]}/${endDate[4]}${endDate[5]}/${endDate[6]}${endDate[7]}`}
            color="onPrimary"
          />
          <DetailListItem title="グループ" value={group.data?.name || ''} color="onPrimary" />
          <DetailListItem title="期間" value={`${range}日`} color="onPrimary" />
          <DetailListItem
            title="グラフ種別"
            value={ActivityGraphType.find((graphType) => graphType.key == type)?.value}
            color="onPrimary"
          />
        </DetailList>
        {type == 'line' ? (
          <ActivityLineChart
            farmId={farmId}
            groupId={groupId}
            endDate={DateUtil.dateHourToDate(endDate)}
            // @ts-expect-error
            range={range}
            paddingX={4}
            isPreview
          />
        ) : null}
      </VStack>
    );
  }
);
const ActivityGraphType: { key: 'line' | 'stack'; value: string }[] = [
  { key: 'line', value: '変化量' },
  { key: 'stack', value: '内訳' },
];
