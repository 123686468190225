import { Box, HStack, VStack, Text, Pressable, Image } from '@gluestack-ui/themed-native-base';
import { ImageEditor } from 'expo-image-editor';
import { manipulateAsync, SaveFormat } from 'expo-image-manipulator';
import * as ImagePicker from 'expo-image-picker';
import { useState, useCallback } from 'react';
import { DefaultStreamChatGenerics } from 'stream-chat-react';
import { usePatchChatChannelsByIdMutation } from '~/api/uFeedApi';
import { Alert } from '~/components/Alert';
import { errorMessageBuilder, BaseFormBuilder } from '~/components/builder';
import type { FieldsProps } from '~/components/builder';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import type { FC } from 'react';
import type { Channel } from 'stream-chat';

const fields: FieldsProps = [
  {
    type: 'text',
    key: 'name',
    label: '名称',
    rules: {
      required: {
        value: true,
        message: '名称を入力してください',
      },
    },
  },
  {
    type: 'textarea',
    key: 'description',
    label: '概要',
  },
];

const ChannelInfoEdit: FC<{
  onClose: () => void;
  appChannel: Channel<DefaultStreamChatGenerics> | undefined;
}> = ({ appChannel, onClose }) => {
  const [shouldShowEditor, setShouldShowEditor] = useState(false);
  const [imageUri, setImageUri] = useState('');
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [patchAvatarChannel] = usePatchChatChannelsByIdMutation();
  const selectPhoto = useCallback(async () => {
    const response = await ImagePicker.requestMediaLibraryPermissionsAsync();

    if (response.granted) {
      const pickerResult = await ImagePicker.launchImageLibraryAsync();

      if (!pickerResult.canceled) {
        setImageUri(pickerResult.assets[0].uri);
        setShouldShowEditor(true);
      }
    } else {
      Alert.alert('システムの設定からこのアプリケーションが写真にアクセスする許可をしてください。', '', []);
    }
  }, []);

  const onEditingComplete = useCallback(
    async (cropResult: any) => {
      const formData = new FormData();
      const resizeResult = await manipulateAsync(
        cropResult.uri,
        [
          {
            // cannot accept bigger than 5kb
            resize: {
              width: 400,
              height: 400,
            },
          },
        ],
        { compress: 1, format: SaveFormat.JPEG }
      );

      const response = await fetch(resizeResult.uri);
      const blob = await response.blob();
      formData.append('channel[image]', blob);
      // @ts-expect-error TS(2741): Property 'channel' is missing in type 'FormData' but ... Remove this comment to see the full error message
      patchAvatarChannel({ id: appChannel?.data?.id as string, body: formData });
    },
    [appChannel?.data?.id]
  );

  const onSubmit = useCallback(
    async (data: { name: string; description: string }) => {
      setErrorMessages([]);
      setIsUpdating(true);

      try {
        await appChannel?.updatePartial({
          set: {
            name: data.name,
            channel_overview: data.description,
          },
        });
        setIsUpdating(false);
        onClose();
      } catch (error) {
        setIsUpdating(false);
        setErrorMessages(errorMessageBuilder(error, fields));
        console.error('update error: ', error);
      }
    },
    [setErrorMessages, onClose]
  );

  return (
    <>
      <VStack
        width="full"
        maxWidth="300px"
        borderLeftWidth={gluestackUIConfig.tokens.borderWidths.medium}
        borderColor="outlineVariant"
        gap="md"
      >
        <HStack
          p="md"
          justifyContent="flex-start"
          alignItems="center"
          gap="2xs"
          borderBottomWidth="medium"
          borderColor="outlineVariant"
        >
          <Pressable onPress={onClose}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="Icons">
                <path
                  id="arrow_back_ios"
                  d="M3.54922 12.0001L10.8992 19.3501C11.1492 19.6001 11.2701 19.8918 11.2617 20.2251C11.2534 20.5584 11.1242 20.8501 10.8742 21.1001C10.6242 21.3501 10.3326 21.4751 9.99922 21.4751C9.66589 21.4751 9.37422 21.3501 9.12422 21.1001L1.42422 13.4251C1.22422 13.2251 1.07422 13.0001 0.974219 12.7501C0.874219 12.5001 0.824219 12.2501 0.824219 12.0001C0.824219 11.7501 0.874219 11.5001 0.974219 11.2501C1.07422 11.0001 1.22422 10.7751 1.42422 10.5751L9.12422 2.87511C9.37422 2.62511 9.67005 2.50428 10.0117 2.51261C10.3534 2.52094 10.6492 2.65011 10.8992 2.90011C11.1492 3.15011 11.2742 3.44178 11.2742 3.77511C11.2742 4.10844 11.1492 4.40011 10.8992 4.65011L3.54922 12.0001Z"
                  fill="#737373"
                />
              </g>
            </svg>
          </Pressable>
          <Text color="onSurface" fontSize="xl" fontWeight="bold">
            ルーム編集
          </Text>
        </HStack>
        <HStack justifyContent="center">
          <Pressable onPress={selectPhoto} position="relative">
            {appChannel?.data?.image ? (
              <Image
                source={{ uri: appChannel?.data?.image }}
                alt="プロフィール画像"
                width={20}
                height={20}
                borderRadius="full"
              />
            ) : (
              <Box width={20} height={20} backgroundColor="onSurfaceBright" borderRadius="full" />
            )}
            <Box position="absolute" right="0" bottom="0" backgroundColor="primary" p="2xs" borderRadius="full">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_331_8831)">
                  <path
                    d="M10 12.5C11.3807 12.5 12.5 11.3807 12.5 10C12.5 8.61929 11.3807 7.5 10 7.5C8.61929 7.5 7.5 8.61929 7.5 10C7.5 11.3807 8.61929 12.5 10 12.5Z"
                    fill="white"
                  />
                  <path
                    d="M16.6665 3.33317H14.0248L12.9915 2.20817C12.6832 1.8665 12.2332 1.6665 11.7665 1.6665H8.23317C7.7665 1.6665 7.3165 1.8665 6.99984 2.20817L5.97484 3.33317H3.33317C2.4165 3.33317 1.6665 4.08317 1.6665 4.99984V14.9998C1.6665 15.9165 2.4165 16.6665 3.33317 16.6665H16.6665C17.5832 16.6665 18.3332 15.9165 18.3332 14.9998V4.99984C18.3332 4.08317 17.5832 3.33317 16.6665 3.33317ZM9.99984 14.1665C7.69984 14.1665 5.83317 12.2998 5.83317 9.99984C5.83317 7.69984 7.69984 5.83317 9.99984 5.83317C12.2998 5.83317 14.1665 7.69984 14.1665 9.99984C14.1665 12.2998 12.2998 14.1665 9.99984 14.1665Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_331_8831">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </Box>
          </Pressable>
        </HStack>
        <Box px="md">
          <BaseFormBuilder
            // @ts-expect-error TS(2322): Type 'UseQueryHookResult<QueryDefinition<GetCurren... Remove this comment to see the full error message
            queryResult={appChannel}
            defaultValues={{
              name: appChannel?.data?.name,
              description: appChannel?.data?.channel_overview,
            }}
            fields={fields}
            onSubmit={onSubmit}
            onCancel={onClose}
            errorMessage={errorMessages}
            isLoading={isUpdating}
          />
        </Box>
      </VStack>
      <ImageEditor
        visible={shouldShowEditor}
        onCloseEditor={() => setShouldShowEditor(false)}
        imageUri={imageUri}
        fixedCropAspectRatio={1}
        lockAspectRatio
        minimumCropDimensions={{
          width: 100,
          height: 100,
        }}
        onEditingComplete={onEditingComplete}
        throttleBlur={false}
        mode="crop-only"
      />
    </>
  );
};

export default ChannelInfoEdit;
