import { Ionicons } from '@expo/vector-icons';
import { Button, Divider, VStack, Box, Text, Input, HStack, Icon, Pressable } from '@gluestack-ui/themed-native-base';
import { useNavigation } from '@react-navigation/native';
import { FlashList } from '@shopify/flash-list';
import debounce from 'lodash/debounce';
import * as React from 'react';
import { Dimensions, Platform } from 'react-native';

import { ListItem } from '~/components';
import { BaseScreenBuilder } from '~/components/builder';
import { ScreenWidthModal } from '~/components/ScreenWithModal';
import { gluestackUIConfig } from '~/config/gluestack-ui.config';
import { getData, clearData } from '~/utils/multiSelectInputDataHelper';
import { SelectedIcon } from './selectedIcon';

export interface Data {
  name: string;
  id: string | number;
}

export type ParamList = {
  data: Data[];
  selectedItems: string[] | number[];
  unchangeableItems?: string[] | number[];
  onConfirm: (result: string[] | number[]) => void;
  onQuery?: (query: string) => void;
  onCancel?: () => void;
};

export const MultiSelectScreen: React.FC = () => {
  const navigation = useNavigation();
  const params = getData();

  const [selected, setSelected] = React.useState(params.selectedItems ? params.selectedItems : []);
  const [data, setData] = React.useState(params.data ? params.data : []);
  const inputRef = React.useRef(null);
  const windowHeight = Dimensions.get('window').height;

  const searchSubmit = async (word: string) => {
    const result = await params?.onQuery?.(word);
    // @ts-expect-error TS(2345): Argument of type 'void' is not assignable to param... Remove this comment to see the full error message
    setData(result);
  };

  const resetQuery = React.useCallback(async () => {
    // @ts-expect-error TS(2531): Object is possibly 'null'.
    inputRef.current.clear();
    searchSubmit('');
  }, []);

  if (Platform.OS === 'web') {
    const pathNameRef = React.useRef(location.pathname);
    React.useEffect(() => {
      if (!pathNameRef.current) {
        return;
      }
      const handleBeforeUnload = () => {
        if (pathNameRef.current.startsWith('/dm/')) {
          // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
          navigation.navigate('DirectMessageList');
        }
        return undefined;
      };

      window.addEventListener('beforeunload', handleBeforeUnload);

      return () => {
        if (pathNameRef.current) {
          window.removeEventListener('beforeunload', handleBeforeUnload);
        }
      };
    }, [navigation]);
  }

  const onSelectAll = React.useCallback(() => {
    const ids = data.map((item) => item.id);
    // @ts-expect-error TS(2345): Argument of type '(string | number)[]' is not assi... Remove this comment to see the full error message
    setSelected(ids);
  }, [data]);
  const onChangeSearchInput = debounce(async (word: string) => {
    searchSubmit(word);
  }, 1000);

  React.useEffect(() => {
    return () => {
      clearData();
    };
  }, []);

  return (
    <ScreenWidthModal title="選択">
      <BaseScreenBuilder title="選択">
        <VStack height={windowHeight - 200} space={2}>
          {params?.onQuery ? (
            <HStack space={4}>
              <Input
                ref={inputRef}
                placeholder="検索"
                onChangeText={onChangeSearchInput}
                returnKeyType="search"
                flexGrow={1}
                autoFocus
                InputRightElement={
                  <Pressable onPress={resetQuery}>
                    <Icon
                      as={Ionicons}
                      name="close-outline"
                      size="sm"
                      paddingRight={gluestackUIConfig.tokens.space['3xs']}
                      color="onSurface"
                    />
                  </Pressable>
                }
                placeholderTextColor={gluestackUIConfig.tokens.colors.onSurface}
                borderColor="outline"
              />
            </HStack>
          ) : null}
          <HStack justifyContent="flex-end">
            <Button
              variant="link"
              onPress={onSelectAll}
              alignSelf="flex-end"
              _text={{ color: gluestackUIConfig.tokens.colors.onSurfaceBrightest }}
            >
              すべて選択
            </Button>
          </HStack>
          <FlashList
            data={data}
            estimatedItemSize={50}
            ItemSeparatorComponent={Divider}
            nestedScrollEnabled
            extraData={{
              onPressHandler: (id: any) => {
                selected.find((select: any) => select == id)
                  ? // @ts-expect-error TS(2349): This expression is not callable.
                    setSelected(selected.filter((select: any) => select !== id))
                  : setSelected([...selected, id]);
              },
              selected: (id: any) => {
                return !!selected.find((select: any) => select == id);
              },
            }}
            renderItem={({ item, extraData }) => {
              return (
                <Box padding={3} marginRight={4}>
                  <ListItem
                    title={item.name}
                    onPress={() =>
                      // @ts-expect-error TS(2345): Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
                      params.unchangeableItems?.includes(item.id) ? null : extraData.onPressHandler(item.id)
                    }
                    right={
                      // @ts-expect-error TS(2345): Argument of type 'string | number' is not assignab... Remove this comment to see the full error message
                      params.unchangeableItems?.includes(item.id) ? (
                        <>
                          <Text color="onSurface" paddingRight={1}>
                            変更不可
                          </Text>
                          <SelectedIcon selected={extraData.selected(item.id)} />
                        </>
                      ) : (
                        <SelectedIcon selected={extraData.selected(item.id)} />
                      )
                    }
                  />
                </Box>
              );
            }}
          />
          <Divider my="xs" />
          <VStack space="xs">
            <Button
              onPress={() => {
                navigation.goBack();
              }}
              _text={{ color: gluestackUIConfig.tokens.colors.onSurfaceBright }}
              variant="outline"
              borderColor="outline"
            >
              キャンセル
            </Button>
            <Button
              onPress={() => {
                params?.onConfirm(selected);
                navigation.goBack();
              }}
              _text={{ color: gluestackUIConfig.tokens.colors.inverseOnSurface }}
              backgroundColor="primary"
            >
              選択
            </Button>
          </VStack>
        </VStack>
      </BaseScreenBuilder>
    </ScreenWidthModal>
  );
};
