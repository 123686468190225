import { Ionicons } from '@expo/vector-icons';
import { Text, HStack, VStack, Icon, Image } from '@gluestack-ui/themed-native-base';

// @ts-expect-error TS(2724): '"~/api/uFeedApi"' has no exported member named 'U... Remove this comment to see the full error message
import type { Users } from '~/api/uFeedApi';

interface Props {
  user: Users;
  displayAccount?: boolean;
}

export const UserDetails: React.FC<Props> = ({ user, displayAccount = true }) => {
  const value = displayAccount ? `${user.name}（${user.account.name}）` : user.name;
  return (
    <VStack>
      <HStack alignItems="center" space="2xs">
        {user.avatar ? (
          <Image source={{ uri: user.avatar }} size="xs" alt="ユーザー画像" borderRadius={20} />
        ) : (
          <Icon as={Ionicons} name="person-circle-outline" size="md" color="onSurface" />
        )}
        <Text fontSize="md" bold color="onSurface">
          {value}
        </Text>
      </HStack>
    </VStack>
  );
};
