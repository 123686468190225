import { HStack, VStack, Text, Heading } from '@gluestack-ui/themed-native-base';
import { StyleSheet, View, Dimensions } from 'react-native';

import { FarmDetail } from '~/api/uFeedApi';
//import MapView, { Marker, LatLng, Region, PROVIDER_GOOGLE } from 'react-native-maps';

interface Props {
  item: FarmDetail;
}

export const FarmDetailCard: React.FC<Props> = ({ item }) => {
  // @ts-expect-error TS(2304): Cannot find name 'Region'.
  const region: Region = {
    latitude: item.latitude,
    longitude: item.longitude,
    latitudeDelta: 0.046,
    longitudeDelta: 0.026,
  };

  // @ts-expect-error TS(2304): Cannot find name 'LatLng'.
  const coordinate: LatLng = {
    latitude: item.latitude,
    longitude: item.longitude,
  };

  return (
    <VStack space={4}>
      <VStack>
        <Heading size="xs" color="onSurface">
          {item.label}
        </Heading>
      </VStack>
      <VStack>
        <HStack space={3}>
          <HStack>
            {coordinate.latitude ? (
              <View style={styles.container} />
            ) : (
              <VStack
                width={Dimensions.get('window').width / 3}
                height={Dimensions.get('window').height / 10}
                borderColor="onSurfaceBright"
                borderWidth={1}
                textAlign="center"
              >
                <Text color="onSurfaceBright">地図を設定してください</Text>
              </VStack>
            )}
          </HStack>
          <HStack>
            <VStack>
              <VStack>
                <Text fontWeight="bold" color="onSurfaceBright">
                  住所
                </Text>
                <Text color="onSurface">{item.address}</Text>
              </VStack>
              <VStack>
                <Text fontWeight="bold" color="onSurfaceBright">
                  電話番号
                </Text>
                <Text color="onSurface">{item.phone_number}</Text>
              </VStack>
            </VStack>
          </HStack>
        </HStack>
      </VStack>
    </VStack>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
  },
  map: {
    width: Dimensions.get('window').width / 3,
    height: Dimensions.get('window').height / 10,
  },
});
