import { useDidMount, useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import {
  Box,
  HStack,
  Text,
  Skeleton,
  VStack,
  Button,
  Switch,
  ScrollView,
  useTheme,
} from '@gluestack-ui/themed-native-base';
import { StyledProps, View } from 'native-base';
import * as React from 'react';
import { useState, useMemo } from 'react';

import { useGroupMilkAmountByRange } from '~/hooks/useGroupMilkAmountByRange';
import { VictoryLine, VictoryChart, VictoryTheme, VictoryAxis, VictoryScatter, VictoryLegend } from '~/lib/Victory';
import { DateUtil } from '~/utils/DateUtils';

import { GraphAlertBox } from './GraphAlertBox';
import { TableChart } from './TableChart';

interface Props {
  endDate: Date;
  range: number;
  showTable: boolean;
  chatChannelId?: string;
  groupId?: number;
  farmId?: number;
  queryParameters?: {
    endDate: string;
    range: number;
    mode: 'table' | 'graph';
  };
  isPreview?: boolean;
  width?: number;
  height?: number;
  barWidth?: number;
  onTypeChange?: (type: string) => void;
  shareButton?: React.ReactElement;
}

export const MilkAmountByCowGroupChart: React.FC<Props & StyledProps> = React.memo(
  ({
    endDate,
    range,
    showTable,
    groupId,
    queryParameters,
    isPreview = false,
    width = 400,
    height = 350,
    shareButton,
  }) => {
    const { setEndDate, setRange, milkingsByGroup, preMilkingsByGroup, milkingsByGroupMA } = useGroupMilkAmountByRange(
      groupId || 0,
      endDate,
      range
    );
    const [shouldShowPrev, setShouldShowPrev] = useState(false);
    const { colors } = useTheme();

    useDidUpdate(
      () => {
        setEndDate(endDate);
      },
      [endDate],
      true
    );

    useDidUpdate(
      () => {
        setRange(range);
      },
      [range],
      true
    );

    const tableData = useMemo(
      () =>
        milkingsByGroupMA.data?.reduce((acc, group) => {
          group.data?.forEach((datum) => {
            const date = DateUtil.toMMDD(datum.date);
            const amount = datum.amount;
            const group_name = group.um_cattle_group_name;

            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            if (!acc[date]) {
              // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              acc[date] = { date };
            }

            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            acc[date][group_name] = amount || 0;
          });

          return acc;
        }, {}),
      [milkingsByGroupMA.data]
    );

    useDidUpdate(() => {
      queryParameters?.endDate && setEndDate(DateUtil.dateHourToDate(queryParameters?.endDate));
      queryParameters?.range && setRange(queryParameters?.range);
    }, [queryParameters]);

    useDidMount(() => {
      queryParameters?.endDate && setEndDate(DateUtil.dateHourToDate(queryParameters?.endDate));
      queryParameters?.range && setRange(queryParameters?.range);
    });

    const stackedBarChart = React.useMemo(
      () => (
        <VictoryChart
          width={isPreview ? 220 : width}
          height={isPreview ? 182 : height}
          theme={VictoryTheme.material}
          domainPadding={{ x: [10, 10], y: [0, 0] }}
          padding={isPreview ? { top: 20, bottom: 40, left: 40, right: 20 } : undefined}
        >
          <VictoryAxis
            fixLabelOverlap
            tickFormat={(x, i) => {
              const date = new Date(x);
              return `${date.getMonth() + 1}/${date.getDate()}`;
            }}
          />
          <VictoryAxis dependentAxis tickFormat={(y) => y} />
          {isPreview ? null : (
            <VictoryLegend
              orientation="horizontal"
              x={52}
              gutter={60}
              data={[
                { name: '日毎の搾乳量', symbol: { fill: '#aaa' } },
                { name: '5日間移動平均', symbol: { fill: '#aaa', type: 'minus' } },
              ]}
            />
          )}
          {shouldShowPrev
            ? preMilkingsByGroup.data?.map((milking, index) => {
                return (
                  <VictoryLine
                    key={index.toString()}
                    data={
                      milking?.data?.map((cur) => {
                        const utcDate = DateUtil.dateHourToDate(cur.date, 'yyyy-MM-dd');
                        return {
                          x: DateUtil.toYYYYMMDD(DateUtil.addDays(utcDate, range), '-'),
                          y: cur.amount || 0,
                        };
                      }) ?? []
                    }
                    style={{
                      data: {
                        stroke: colors.um.cowGroups[index],
                        strokeOpacity: 0.7,
                        strokeDasharray: 8,
                        strokeWidth: isPreview ? 2 : 4,
                      },
                    }}
                  />
                );
              })
            : null}
          {milkingsByGroupMA.data?.map((milking, index) => {
            return (
              <VictoryLine
                key={index.toString()}
                data={
                  milking?.data?.map((cur) => {
                    return {
                      x: cur.date,
                      y: cur.amount || 0,
                    };
                  }) ?? []
                }
                style={{
                  data: { stroke: colors.um.cowGroups[index], strokeWidth: isPreview ? 2 : 4 },
                }}
              />
            );
          })}
          {range === 30
            ? milkingsByGroup.data?.map((milking, index) => (
                <VictoryScatter
                  key={index.toString()}
                  data={
                    milking?.data?.map((cur) => {
                      return {
                        x: cur.date,
                        y: cur.amount || 0,
                      };
                    }) ?? []
                  }
                  size={3}
                  style={{
                    data: { fill: colors.um.cowGroups[index], opacity: 0.5 },
                  }}
                />
              ))
            : null}
        </VictoryChart>
      ),
      [milkingsByGroup, preMilkingsByGroup, shouldShowPrev, milkingsByGroupMA]
    );

    if (milkingsByGroup?.isLoading) {
      return <Skeleton height={isPreview ? 216 : height} />;
    }

    if (milkingsByGroup.isError || preMilkingsByGroup.isError || milkingsByGroupMA.isError) {
      return (
        <GraphAlertBox
          onPress={() => {
            milkingsByGroup.refetch();
            preMilkingsByGroup.refetch();
            milkingsByGroupMA.refetch();
          }}
        />
      );
    }

    if (isPreview) {
      return <VStack>{stackedBarChart}</VStack>;
    }

    return (
      <View w={width}>
        <ScrollView horizontal nestedScrollEnabled>
          <HStack p="xs">
            {milkingsByGroup.data?.map((byGroup, index) => (
              <Button
                size="sm"
                borderRadius="full"
                m="2xs"
                backgroundColor={colors.um.cowGroups[index]}
                key={byGroup?.um_cattle_group_name}
                onPress={() => {}}
                _text={{ fontWeight: 'bold', color: 'onSurface' }}
              >
                {byGroup?.um_cattle_group_name}
              </Button>
            ))}
          </HStack>
        </ScrollView>

        <Box paddingX="auto">{stackedBarChart}</Box>

        {false && (
          <HStack alignItems="center" justifyContent="flex-end" space={2} paddingX={4}>
            <Text>前の期間と比較</Text>
            {/* @ts-expect-error */}
            <Switch value={shouldShowPrev} onToggle={(newValue) => setShouldShowPrev(newValue)} size="sm" />
          </HStack>
        )}
        <TableChart
          // @ts-expect-error TS(2322): Type '(string | number | { date?: string | undefin... Remove this comment to see the full error message
          data={tableData && Object.values(tableData)}
          width={width}
          headers={['日付'].concat(
            milkingsByGroupMA.data
              ? milkingsByGroupMA.data.map((milking) =>
                  milking.um_cattle_group_name ? milking.um_cattle_group_name : ''
                )
              : []
          )}
          columns={['date'].concat(
            milkingsByGroupMA.data
              ? milkingsByGroupMA.data?.map((milking) =>
                  milking.um_cattle_group_name ? milking.um_cattle_group_name : ''
                )
              : []
          )}
          padding={4}
          showTable={showTable}
          widthArr={new Array<number>(
            ['日付'].concat(
              milkingsByGroupMA.data
                ? milkingsByGroupMA.data.map((milking) =>
                    milking.um_cattle_group_name ? milking.um_cattle_group_name : ''
                  )
                : []
            ).length
          ).fill(50)}
        />
        {shareButton ? shareButton : null}
      </View>
    );
  }
);
