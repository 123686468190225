import { Ionicons } from '@expo/vector-icons';
import { Pressable, Icon, Center, Spinner, Text } from '@gluestack-ui/themed-native-base';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useNavigation, useLinkTo } from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { StatusBar } from 'expo-status-bar';
import { useState, useEffect } from 'react';
import { Platform } from 'react-native';
import NetworkLogger from 'react-native-network-logger';

import { useAuth } from '~/hooks/useAuth';
import { UmotionLoginModalScreen, UmotionResetPasswordScreen } from '~/packages/u-motion-login/screens/';

import { ChatApplication } from './ChatApplication';

const Stack = createNativeStackNavigator();

export const renderHeaderLeft = (navigation: any, routeName: string) => {
  // issue421  navigation.canGoBackが正しく動作しないためindexを使用
  return navigation.getState().index !== 0 ? (
    <Pressable alignItems="center" display="flex" flexDirection="row" onPress={navigation.goBack} paddingRight={4}>
      <Icon as={Ionicons} name="chevron-back-outline" size="xl" color="onSurfaceBright" />
      <Text color="onSurfaceBright" fontSize="lg">
        戻る
      </Text>
    </Pressable>
  ) : Platform.OS === 'web' ? null : (
    <Text fontSize="xl" fontWeight="bold" color="onSurface">
      {routeName}
    </Text>
  );
};

const getPersistenceFunctions = () => {
  return __DEV__
    ? {
        persistNavigationState: async (navState: any) => {
          try {
            await AsyncStorage.setItem('ReactNavigatorPersistenceKey', JSON.stringify(navState));
          } catch (err) {
            // handle the error according to your needs
          }
        },
        loadNavigationState: async () => {
          const jsonString = await AsyncStorage.getItem('ReactNavigatorPersistenceKey');
          // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
          return JSON.parse(jsonString);
        },
      }
    : undefined;
};

export const Routes = () => {
  const { hasSession, hasCreatedUser } = useAuth();
  const [redirectURL, setRedirectURL] = useState<string>();
  const linkTo = useLinkTo();

  useEffect(() => {
    if (!hasSession && !redirectURL && Platform.OS === 'web') {
      setRedirectURL(`${location.pathname}${location.search}`);
    }
    if (hasSession && redirectURL) {
      setRedirectURL(undefined);
      try {
        linkTo(redirectURL);
      } catch (e) {
        // 存在しないURLの場合はデフォルト画面(/chat)にリダイレクト
        linkTo('/chat');
      }
    }
  }, [hasSession]);

  return (
    <>
      <StatusBar style="dark" />
      {!hasSession ? (
        <Stack.Navigator
          screenOptions={({ navigation }) => ({
            headerBackTitle: '戻る',
            headerTitleStyle: {
              fontSize: 18,
            },
          })}
          {...getPersistenceFunctions()}
        >
          <Stack.Screen
            name="UmotionLogin"
            component={UmotionLoginModalScreen}
            options={{ headerShown: false, title: 'U-motion IDでログイン' }}
          />
          <Stack.Screen
            name="UmotionResetPassword"
            component={UmotionResetPasswordScreen}
            options={{ title: 'パスワード・リセット', headerShown: false }}
          />
          <Stack.Screen
            name="NetworkLog"
            component={NetworkLogger}
            options={{
              title: 'ネットワーク・ログ',
              headerShown: false,
            }}
          />
        </Stack.Navigator>
      ) : !hasCreatedUser ? (
        <Center flex={1}>
          <Spinner />
        </Center>
      ) : (
        <ChatApplication />
      )}
    </>
  );
};

export const useUniversalRouter = () => {
  const navigation = useNavigation();
  const navigate = (props: any) => {
    // @ts-expect-error TS(2769): No overload matches this call.
    navigation?.navigate(props);
  };

  const params = {};

  return {
    navigate,
    params,
  };
};
