import { useDidUpdate } from '@better-typed/react-lifecycle-hooks';
import { useRoute } from '@react-navigation/native';
import * as React from 'react';

import { ChatChannelListWeb } from '~/components/ChatChannelListWeb';
import { ShowPinMessagesProvider } from '~/contexts/PinMessageContext';
import { useStreamChatContext } from '~/contexts/StreamChatContext';
import { usePinnedChannels } from '~/hooks';

type ChannelListCategory = 'my' | 'pinned' | 'others';

interface Props {
  type: ChannelListCategory;
}

export const ChatChannelListScreen: React.FC<Props> = React.memo(({ type }) => {
  const { chatUserId, isUserConnected } = useStreamChatContext();
  const { pinnedChannelsQuery } = usePinnedChannels();
  const route = useRoute();

  useDidUpdate(
    () => {
      if (isUserConnected) {
        pinnedChannelsQuery();
      }
    },
    [isUserConnected, chatUserId],
    true
  );
  // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'objec... Remove this comment to see the full error message
  const channelId = route?.params?.channelId;
  // @ts-expect-error TS(2339): Property 'messageId' does not exist on type 'objec... Remove this comment to see the full error message
  const messageId = route?.params?.messageId;
  // @ts-expect-err route?.params?.message;

  return (
    <ShowPinMessagesProvider>
      <ChatChannelListWeb
        // @ts-expect-error TS(2719): Type 'ChannelListCategory' is not assignable to ty... Remove this comment to see the full error message
        type={type}
        channelId={channelId}
        messageId={messageId}
      />
    </ShowPinMessagesProvider>
  );
});
